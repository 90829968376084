/* Typography */
.font {
    font-weight: 400;
    font-family: Inter, sans-serif;
    line-height: 1.25;
}

.font-xs {
    composes: font;

    font-size: 0.75rem;
}

.font-sm {
    composes: font;

    font-size: 0.875rem;
}

.font-base {
    composes: font;

    font-size: 1rem;
}

.font-lg {
    composes: font;

    font-size: 1.125rem;
}

.font-xl {
    composes: font;

    font-size: 1.25rem;
}

.font-2xl {
    composes: font;

    font-size: 1.5rem;
}

.font-3xl {
    composes: font;

    font-size: 1.875rem;
}

.font-4xl {
    composes: font;

    font-size: 2.25rem;
}

.font-5xl {
    composes: font;

    font-size: 3rem;
    line-height: 1;
}

.font-6xl {
    composes: font;

    font-size: 3.75rem;
    line-height: 1;
}

.font-7xl {
    composes: font;

    font-size: 4.5rem;
    line-height: 1;
}

/* Medium weight */
.font-md-xs {
    composes: font-xs;

    font-weight: 500;
}

.font-md-sm {
    composes: font-sm;

    font-weight: 500;
}

.font-md-base {
    composes: font-base;

    font-weight: 500;
}

.font-md-lg {
    composes: font-lg;

    font-weight: 500;
}

.font-md-xl {
    composes: font-xl;

    font-weight: 500;
}

.font-md-2xl {
    composes: font-2xl;

    font-weight: 500;
}

.font-md-3xl {
    composes: font-3xl;

    font-weight: 500;
}

.font-md-4xl {
    composes: font-4xl;

    font-weight: 500;
}

.font-md-5xl {
    composes: font-5xl;

    font-weight: 500;
}

.font-md-6xl {
    composes: font-6xl;

    font-weight: 500;
}

.font-md-7xl {
    composes: font-7xl;

    font-weight: 500;
}

/* SemiBold */
.font-semi-xs {
    composes: font-xs;

    font-weight: 600;
}

.font-semi-sm {
    composes: font-sm;

    font-weight: 600;
}

.font-semi-base {
    composes: font-base;

    font-weight: 600;
}

.font-semi-lg {
    composes: font-lg;

    font-weight: 600;
}

.font-semi-xl {
    composes: font-xl;

    font-weight: 600;
}

.font-semi-2xl {
    composes: font-2xl;

    font-weight: 600;
}

.font-semi-3xl {
    composes: font-3xl;

    font-weight: 600;
}

.font-semi-4xl {
    composes: font-4xl;

    font-weight: 600;
}

.font-semi-5xl {
    composes: font-5xl;

    font-weight: 600;
}

.font-semi-6xl {
    composes: font-6xl;

    font-weight: 600;
}

.font-semi-7xl {
    composes: font-7xl;

    font-weight: 600;
}

/* Bold */
.font-bold-xs {
    composes: font-xs;

    font-weight: 700;
}

.font-bold-sm {
    composes: font-sm;

    font-weight: 700;
}

.font-bold-base {
    composes: font-base;

    font-weight: 700;
}

.font-bold-lg {
    composes: font-lg;

    font-weight: 700;
}

.font-bold-xl {
    composes: font-xl;

    font-weight: 700;
}

.font-bold-2xl {
    composes: font-2xl;

    font-weight: 700;
}

.font-bold-3xl {
    composes: font-3xl;

    font-weight: 700;
}

.font-bold-4xl {
    composes: font-4xl;

    font-weight: 700;
}

.font-bold-5xl {
    composes: font-5xl;

    font-weight: 700;
}

.font-bold-6xl {
    composes: font-6xl;

    font-weight: 700;
}

.font-bold-7xl {
    composes: font-7xl;

    font-weight: 700;
}

/* Effects */
.shadow-sm {
    box-shadow: 0 0 4px 0 rgba(120, 120, 120, 0.10),
                0 3px 3px 0 rgba(120, 120, 120, 0.08),
                0 7px 4px 0 rgba(120, 120, 120, 0.05),
                0 12px 5px 0 rgba(120, 120, 120, 0.01),
                0 19px 5px 0 rgba(120, 120, 120, 0.00);
}

.shadow {
    box-shadow: 0  0 6px 0 rgba(110, 110, 110, 0.09),
                0 5px 5px 0 rgba(110, 110, 110, 0.08),
                0 12px 7px 0 rgba(110, 110, 110, 0.05),
                0 21px 8px 0 rgba(110, 110, 110, 0.01),
                0 33px 9px 0 rgba(110, 110, 110, 0.00);
}

.shadow-md {
    box-shadow: 0 1px 10px 0 rgba(128, 128, 128, 0.10),
                0 12px 12px 0 rgba(128, 128, 128, 0.09),
                0 28px 17px 0 rgba(128, 128, 128, 0.05),
                0 50px 20px 0 rgba(128, 128, 128, 0.02),
                0 78px 22px 0 rgba(128, 128, 128, 0.00);
}

.shadow-md-topless {
    box-shadow: 0 1px 0 0 rgba(128, 128, 128, 0.10),
    0 12px 12px 0 rgba(128, 128, 128, 0.09),
    0 28px 17px 0 rgba(128, 128, 128, 0.05),
    0 50px 20px 0 rgba(128, 128, 128, 0.02),
    0 78px 22px 0 rgba(128, 128, 128, 0.00);
}

.shadow-lg {
    box-shadow: 0 3px 20px 0 rgba(87, 87, 87, 0.10),
                0 29px 29px 0 rgba(87, 87, 87, 0.09),
                0 66px 39px 0 rgba(87, 87, 87, 0.05),
                0 117px 47px 0 rgba(87, 87, 87, 0.01),
                0 183px 51px 0 rgba(87, 87, 87, 0.00);
}

.scroll {
    overflow: auto;
}

.scroll::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;

    background-color: transparent;
}

.scroll::-webkit-scrollbar-thumb {
    background: var(--color-neutral-300);
    border-radius: 0.5rem;
}

.hidden-scroll {
    overflow: auto;
}

.hidden-scroll::-webkit-scrollbar {
    display: none;
}

.hidden-scroll::-webkit-scrollbar-thumb {
    display: none;
}

.line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;

    overflow: hidden;

    text-overflow: ellipsis;
}

.line-clamp-2 {
    composes: line-clamp;
    -webkit-line-clamp: 2;
}
