.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.header {
    display: flex;
    gap: 1.625rem;
    align-items: center;
    justify-content: space-between;
    height: 4.625rem;
    padding: 1.125rem 1.25rem;
}

.title {
    composes: font-semi-xl from 'ui/utils.css';

    margin: 0;

    color: var(--color-grayscale-100);
}

.rowsDisplayedText {
    composes: font-xs from 'ui/utils.css';

    margin: 0;

    color: var(--color-grayscale-40);
}
