.linkStatusCampaignContainer {
    padding: 0.625rem 0.9375rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
    cursor: default;
}

.linkStatusCampaignContainerTitle {
    margin: 0 0 0.2rem;

    color: #323c47;
    font-weight: 500;
    font-size: 0.75rem;
}

.linkStatusCampaign {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;

    color: #58698e;
    font-size: 0.7rem;
}

.unlinkCampaignButton {
    margin-left: 0.5rem;

    color: #f7685b;
    font-weight: 600;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.modalFooter > * + * {
    margin-left: 0.5rem;
}
