.tabIcon {
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.375rem;
}

.tabContainer {
    margin-top: 0.5rem;
    padding: 0 0.25rem;
}

.tabContainer > * + * {
    margin-top: 0.325rem;
}
