.description {
    display: inline-block;
    max-width: 10rem;

    color: inherit;
}

.description span {
    padding: 0;
}

.username {
    max-width: 5.5rem;
    padding: 0;
}

.date {
    white-space: nowrap;
}

.table thead th,
.table tbody td {
    padding: 0.6875rem 1.3125rem
}

.table thead th {
    font-size: 0.7rem;
    white-space: nowrap;
}

.table thead th span {
    margin-left: 3px;
}

.table tbody td {
    min-width: min-content
}

.location {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    column-gap: 0.5rem;
}
