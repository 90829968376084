.container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: stretch;
    padding: 1.25rem;

    background: var(--color-neutral-50);
    border: 1px solid var(--color-neutral-200);
    border-radius: 0.75rem
}

.platformSelect {
    display: flex;
    gap: 0.625rem;
}

.platformOption, .platformSkeleton {
    min-width: 5.625rem;
    height: 1.875rem;

    border-radius: 1.875rem;
}

.platformOption {
    composes: font-bold-sm from 'ui/utils.css';

    display: flex;
    gap: 0.25rem;
    align-items: center;

    padding: 0 1rem 0 0.75rem;

    background: #FFF;

    border: 1px solid var(--color-neutral-200);
    cursor: pointer
}

.platformOption input {
    display: none;
}

.platformOption:hover {
    box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.10);
}

.platformOption:has(input:checked) {
    border: 1px solid var(--color-brand-primary)
}

.row {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    column-gap: 1.875rem;
}

.fieldGroup {
    display: flex;
    flex: 1;
    align-items: stretch;

    column-gap: 0.625rem;
}

.fieldGroup > * {
    flex: 1;
    width: 0;
}

.fieldSkeleton {
    min-height: 2.5rem;
}

.identifierField input::placeholder {
    font-size: 0.8125rem;
}

.switchAudioInputButton {
    align-self: flex-start;
    padding: 0.25rem;

    color: var(--color-grayscale-50);
    font-weight: 500;

    background-color: transparent;
}

.switchAudioInputButton:hover {
    color: var(--color-grayscale-100);
    text-decoration: underline;

    background-color: transparent;
    text-underline-offset: 0.25rem;
}
