.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    column-gap: var(--6);
}

.image {
    width: 5rem;
    height: 5rem;

    border-radius: var(--rounded-md);
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.title {
    composes: heading-small from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.authorName {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;

    color: var(--Text-Weak);
}

.badges {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: auto;

    column-gap: var(--1);
}
