.container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-width: 887px;
    height: 100%;

    font-family: Inter, sans-serif;
}

.sideBar {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1.5rem;
    padding: 2rem 0 0;

    background-color: #F3F4F6;

    border-radius: 0.75rem;
}

.content {
    flex: 2;
    padding: 3rem 1.75rem 1.75rem;
}

.title {
    margin: 0;

    color: #111111;
    font-weight: 700;
    font-size: 1.25rem;
}

.creator {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.75rem;
}

.creator, .title {
    padding: 0 1.75rem
}

.creatorDetails > * {
    margin: 0;
}

.creatorImage {
    width: 2.8125rem;
    height: 2.8125rem;
    overflow: hidden;

    border: 2px solid white;

    border-radius: 50%;
}

.creatorName {
    color: #111111;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 125%;
}

.creatorUsername {
    color: #8D8D8D;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 125%;
}

.creatorEmailAddress {
    margin-top: 0.4rem;

    color: #111111;
    font-weight: 400;
    font-size: 0.875rem;
}

.navigation {
    flex: 1;
    overflow-y: auto;
}

.navigationList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.navItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.25rem;

    padding: 0.875rem 1.75rem;
    padding-right: 0;
}

.navItem:hover {
    background-color: white;
}

.activeNavItem {
    background-color: white;
}

.navItemTitleRow {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    width: 100%;
}

.navItemTitle {
    color: #111111;
    font-weight: 400;
    font-size: 0.875rem;
    white-space: nowrap;
}

.navItemTimestamp {
    margin-right: 0.5rem;
    margin-left: 1rem;

    color: #8D8D8D;
    font-weight: 400;
    font-size: 0.75rem;
    white-space: nowrap;
}

.navItemSubtitle {
    color: #8D8D8D;
    font-weight: 400;
    font-size: 0.75rem;
}

.errorMessage {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #767676;
}

.stages {
    border: 1px solid #E5E7EB;
    border-radius: 0.75rem;
}

.stages > * + * {
    border-top: 1px solid #E5E7EB;
}

.stage {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;

    font-size: 0.875rem;

    column-gap: 1rem;
}

.stageIndex {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;

    color: #111111;

    background-color: #F3F4F6;
    border-radius: 50%;
}

.stageTitle {
    color: #767676;
}

.stageTimestamp {
	display: flex;
	gap: 0.25rem;
	align-items: center;
}

.stage a {
    color: #111111;
}

.flagIcon {
    width: 1.125rem;
    height: 1.125rem;
}
