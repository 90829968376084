.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;
    overflow: hidden;

    border: 1px solid var(--color-neutral-400);
    border-radius: 0.375rem;
    cursor: pointer;
}

.checkbox.checked {
    background-color: var(--color-brand-primary, #FE002B);
    border-color: var(--color-brand-primary, #FE002B);
}

.checkbox svg {
    color: white;
}

.check {
    width: 0.75rem;
    height: 0.75rem;
}

.indeterminate path {
    stroke-width: 4;
}

.checkbox.disabled {
    cursor: not-allowed;
}
