.container {
    box-sizing: border-box;
    padding: 1.25rem 1.875rem;

    background-color: #FBFBFD;
    border-radius: 0.625rem;
}

.chartHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.875rem;
}

.chartNavItem {
    min-width: 9.75rem;

    white-space: nowrap;
}

.dateRangeOption {
    padding: 0.3125rem 0.625rem;

    font-weight: 400;

    border-bottom: 0.125rem solid #E7EEF6;
    border-radius: 0;
}

.dateRangeOption.active {
    border-bottom-color: #192A3E;
}

.tooltipLabel {
    margin-right: 0.3125rem;

    font-weight: 600;
}

.tooltipDateTime {
    color: #90A0B7;
    font-weight: 400;
    font-size: 0.75rem;
}
