.errorMessage {
    margin-top: 0.25rem;

    color: red;
    font-size: 0.6875rem;
}

.modalContent {
    max-width: 36rem;
}

.imagePreview {
    max-height: 32rem;
}

.actionsContainer {
    display: flex;

    column-gap: 0.5rem;
}
