.table {
    overflow-x: auto;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table th {
    background-color: var(--Tables-header-grey);
}

.table td, .table th {
    min-width:8rem;
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22rem;
}

table .brandColumn {
    min-width: 16rem;
}

table .projectColumn {
    min-width: 18rem;
}

table .checkboxColumn {
    min-width: 2.5rem;
    max-width: 2.5rem;
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--icon-base);
}

.ghostIcon {
    color: var(--Icon-light, #F6F6F6);
}
