.tooltip {
    position: fixed;
    z-index: 20;

    padding: 0.46875rem 0.78125rem;

    color: #2d405a;
    font-weight: 500;
    font-size: 0.8rem;

    background-color: white;
    border: 1px solid #e7eef6;
    border-radius: 0.5rem;
    box-shadow: 0 0 0.875rem 0 #0000000f;
}

.tooltipContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}
