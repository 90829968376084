.modal {
    min-width: 30rem;
}

.form > * + * {
    margin-top: 1rem;
}

.paymentOptionsContainer {
    display: flex;
    gap: 0.75rem
}

.paymentButton {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;

    color: #3E3E3E;

    font-weight: 600;
    font-size: 0.75rem;

    background: #FFF;
    border: 1px solid #D2D2D2;
    border-radius: 0.25rem;
    cursor: pointer;
}

.paymentButton:not(.selected):hover {
    box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.10);
}

.paymentButton.selected {
    background: rgba(68, 68, 68, 0.05);
    border-color: #3E3E3E;
}
