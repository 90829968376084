.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.selectionPath {
    font-size: 0.75rem;
}

.selectionItemContainer {
    padding: 0.5rem;
}

.selectionItem {
    padding: 0.5rem;

    font-weight: 500;
    font-size: 0.75rem;

    background-color: white;
    border-radius: 0.2rem;
}

.selectionLabel {
    flex: 1;
    margin: 0;
}

.closeButton {
    background-color: transparent;
    border: none;
}

.closeButton:hover {
    cursor: pointer;
}

.selectionDescriptionContainer {
    box-sizing: border-box;
    width: 350px;

    background-color: white;
    border-radius: 0.2em;
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.24);
}
