.fetchStatusContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 0;

    line-height: 1.5;
}

.errorMessage {
    color: var(--color-status-fail);
    font-weight: 500;
}

.campaignsSection {
    margin-top: 3.75rem;
}

.platformIcon {
    margin-right: 0.375rem;
}

.tabNavigation {
    padding: 0 1.25rem;
}

.tabPanel {
    padding: 0 1.25rem;
}
