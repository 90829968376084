.badgeContainer > * + * {
    margin-left: 1.25rem;
}

.badgeContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.textAlignCenter {
    text-align: center;
}
