.cell {
    padding: 1rem 2rem;
}

.row {
    color: #707683;
    font-size: 0.75rem;
    letter-spacing: 0.1px;

    border: none;
    cursor: pointer;
}

.row:hover {
    background-color: #f2f9ff;
}
