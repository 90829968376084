.table {
    width: 100%;
}

.table th {
    font-size: 0.625rem;
    white-space: nowrap;
}

.table td {
    min-width: 0;
}

.link {
    display: inline-block;
    max-width: 15rem;

    color: inherit;
    text-decoration: none;
    vertical-align: middle;
}

.thead th:nth-child(3) {
    width: 3rem;
}
