.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.container p {
    margin: 0;
}

.image {
    width: 2rem;
    height: 2rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.names {
    flex: 1;
    overflow-x: hidden;
}

.brandName,
.teamName {
    overflow-x: hidden;

    text-overflow: ellipsis;
}

.brandName {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    white-space: nowrap;
}

.teamName {
    color: var(--Text-Weak, #767676);
}
