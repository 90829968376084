.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.searchContainer {
    align-items: flex-start;
}

.includeLocationSelect {
    min-width: 6rem;
    margin-right: 1.25rem;
}

.includeLocationSelect input {
    height: 0 !important;
}

/* Location search */
.search {
    flex: 1;
}

.select input {
    height: auto;
}

.optionLabel {
    margin: 0;
}

.optionType {
    color: #999999;
}

/* location list  */
.locationsList {
    max-height: 15rem;
    margin: 0.5rem 0;
    padding: 0.2rem;
    overflow-y: auto;

    background-color: #fbfbfd;
    border-radius: 0.2rem;
}

.locationContainer {
    padding: 0.5em;
}

.locationContainer * {
    margin: 0;
}

.locationLabel {
    flex: 1;
    margin-left: 0.625rem;

    font-weight: 500;
    font-size: 0.75rem;
}

.locationType {
    color: #818e9b;
    font-weight: 400;
    font-size: 0.75rem;
}

.locationDeleteIcon {
    background-color: transparent;
    border: none;
}

.locationDeleteIcon:hover {
    cursor: pointer;
}

.locationDeleteIcon > svg {
    width: 1.25rem;
    height: 1.25rem;
}

.locationDeleteIcon * {
    stroke: #b2bdcd;
}

.locationDeleteIcon:hover * {
    stroke: #f7685b;
}

.radiusFormContainer {
    padding: 0.5rem;

    background-color: white;
    border-radius: 4px;
    box-shadow: 0 0 0 1px hsla(0deg, 0%, 0%, 0.1), 0 4px 11px hsla(0deg, 0%, 0%, 0.1);
}

/* override specificity of .ModalFormField input */
input.radiusFormDistanceInput {
    max-width: 4.375rem;
    height: auto;
    padding: 0.25rem 1rem;

    font-size: 0.8125rem;

    background-color: transparent;
}

.errorMessage {
    color: rgba(236, 56, 83, 0.86);
    font-size: 0.7rem;
    letter-spacing: 0.2px;
}
