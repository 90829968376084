.container {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    display: inline-flex;
    align-items: center;
    padding: var(--1) var(--3) var(--1) var(--2);

    color: var(--Text-Base);
    column-gap: var(--2);

    background-color: white;
    border: 1px solid var(--Badges-Border);
    border-radius: var(--rounded);
}

.icon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}
