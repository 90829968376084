.table td,
.table th {
    padding: 0.625rem;
}

.table th {
    font-size: 0.625rem;
}

.userDetails {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.userDetails a {
    color: #323C47;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;
}

.userDetails a:hover {
    text-decoration: underline;
}

.profileImage {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;

    border-radius: 50%;
}

.verifiedIcon {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.25rem;
}

.bio {
    max-width: 14rem;
}

.contactDetails {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.325rem 0.25rem;
    align-items: flex-start;
}

.location {
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
    align-items: flex-start;
}

.country {
    display: flex;
    flex-direction: row;
    gap: 0.3125rem;
    align-items: center;
}

.subLocation {
    color: #58698E;
    font-size: 0.625rem;
}

.flagIcon {
    min-width: 1rem;
    min-height: 1rem;
}
