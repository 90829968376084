.container {
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 1.25rem 0.9375rem;

    color: #58698e;
    font-size: 0.75rem;

    background-color: #f5f6f8;
    border-radius: 0.1875rem;
}

.label {
    margin-right: 0.5rem;

    font-weight: 500;
    white-space: nowrap;
}

.value {
    margin-right: 0.5rem;

    font-weight: 600;
}

.value.needsUpdate {
    color: #9faab9;
}

.loadingSpinnerContainer {
    display: inline-flex;
    width: 1rem;
    height: 1rem;
}

.loadingSpinnerContainer > div:before {
    width: 0.7rem;
    height: 0.7rem;

    border-width: 2px;
}

.infoIcon {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.infoIcon * {
    stroke: #0c8cda;
}
