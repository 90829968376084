.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
}

@media screen and (min-width: 700px) {
    .container {
        flex-direction: row;
    }

    .container > * {
        flex: 1;
    }
}

.chartTile {
    padding: var(--4);

    background-color: var(--Background-light);
    border-radius: var(--rounded-md);
}

.title {
    composes: p-medium from 'ui-new/whitelabel/utils/utils.css';

    margin: 0 0 1.5rem;

    color: var(--Text-body);

    font-size: 1rem;
}

.chartContainer {
    position: relative;

    height: 18.75rem;
}

@media screen and (min-width: 700px) {
    .chartContainer {
        height: auto;
        aspect-ratio: 2.5;
    }
}

.chartTooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.chartTooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}
