.container {
    width: 100%;
    max-width: 24.375rem;
}

.title {
    color: #323c47;
    font-weight: 500;
    font-size: 1.75rem;
}

.submit {
    width: 100%;
    margin-top: 1.875rem;
    padding: 0.86rem 1rem !important;
}

.submit.skeleton {
    box-sizing: border-box;
}

.form {
    position: relative;
}

.error {
    color: red;
    font-size: 0.75rem;
}
