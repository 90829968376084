.table {
    width: 100%;
}

.table thead th:first-child,
.table tbody td:first-child {
    width: 1rem;
    min-width: 0;
}

.lastContactedAtContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.historyButton {
    padding: 0.25rem;

    visibility: hidden;
}

.lastContactedAtContainer:hover .historyButton {
    visibility: visible;
}

.historyIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
}

.paymentRequest {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.paymentRequest,
.paymentRequest:visited,
.paymentRequest:active {
    color: inherit;
}

.paymentRequestIcon {
    width: 0.9rem;
    height: 0.9rem;
    stroke-width: 1rem;
}

.anchor {
    outline: var(--color-accent-blue) solid 0.5px;
}
