.button {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--Spacing-Spacing-2, 0.5rem);
    align-items: center;
    justify-content: center;
    padding: var(--Spacing-Spacing-2, 0.5rem) var(--Spacing-Spacing-4, 1rem);

    border-radius: var(--rounded, 0.25rem);

    cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
}

.button:focus {
    outline: 3px solid #D1D1D1;
}

.primary {
    composes: shadows-button-small-base from 'ui-new/whitelabel/utils/utils.css';

    color: var(--text-white);

    background: var(--Button-Primary-primary, #2823F6);
    border: none;
}

.primary:hover {
    background-color: var(--Button-Primary-hover, #606060);
}

.primary:active {
    background-color: var(--Button-Primary-active, #373737);
}

.primary:disabled {
    background-color: var(--Button-Primary-disabled, #D1D1D1);
    opacity: 0.24;
}

.critical {
    composes: shadows-button-small-base from 'ui-new/whitelabel/utils/utils.css';

    color: var(--text-white);

    background-color: var(--Button-Critical-primary, #FF003F);

    border: none;
}

.critical:active {
    background-color: var(--Button-Critical-active, #D50035);
}

.critical:disabled {
    opacity: 0.24;
}

.critical:hover {
    background-color: var(--Button-Critical-hover, #FF2A5F );
}

.outline {
    composes: shadows-button-small-base from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-primary);

    background-color: transparent;
    border: 1px solid var(--Borders-Grey, #B0B0B0);
}

.outline:hover {
    background-color: var(--Button-Outline-hover, #00000008);
    border-color: var(--Borders-Black, #000000);
}

.outline:active {
    border-color: var(--Borders-Black, #000000);
}

.outline:disabled {
    border-color: var(--Button-Outline-disabled, #00000033);
    opacity: 0.24;
}

.ghost {
    color: var(--Text-primary);

    background-color: transparent;

    border: none;
}

.ghost:hover {
    background-color: var(--Button-Ghost-hover, #00000008);
}

.ghost:disabled {
    opacity: 0.24;
}

.spinner {
    width: 1rem;
    height: 1rem;

    animation: 0.75s linear infinite spin;
}

.primary .spinner,
.critical .spinner {
    color: var(--text-white);
}

.outline .spinner,
.ghost .spinner {
    color: var(--Text-primary);
}

@keyframes spin {
    0% { transform: rotate(0deg); }

    100% { transform: rotate(360deg); }
}
