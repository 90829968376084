.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    min-width: 12rem;

    column-gap: 0.5rem;
}

.thumbnail {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
}

.info {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    align-items: stretch;
}

.title {
    composes: font-sm from 'ui/utils.css';
    composes: line-clamp-2 from 'ui/utils.css';

    color: var(--color-grayscale-100);

    text-decoration: none;

    cursor: pointer;
}

.title:visited {
    color: var(--color-grayscale-100);
}

.title:hover,
.title:focus {
    text-decoration: underline;
}

.subTitle {
    composes: font-xs from 'ui/utils.css';
    composes: line-clamp-2 from 'ui/utils.css';

    color: var(--color-grayscale-40);
}
