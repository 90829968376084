.advertisingCell > * + *,
.creatorsCell > * + *,
.statusHeader > * + *,
.statusCell > * + * {
    margin-left: 0.5rem;
}

.brandContainer > * + * {
    margin-left: 0.625rem;
}

.statusesDescriptionsContainer > * + * {
    border-top: 1px solid #00000030;
}

.viewDetailsLink {
    display: inline-flex;
    align-items: center;

    color: #707683;
    text-decoration: none;

    transition: all 0.2s ease-in-out;
}

.viewDetailsLink:hover,
.viewDetailsLink:hover * {
    color: #109cf1;

    stroke: #109cf1;
}

.viewDetailsIcon {
    margin-left: 0.2rem;
    stroke-width: 1.5;
}

.advertisingCell,
.creatorsCell {
    display: flex;
    align-items: center;
    justify-content: center;
}

.statusCell {
    display: flex;
    align-items: center;
}

.statusLabel {
    font-weight: 500;
}

.statusLabel.live {
    color: #2ed47a;
}

.statusLabel.completed {
    color: #109cf1;
}

.statusLabel.planned {
    color: #727f8f;
}

.statusHeader {
    position: relative;

    display: flex;
    align-items: center;
}

.accountTeamCell {
    display: -webkit-box;
    flex-shrink: 0;
    max-width: 12rem;
    overflow: hidden;

    font-size: 0.65rem;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.statusesDescriptionsContainer {
    position: absolute;
    top: 200%;

    width: 20rem;

    text-transform: none;

    background-color: white;
    box-shadow: 0 5px 10px 0 #19283b33;
}

.statusesDescriptionsContainer .statusLabel {
    font-size: 0.8rem;
}

.statusDescriptionRow {
    padding: 0.6875rem 1.5rem;
}

.statusDescriptionRow > p {
    margin: 0.5rem 0 0;

    color: #58698e;
    font-size: 0.6875rem;
}

.brandContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 14rem;
}

.brandImage {
    width: 2rem;
    height: 2rem;

    border-radius: 50%;
}
