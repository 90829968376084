.modal {
    padding: 1.75rem;
}

.footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between !important;
    width: 100%;
}

.label {
    font-weight: 500;
    font-size: 0.8125rem;
}

.row {
    display: flex;
    gap: 0.475rem;
    align-items: center;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 0.475rem;
}

.message {
    color: #4f4f4f;
    font-weight: 500;
    font-size: 0.75rem;
}
