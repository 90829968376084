.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 2rem;

    padding: 1rem 1.5rem;

    color: white;
}

.titles {
    display: flex;
    flex-direction: column;

    gap: var(--1);
    align-items: flex-start;
}

.title {
    composes: heading-large from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.description {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.badgeContainer {
    --badge-width: 2rem;
    --badge-offset: -0.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 5rem;
    /* Fit 3 badges with gaps */
    max-width: calc(var(--badge-width) * 3 + (var(--badge-offset) * 2));
}

.badgeContainer > .badge + .badge {
    margin-left: var(--badge-offset);
}

.teamBadge {
    position: relative;
}

.teamBadge .sonyLogo {
    width: 90%;
    height: 90%;

    color: var(--Text-primary);
}

.teamBadge .flag {
    position: absolute;
    bottom: 0;
    left: 0;

    width: 1.25rem;
    height: 1.25rem;
    object-fit: cover;

    border-radius: 50%;

    transform: translate(-25%, 25%);
}

.titleSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
}

@media screen and (min-width: 700px) {
    .titleSection {
        flex-direction: row;
    }
}

.publishActions {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.publishReport {
    composes: p-bold from 'ui-new/whitelabel/utils/utils.css';
}

.publishedState {
    display: flex;
    gap: 1rem;
}

.unpublishButton, .editButton {
    background-color: transparent;
    border: 1px solid var(--Borders-White);
}

.copyLinkButton svg {
    width: 1.125rem;
    height: 1.125rem;
}
