.tableContainer {
    height: 100%;
}

.table {
    white-space: nowrap;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table tbody tr {
    cursor: pointer;
}

.table p {
    margin: 0;
}

.table th {
    background-color: var(--Tables-header-grey);
}

.table td,
.table th {
    min-width: 10rem;
}

.table .brandColumn {
    min-width: 14rem;
}

.table .projectColumn {
    min-width: 18rem;
}

.table tbody td {
    font: inherit;
}

.channelsContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.table tbody tr:has(.noDataContainer) {
    cursor: auto;
}

.table tbody tr:not(:has(*[aria-label='no projects'])):hover {
    background: var(--tables-surface-hover, #f4f4ff);
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22rem;
}

.dailyChangeContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;

    cursor: pointer;
}

.songColumn {
    max-width: min-content;
}

.songIcon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}

.chartContainer {
    position: relative;

    width: 5.625rem;
    height: calc(3.0625rem - 1rem);
}

.chartTooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.chartTooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.table .actions {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    min-width: 2rem;
    max-width: min-content;
}

.deleteAction {
    color: var(--Text-destructive, #ff003f);
}
