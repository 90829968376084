.head {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;

    cursor: pointer;
}

.head:hover {
    background-color: #f2f9ff;
}

.labels {
    flex: 1;
}

.label {
    margin: 0;

    font-weight: 500;
    font-size: 0.9rem;
}

.toggleAll {
    cursor: pointer;
}

.toggleAllLabel {
    margin-left: 0.5rem;

    color: #707683;
    font-size: 0.8rem;
}

.dropdownIcon {
    width: 1rem;
    height: 1rem;

    transform: rotate(-90deg);
}

.dropdownIcon.expanded {
    transform: rotate(0);
}
