.accountContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.75rem;
}

.accountDetails {
    flex: 1;
}

.accountDetails > p {
    margin: 0;
}

.followerCount {
    color: var(--Text-Weak, #767676);
}

.accountImage {
    width: 2rem;
    height: 2rem;

    border-radius: 50%;
}

.editAccountButton {
    visibility: hidden;
}

/* always display edit icon on touch screen devices */
@media screen and (pointer: coarse) {
    .editAccountButton {
        visibility: visible;
    }
}

.editAccountButton[data-open="true"],
.table tr:hover .editAccountButton,
.table tr:active .editAccountButton {
    visibility: visible;
}
