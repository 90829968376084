.table {
    composes: font-sm from 'ui/utils.css';

    min-width: 100%;

    color: var(--color-grayscale-100);

    border-spacing: 0;
}

.table tr {
    overflow-y: hidden;
}

.table td,
.table th {
    padding: 0 0.75rem;
}

.table thead th {
    position: relative;

    height: 3.5rem;

    font-weight: 500;
    white-space: nowrap;
    text-align: left;

    background-color: var(--color-neutral-50);

    border-top: 1px solid var(--color-neutral-300);
    border-bottom: 1px solid var(--color-neutral-300);
}

.table thead th:first-child {
    padding-left: 1rem;
}

.table thead th:last-child {
    padding-right: 1rem;
}

.table thead th:first-letter {
    text-transform: uppercase;
}

.table td {
    height: 5rem;

    vertical-align: middle;

    background-color: white;
}

.table :global(.table-left-pinned-edge):before {
    position: absolute;
    top: 0;
    right: -10px;

    width: 10px;

    height: 100%;

    box-shadow: inset 10px 0 6px -12px rgba(110, 110, 110, 0.09),
                inset 10px 5px 5px -10px rgba(110, 110, 110, 0.08),
                inset 10px 12px 7px -14px rgba(110, 110, 110, 0.05),
                inset 10px 21px 8px -16px rgba(110, 110, 110, 0.01),
                inset 10px 33px 9px -18px rgba(110, 110, 110, 0.00);

    content: '';
}

.table :global(.table-right-pinned-edge):before {
    position: absolute;
    top: 0;
    left: -10px;

    width: 10px;

    height: 100%;

    box-shadow: inset -10px 0 6px -12px rgba(110, 110, 110, 0.09),
                inset -10px 5px 5px -10px rgba(110, 110, 110, 0.08),
                inset -10px 12px 7px -14px rgba(110, 110, 110, 0.05),
                inset -10px 21px 8px -16px rgba(110, 110, 110, 0.01),
                inset -10px 33px 9px -18px rgba(110, 110, 110, 0.00);

    content: '';
}

.table :global(.table-header-sortable) {
    cursor: pointer;
}

.noDataLabel {
    color: var(--color-neutral-400);
    text-align: center;
}

.sortingIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;

    vertical-align: middle;
}

.sortingIcon.sortingActive {
    color: rgba(24, 126, 221, 1);
}
