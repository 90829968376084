.container {
    margin: 1.875rem;
    padding: 1.25rem;
}

.container > * + * {
    margin-top: 1.25rem;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.title {
    margin: 0;

    font-size: 1.15rem;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.files {
    display: flex;
    flex-direction: row;
}

.files > * + * {
    margin-left: 0.625rem;
}

.file {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 4.375rem;
}

.delete {
    position: absolute;
    top: 0;
    right: 0;

    display: none;

    color: white;

    background-color: red;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.file:hover .delete {
    display: block;
}

.fileIcon {
    width: 100%;
    height: auto;

    color: rgba(51, 77, 110, 0.6);
}

.fileName {
    margin: 0.3125rem 0 0;

    color: rgba(51, 77, 110);
    font-size: 0.75rem;
    text-align: center;
}

.results > * + * {
    margin-top: 0.625rem;
}

.result {
    padding: 0.5rem;

    font-size: 0.875rem;
    word-break: break-word;

    background-color: #F5F6F8;
}

.result .fileName {
    font-weight: 500;
}
