.overlay {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.container {
    composes: shadow-lg from 'ui-new/whitelabel/utils/effects.css';

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 18.3125rem;

    background: var(--Background-white, #FFF);

    border-radius: var(--rounded-md, 0.375rem);
}

.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
}

.targetButton {
    position: relative;

    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.125rem;

    color: var(--Icon-white);

    background: var(--Alpha-Black-50, rgba(0, 0, 0, 0.0125));

    border-radius: 50%;

    aspect-ratio: 1/1;
}

.targetButton.active {
    color: var(--Button-Primary-primary, #2684FF);

    background: var(--Icon-white, #FFF);
}

.targetButton:hover {
    background: color-mix(in sRGB, var(--Button-Primary-primary) 85%, var(--Icon-white) 15%);
}

.label {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    padding: var(--2, 0.5rem);

    color: var(--Text-primary)
}

.actions {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: space-between;
    padding: var(--2, 0.5rem);
}
