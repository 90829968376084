.option {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 1rem;
}

.icon {
    width: 1.25rem;
    height: 1.25rem;
}
