.content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    min-width: 47.5rem;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}
