.container {
    background: var(--tables-surface-hover);
    border-radius: var(--Spacing-Spacing-25);
}

.icon {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--icon-base);
}
