.form > *  {
    margin-bottom: 0.825rem;
}

.errorMessage {
    margin-top: 0.5rem;

    color: red;
    font-size: 0.7rem;
}
