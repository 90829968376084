.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.container > * + * {
    margin-top: 1.25rem;
}

.title {
    margin: 0;

    font-weight: 600;
    font-size: 1.5rem;
}
