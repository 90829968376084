.row {
    color: #707683;
    font-size: 0.75rem;
    letter-spacing: 0.1px;

    border: none;
    cursor: pointer;
}

.row a {
    color: inherit;
}

.row:hover {
    background-color: #f2f9ff;
}

.row td {
    padding: 0.5rem 0;
}

.nameCell {
    width: 24rem;
    max-width: 24rem;
    padding-right: 0.5rem;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.dateCell {
    display: flex;
    align-items: center;
    min-width: 10rem;
    height: 3rem;
}

.date {
    width: 50%;
}

.budgetCell {
    display: flex;
}

.indented {
    padding-left: 4rem;
}

.imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
}

.image {
    width: 3.15rem;
    height: 3.15rem;
    object-fit: cover;

    border-radius: 50%;
}

.imageCell {
    width: 11rem;
    height: 3.15rem;
    /*padding: 0.5rem 0;*/
}

.relativeStatsChange {
    padding-left: 1rem;
}

.green {
    color: #2ed47a;
}

.red {
    color: #f7685b;
}

.alternateName {
    display: inline-block;
    margin-left: 0.5rem;
}

.table {
    width: 100%;

    background-color: white;
    border-collapse: collapse;
}

.columnHeaderRow {
    height: 3rem;

    color: #334d6e;
    font-size: 0.7rem;
    letter-spacing: 0.2px;
    text-align: left;

    opacity: 0.5;
}

.columnHeaderRow th {
    font-weight: 500;
}

.displayCount {
    padding-bottom: 0.3rem;

    color: #334d6e;
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.1px;

    opacity: 0.5;
}
