.container {
    min-width: 40rem;
    padding: 0 1.875rem 1.875rem;

    font-size: 0.875rem;
}

.form {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.splitSection {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;
}

.splitSection > * {
    flex: 1;
}

.submitButton {
    align-self: flex-start;
}

.error {
    color: red;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.875rem 0;

    border: 1px solid #D1D5DB;
    border-right: none;
    border-left: none;
}

.detailsContainer > div {
    display: flex;
    gap: 0.875rem;
    justify-content: space-between;
}

.container dd, .container dt {
    margin: 0;
}

.warning {
    margin-top: 0.25rem;

    color: #F59E0B;
    font-size: 0.75rem;
}
