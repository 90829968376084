.tableContainer {
    overflow: auto;
}

.tableContainerContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: fit-content;
    min-width: 100%;
}

.alignCenter {
    text-align: center;
}

.groupsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 0.625rem;
}

.groupSkeleton {
    width: 100%;
    height: 3.75rem;
}
