.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
}

.form > * {
    width: 100%;
}

@media screen and (min-width: 700px) {
    .form > * {
        width: 25rem;
    }
}
