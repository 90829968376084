.form {
    width: 26.875rem;
}

.form > * + * {
    margin-top: 0.8125rem;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row > * + * {
    margin: 0 0 0 0.625rem !important;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.row > * {
    flex: 1;
}

.error {
    color: red;
    font-size: 0.75rem;
}
