.modal {
    max-height: 90vh;
    padding: 0;
    overflow-y: auto;
}

.modalTitle,
.modalFooter {
    padding: 1.875rem;
}

.modalFooter {
    padding-top: 0;
}

.modalTitle {
    padding-top: 0;
    padding-bottom: 0;
}

.content {
    display: flex;
    flex-direction: column;
}

.statusButton {
    padding: 1rem 1.875rem;

    font-size: 0.875rem;

    background-color: white;
    border: none;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .statusButton {
        padding: 1.125rem 2.5rem;
    }

    .modalTitle {
        padding-top: unset;
    }
}

.statusButton:hover,
.statusButton.selected {
    background-color: #f5f6f8;
}

.status {
    color: #58698e;
}

.selectDateLabel {
    margin: 1rem 0 0.5rem 1rem;

    color: #000000;
    font-weight: 500;
    font-size: 1rem;
}

@media screen and (min-width: 600px) {
    .calendarContainer {
        padding: 0 1.875rem;
    }

    .selectDateLabel {
        margin: 1rem 0 0.5rem;
    }
}
