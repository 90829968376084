.overlay {
    /* Need to compose variables here because the tooltip is not a child of the layout wrapper */
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.tooltip {
    padding: 0.75rem;

    background: #0C111D;
    border-radius: 0.5rem;
}

/*TODO: Extract font styles to classes*/
.title {
    color: var(--text-white);
    /* Text xs/Semibold */
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.body {
    color: #D0D5DD;
    /* Text xs/Medium */
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.underline {
    text-decoration: dashed;
    text-decoration-line: underline;
    text-decoration-color: var(--Borders-Grey);
    text-underline-offset: 0.125rem;
}

.arrow {
  position: absolute;
  z-index: -1;

  width: 1rem;
  height: 1rem;

  background: #0C111D;
  transform: rotate(45deg);
}

.overlay *[data-popper-placement^='top']:has(.tooltip) .arrow {
  bottom: -4px;
  left: calc(50% - 0.5rem);
}

.overlay *[data-popper-placement^='top-start']:has(.tooltip) .arrow {
  bottom: -4px;
  left: 0.5rem;
}

.overlay *[data-popper-placement^='bottom']:has(.tooltip) .arrow {
  top: -4px;
  left: calc(50% - 0.5rem);
}

.overlay *[data-popper-placement^='left']:has(.tooltip) .arrow {
    top: calc(50% - 0.5rem);
    right: -4px;
}

.overlay *[data-popper-placement^='right']:has(.tooltip) .arrow {
    top: calc(50% - 0.5rem);
    left: -4px;
}
