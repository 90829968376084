.container {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 2.8125rem 1.875rem;

    background-color: white;
}

.container > * + * {
    margin-top: 1.875rem;
}

.closeIcon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 10;

    width: 1rem;
    height: 1rem;

    cursor: pointer;
    opacity: 0.3;

    transition: all 0.2s ease-in-out;
}

.closeIcon path {
    fill: #192a3e;
}

.closeIcon:hover {
    opacity: 1;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.875rem;
    overflow: hidden;

    background-color: #f5f6f8;
    border-radius: 0.375rem;
}

.header > * + * {
    margin-left: 1rem;
}

.headerToolbar {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
}

.mediaPlanItemsSelectContainer {
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 35%;
    padding: 1.25rem;

    background-color: #dcdfe5;
    cursor: pointer;
}

.mediaPlanItemsSelectDropdown {
    max-height: 18.75rem;
    overflow-y: auto;

    background-color: white;
    border-radius: 0 0 0.1875rem 0.1875rem;
    box-shadow: 0 0.875rem 1.875rem 0 #19283b14;
}

.release {
    flex: 1;
}

.brandImage {
    width: 2.8125rem;
    height: 2.8125rem;
    margin-right: 0.9375rem;
    object-fit: cover;

    border-radius: 50%;
}

.brandName {
    margin: 0;

    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
    font-style: normal;
}

.releaseName {
    margin: 0;

    color: #90a0b7;
    font-size: 0.625rem;
    font-style: normal;
}

.itemsSelectedCount {
    margin: 0.2rem 0 0;

    color: #4a87cd;
    font-size: 0.6875rem;
}

.mediaPlanItemsSelectDropdownIcon {
    color: #58698e;
}

.dateRangeContainer {
    display: flex;
    flex-direction: row;
}

.dateRangeContainer > * + * {
    margin-left: 0.3125rem;
}

.dateField {
    position: relative;

    display: flex;
    flex-direction: column;
}

.headerToolbar label {
    color: #90a0b7;
    font-weight: 600;
    font-size: 0.75rem;
}

.dateField > label {
    position: absolute;

    transform: translateY(-100%);
}

.dateInput {
    box-sizing: border-box;
    padding: 0.7rem 1.5625rem;

    color: #323c47;
    font-weight: 600;
    font-size: 0.75rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.1875rem;
}

.checkboxContainer {
    display: flex;
    gap: 0.3125rem;
    align-items: center;
    margin-right: 0.625rem;
}

.campaignTotalsHeading {
    color: #192a3e;
    font-weight: 600;
    font-size: 1.125rem;
}

.totalsContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
    margin-top: 1.25rem;
}

.totalsContainer > * {
    flex: 1;
}

.totalsContainer > * + * {
    margin-left: 0.5rem;
}

.table, .breakdownGroup {
    margin: 0.5rem;
}

.table {
    margin: 0;

    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
}

.breakdownGroup h2 {
  color: #192a3e;
  font-weight: 600;
  font-size: 1rem;
}

.groupBarsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.tableHeader {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.25rem 0;
}

.tableHeader button > svg {
    margin-left: 15px;
}

.breakdownTable thead th {
    padding: 0.625rem 0.8125rem;

    color: #90a0b7;
    font-size: 0.625rem;
    line-height: 1;
    text-transform: uppercase;

    background-color: #f5f6f8;
}

.breakdownTable tbody td {
    min-width: 7rem;

    color: #58698e;
    font-weight: 500;
    font-size: 0.625rem;

    border-color: #e7eef6;
}

.breakdownTable .channelContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.breakdownTable .channelContent > * + * {
    margin-left: 0.3125rem;
}

.breakdownTable .formatCell {
    font-weight: 400;
}


.breakdownTable .creativeContainer {
    max-width: 12rem;
}

/* [BP-20]: This is a workaround to make the creatives popover appear above the modal */
div:has(.creativePopover) {
    position: relative;
    z-index: 15;
}

.amountSpentContainer {
    min-width: 7rem;
    padding: 0 0.625rem;
}

.amountSpent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    color: #192a3e;
    font-weight: 600;
}

.amountSpentPercentage {
    color: #90a0b7;
    font-weight: 400;
}

.tabs {
    margin-top: 2.5rem;
}

.tabsHeader {
    color: #192a3e;
}

.activeTabIndicator {
    background-color: #192a3e;
}

.tableDetails {
    margin: 0;

    color: #90a0b7;
    font-size: 0.7rem;
}

.tableDetailsValue {
    margin-left: 0.5rem;

    color: #4c5862;
    font-weight: 700;
}

.notSupported {
    color: #90a0b7;
}

.link,
.link:visited,
.link:hover {
    color: #90a0b7;
    text-decoration: none;
}

.amountSpentCell {
    width: 8.4375rem !important;
    min-width: 8.4375rem !important;
}

.alwaysShownCell {
    width: 12rem;
    min-width: 12rem;
}

.tableCell {
    min-width: 7rem;
}

.dropdownList {
    display: flex;
    flex-direction: column;
}

.exportIcon {
    width: 1.25rem;
    margin-right: 0.5rem;

    stroke: #323c47;
}

.exportButton {
    padding: 0.625rem 1.5625rem;

    color: #323c47;

    background-color: white;
    border-color: #c2cfe0;
}

.exportButton:hover {
    background-color: white !important;
}
