.container > * + * {
    margin-top: 1.5rem;
}

.title {
    margin: 0;

    color: #192A3E;
    font-weight: 500;
    font-size: 1.125rem;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.actions {
    display: flex;
    flex-direction: row;

    column-gap: 0.5rem;
}

.orderingSelectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.orderingSelect {
    width: 15rem;
}

.orderingSelectLabel {
    color: #90A0B7;
}

.showFiltersButton {
    border-color: #C2CFE0;
}

.showFiltersIcon {
    margin-right: 0.625rem;
}

.filters {
    display: none;
    flex-direction: column;

    gap: 1rem;
}

.filtersRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: stretch;
}

.filters.visible {
    display: flex;
}

.filter {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.375rem;
    width: 0;
}

.filterGroup {
    display: flex;
    gap: 1rem;
}

* + .filterGroup {
    margin: 0 1rem;
}

.filterGroup.pair {
    flex: 2;
}

.filterLabel {
    color: #90A0B7;
    font-weight: 400;
    font-size: 0.6875rem;
    white-space: nowrap;
}

.filterInput {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.375rem;
}

.filterInput::placeholder {
    font-size: 0.8125rem;
}

.filterSelect {
    width: 100%;
}

.filterInput, .filterSelect, .hashtagFilter {
    min-height: 2.5rem;
}

.checkboxInputContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.filterCheckboxInput {
    width: 1.5rem;
    height: 1.5rem;
}

.locationFilter {
    flex: 2.5;
}

.hashtagFilter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0.5rem 1rem;

    font-size: 0.8125rem;
    white-space: nowrap;

    background-color: transparent;

    border: solid 1px #C2CFE0;
    border-radius: 4px;
}

.hashtagFilter > span {
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.hashtagFilter:hover {
    background-color: initial;
}

.hashtagFilter svg:hover path {
    fill: hsl(0deg,0%,60%);
}

.placeholder {
    color: hsl(0deg,0%,50%)
}

.hashtagDropdown {
    min-width: 16rem;
    max-width: 22rem;

    border-radius: 4px;

    box-shadow: 0 0 0 1px hsla(0deg,0%,0%,0.1),0 4px 11px hsla(0deg,0%,0%,0.1);
}
