.cellText {
    padding: 1rem 0;

    color: inherit;
    font-size: inherit;
    text-align: inherit;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.cellText:hover {
    color: #4a87cd;
}

.cellText.readonly,
.cellText.readonly:hover {
    color: inherit;

    cursor: inherit;
}

.dropdown {
    box-sizing: border-box;
    min-width: 18.75rem;
    padding: 1rem 0.5rem 0;
}

.option {
    box-sizing: border-box;
    margin: 0.5rem 0;
    padding: 0.5rem;

    cursor: pointer;
}

.option:hover {
    background-color: #FAFAFA;
}

.option.expanded {
    background-color: #FAFAFA;
}

.optionLabels {
    position: relative;
}

.optionLabels > * {
    margin: 0;
}

.expandedIcon {
    position: absolute;
    top: 50%;
    right: 0.5rem;

    width: 0.7rem;

    color: #C4C4C4;

    transform: translateY(-50%) rotate(90deg);
}

.expandedIcon.expanded {
    transform: rotate(0);
}

.optionName {
    color: #323C47;
    font-weight: 500;
    font-size: 0.75rem;
    text-decoration: none;
}

.optionName:hover {
    text-decoration: underline;
}

.optionName.withInfluencer,
.groupName.withInfluencer {
    color: #2ED47A;
}

.optionSubtitle {
    color: #707683;
    font-size: 0.6875rem;
}

.groupsContainer {
    margin-top: 0.5rem;
}

.groupsNoticeText {
    padding: 0.5rem 0;

    color: #707683;
    font-size: 0.75rem;
    text-align: center;
}

.group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.group + .group {
    margin-top: 0.3125rem;
}

.groupName {
    color: #707683;
    font-weight: 500;
    font-size: 0.75rem;
}

.addToGroupButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;

    color: #152E4A;

    background-color: rgb(233, 244, 254);
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}
