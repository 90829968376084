.insightNumber {
	padding: 0.5rem;

	color: #109cf1;
	font-weight: 500;
	font-size: 18px;
	font-style: normal;
	line-height: 28px;
	letter-spacing: 0.1px;
}
