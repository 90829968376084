.container {
    display: flex;
    align-items: center;
    align-self: stretch;
    margin: 0;
    padding: 1.625rem 1.25rem;

    column-gap: 1rem;

    border-top: 1px solid var(--color-neutral-200, #E5E7EB);
}
