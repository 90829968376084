.values {
    position: relative;
}

.values > *[data-overflowing="overflowing"] {
    display: none;
}

.overflowingCountBadge {
    z-index: 1;

    display: flex;
}
