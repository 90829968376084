.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
}

.form .field {
    margin: 0;
}

.row {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;

    column-gap: 0.5rem;
}

.currency {
    flex: 1;
}

.cost {
    flex: 2;
}

.errorMessage {
    max-width: 100%;
    margin-top: 0.5rem;

    color: red;
    font-size: 0.7rem;
}
