.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1.875rem;
}

.title {
    margin: 0 0 0.3rem;

    font-weight: 500;
    font-size: 1.2rem;
}

.activeCampaigns {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 0.8rem;
}

.activeCampaignsBadge {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5rem;
    padding: 0.1rem 0.5rem;

    color: white;
    font-weight: 700;

    background-color: #2cbe6f;
    border-radius: 1rem;
}

.activeCampaignsBadgeIcon {
    margin-right: 0.25rem;
}

.headerRightTop {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: stretch;
}

.headerRight > * + * {
    margin-left: 0.5rem;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;

    border: 1px solid #c2cfe0;
    border-radius: 6px;
}

.search {
    width: 18rem;
    height: auto;
}

.search::placeholder {
    color: #90a0b7;
}

.currentUserPlansFilter {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: 0.5rem;

    color: #707683;
    font-size: 0.8125rem;
}

.currentUserPlansFilter > * + * {
    margin-left: 0.5rem;
}
