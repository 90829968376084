.container {
    display: flex;
    gap: 0.565rem;
    align-items: center;
    margin-bottom: 0.625rem;

    color: #111111;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.25;
}

.clearButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 0;

    color: #3451FF;

    background: transparent;

    border: none;
    cursor: pointer;
}
