.button {
    color: #90a0b7;

    background-color: transparent;
    border: none;
}

.button.active {
    color: inherit;
}
