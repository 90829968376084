.container {
    background-color: white;
    border-radius: 0.375rem;
}

.navContainer {
    display: flex;
    padding: 1rem 1.25rem;

    border-bottom: 1px solid var(--color-neutral-200, #E5E7EB);
}
