.modal {
    max-width: 40rem;
    padding: 0 1.875rem;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;

    font-size: 0.875rem;
}

.form > * + * {
    margin-top: 2rem;
}

.form label {
    margin: 0 0 0.375rem 0.375rem;

    font-weight: 500;
    font-size: 0.8125rem;
}

.form .field, .field.postUrl {
    width: 100%;

    margin: 0;

    font-size: 0.875rem;

    background: #FFF;
    border: 1px solid #D2D2D2;
    border-radius: 0.25rem;
}

.postUrl {
    padding-right: 0.625rem;
}

.postUrl.invalid {
    color: #FF3C3C;

    border-color: #FF3C3C
}

.postUrl input {
    padding-right: 0.3rem;

    font-size: 0.875rem;

    border: none
}

.form textarea {
    height: 7.75rem;
    padding: 1rem 0.875rem;

    resize: none;
}

.paymentOptionsContainer {
    display: flex;
    flex-direction: column;
}

.paymentOptionsContainer article {
    display: flex;
    gap: 0.75rem
}

.paymentButton {
    flex: 1;
    padding: 0.75rem;

    color: #3E3E3E;

    font-weight: 600;
    font-size: 0.75rem;

    background: #FFF;
    border: 1px solid #D2D2D2;
    border-radius: 0.25rem;
    cursor: pointer;
}

.paymentButton.selected {
    background: rgba(68, 68, 68, 0.05);
    border-color: #3E3E3E;
}

.error {
    margin-top: 0.5rem;

    color: #FE002B;

    font-size: 0.75rem
}

.submit {
    max-width: max-content;
    height: 2.5rem;
    padding: 0.625rem 1.5rem;
}
