.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1.5rem;

    column-gap: 1rem;

    border-bottom: 1px solid var(--Borders-Grey---Extra-light);
}

.closeButton {
    padding: 0;

    color: var(--icon-base);

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.platformTabContent {
    padding: var(--6) var(--6) var(--10);
}
