.container {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 0.625rem;
    align-items: center;
    min-height: fit-content;
}

.image {
    width: 2.75rem;
    height: 2.75rem;

    object-fit: cover;

    border-radius: 50%;
    aspect-ratio: 1/1;
}

.platformsContainer {
    display: flex;
    flex-direction: column;

    column-gap: 0.475rem;
}

.platformsContainer svg {
    width: 1rem;
    height: 1rem;
}

.row {
    flex-direction: row;
}

.platform {
    display: flex;
    gap: 0.625rem;
    align-items: center;
}
