.container {
    display: flex;

    column-gap: 1rem;
}

.thumbnail {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
}

.info {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 0.625rem;
    align-items: baseline;
    margin-bottom: 0.2rem;
}

.titlesColumn {
    display: flex;
    flex-direction: column;
}

.title {
    margin: 0;

    color: #323c47;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;
}

.title:hover {
    text-decoration: underline;
}

.badges {
    display: flex;

    column-gap: 0.25rem;
}

.badge {
    padding: 0.2rem 0.5rem;

    color: white;
    font-size: 0.6rem;
}

.badge.loading {
    /*
        Well, it's because of how skeleton's markup.
        Because it has container element it gives space around
        skeleton bar.
     */
    padding: 0;
}

.badge.original,
.badge.engagement {
    background-color: #ed7200;
}

.badge.official {
    background-color: #2a5484;
}

.badge.enhanced {
    background-color: var(--color-success);
}
