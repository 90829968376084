.container {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.headlines {
    display: flex;
    gap: 1.25rem;
    align-items: center;
    justify-content: center;
}

.card {
    padding: 0.5rem 0.75rem;

    background: var(--color-neutral-50);
    border: 1px solid var(--color-neutral-200);
    border-radius: 0.75rem;
}

.countRow {
    composes: font-md-base from 'ui/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
}

.success {
    color: var(--color-status-success);
}

.failure {
    color: var(--color-status-fail);
}

.faded {
    color: var(--color-neutral-400);

    opacity: 0.5;
}

.failureDetails {
    composes: font-md-sm from 'ui/utils.css';

    min-width: 20rem;

    list-style: none;
}

.failureDetails summary {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
}

.chevron {
    color: var(--color-neutral-500)
}

.chevronOpen {
    transform: rotate(180deg);
}

.failuresList {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin: 1rem 0 0 1rem;
    padding: 0;
}

.failureRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.failureRow > * {
    margin: 0;
}
