.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.button {
    cursor: pointer;

    transition: all 0.2s linear;

    stroke: #ccc;
}

.button.editButton:hover {
    stroke: #109cf1;
}
