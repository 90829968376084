.container {
    display: flex;

    margin-top: 0.5rem;
    padding: 0.5rem;

    overflow: hidden;

    background: var(--Background-light, #F9FAFB);

    border-radius: var(--rounded, 4px);
}

.container.expanded {
    flex-direction: column;
    align-items: stretch;
    row-gap: var(--3, 0.75rem);
}

.container.noValues {
    display: none;
}

.values {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;

    gap: 0.5rem;
    align-items: center;

    max-width: 100%;

    overflow-x: hidden;
}

.values.expanded {
    flex-wrap: wrap;
}

.value {
    display: flex;
    gap: 0.25rem;
    align-items: baseline;
    padding: var(--1, 4px) var(--Spacing-Spacing-1, 4px) var(--1, 4px) var(--Spacing-Spacing-2, 8px);

    color: #555555;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1;
    white-space: nowrap;

    background: var(--Background-brand, #F9F8FF);
    border: var(--Spacing-Spacing-px, 1px) solid var(--Borders-Disabled, rgba(0, 0, 0, 0.05));
    border-radius: var(--Radius-Radius-rounded-md, 6px);
}

.value.hidden {
    visibility: hidden;
}

.removeValueButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.removeValueIcon {
    width: 0.5rem;
    height: 0.5rem;
}

.actions {
    display: flex;
    justify-content: space-between;
}

.actionButton {
    composes: p-extra-small-medium from 'ui-new/whitelabel/utils/utils.css';

    padding: 0;

    color: var(--Text-Base);

    background-color: transparent;

    border: none;
    cursor: pointer;
}

.showMoreButton {
    padding: var(--1, 4px) var(--2, 8px) var(--1, 4px) var(--Spacing-Spacing-2, 8px);

    background: var(--Background-white, #FFF);
    border: var(--Spacing-Spacing-px, 1px) solid var(--Borders-Disabled, rgba(0, 0, 0, 0.05));
    border-radius: var(--Radius-Radius-rounded-md, 6px);
}
