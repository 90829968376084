.Modal {
	position: absolute;
	top: 50%;
	right: auto;
	bottom: auto;
	left: 50%;

	min-width: 20rem;

	background-color: white;
	border-radius: 0.25rem;
	outline: none;
	box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);
	transform: translate(-50%, -50%);
}

.Overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;

	background-color: rgba(0, 0, 0, 0.4);
}
