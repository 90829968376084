.tooltip {
    min-width: 12.6875rem;
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--icon-base);
}

.ghostIcon {
    color: var(--Icon-light, #F6F6F6);
}

.stats {
    margin: 0;
    padding: 0;

    white-space: nowrap;
}

.item {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.skeleton {
    background: #EBEBEB80;
    opacity: 0.5
}

.skeletonContainer {
    min-width: 3.5rem;
}
