@media screen and (min-width: 992px) {
    .content {
        margin: 2rem 3rem;
    }
}

.mobileContent {
    min-height: 100vh;

    background: white;
}
