.headlineCalculations {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.875rem;
    margin-top: 1.25rem;
}

@media screen and (min-width: 1200px) {
    .headlineCalculations {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow-x: auto;
    }
}

.headlineCalculations > * {
    flex: 1;

    white-space: nowrap;
}
