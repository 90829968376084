.input {
    padding: 0.25rem 0.5rem;

    background-color: transparent;
    border: 1px solid transparent;

    border-radius: 0.3125rem;
}

.input:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.input:not(:read-only):focus {
    border: 1px solid var(--Borders-Interactive---Solid, #2823F6);
}

.input:not(:read-only):focus, .input:not(:read-only):not(:disabled):hover {
    background-color: #FFF;
}

.input:not(:read-only):not(:focus):not(:disabled):hover {
    border: 1px solid var(--Borders-Grey---Light, #D1D1D1);
}
