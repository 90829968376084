.searchContainer {
    composes: text-small-regular from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--Spacing-Spacing-2, 8px);
    align-items: center;
    padding: var(--15) var(--Spacing-Spacing-2) var(--15) var(--Spacing-Spacing-35);

    background: var(--Input-surface);
    border: 1px solid var(--Input-Border);
    border-radius: var(--Radius-Radius-rounded);
}

.search {
    flex: 1;

    border: none;
    outline: none;
}

input.search::placeholder {
    color: var(--text-black, #000);
}
