.container > * + * {
    margin-top: 1.25rem;
}

.title {
    margin: 0;

    color: #192A3E;
    font-weight: 500;
    font-size: 1.125rem;
}

.row, .filtersRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.search {
    min-width: 25%;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
}

.buttonsContainer > * + * {
    margin-left: 0.625rem;
}

.showFiltersButton {
    border-color: #C2CFE0;
}

.showFiltersIcon {
    margin-right: 0.625rem;
}

.filtersRow {
    align-items: flex-end;
}

.filtersRow > * + * {
    margin-left: 1.875rem;
}

.filtersGroup {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-start;
}

.filtersGroup > * {
    flex: 1;
}

.filtersGroup > * + * {
    margin-left: 0.625rem;
}

.tagsFilters {
    flex: 3;
}

.tagsFilter {
    flex: 3;
}

.locationsFilter {
    flex: 2;
}

.filter {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.filterLabel {
    color: #90A0B7;
    font-weight: 400;
    font-size: 0.6875rem;
}

.filterInput {
    box-sizing: border-box;
    width: 100%;
    padding: 0.5rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.375rem;
}

.apexOnlyFilterWrapper {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;
}

.apexOnlyFilter {
    width: 1.5rem;
    height: 1.5rem;
}

.categoriesFilter {
    flex-grow: 0;
    flex-shrink: 1;
    align-self: stretch;
    min-width: max-content;

    font-size: 0.75rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.375rem;
}
