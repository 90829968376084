.container {
    margin: 2rem 3rem;
    padding: 1.875rem;
}

.main {
    display: flex;
    flex-direction: column;
    width: 100%;
}
