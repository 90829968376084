.modal {
    display: flex;
    flex-direction: column;
}

.table {
    height: 100%;
}

.menuContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem var(--6) 0 var(--6);
}

.filterMenu {
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.searchInput {
    max-width: 16.25rem;
}

.countLabel {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.selectedOnlyFilter {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;

    color: #000;
}
