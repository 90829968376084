.addHashtagButton {
    padding: 0;
}

.list {
    min-width: 18.75rem;
    max-height: 18.75rem;
    margin: 0.625rem 0 0;
    padding: 0;
    overflow-y: auto;
}

.deleteButton {
    display: none;
    padding: 0;

    color: #f7685b;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    padding: 0.9375rem;

    text-decoration: none;

    cursor: pointer;
}

.listItem:hover {
    background-color: #fafafa;
}

.listItem:hover .deleteButton {
    display: inline-block;
}

.audio {
    color: #323c47;
    font-weight: 500;
    font-size: 0.875rem;
}

.deleteIcon {
    width: 1.125rem;
    height: 1.125rem;
}
