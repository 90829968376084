.table {
    width: 100%;

    user-select: none;
}

.table th {
    white-space: nowrap;
}

.table thead th,
.table tbody td {
    max-width: 15rem;
}

.table thead th:first-child,
.table tbody td:first-child {
    width: 1rem;
    min-width: 0;
}

.table thead th:nth-child(2),
.table tbody td:nth-child(2) {
    max-width: none;
}

.account {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.avatar {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
}

.accountDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.nickname {
    display: flex;
    align-items: baseline;

    color: #323C47;
    font-weight: 500;
    font-size: 0.875rem;

    column-gap: 0.375rem;
}

.verifiedIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;

    background-color: rgb(32, 213, 236);
    border-radius: 1rem;
}

.uniqueId {
    color: #4c5862;
    text-decoration: none;
}

.uniqueId:hover {
    text-decoration: underline;
}

.privateAccountBadge {
    color: white;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1;

    background: var(--color-accent-blue);

    border-radius: 0.5rem;
}

.location {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    column-gap: 0.5rem;
}

.tagsSelect {
    min-width: 10rem;
}

.anchor {
    outline: var(--color-accent-blue) solid 0.5px;
}
