.container {
    display: flex;
    flex-direction: column;
    row-gap: 0.125rem;
}

.scoreRow {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;

    white-space: nowrap;

    column-gap: 0.25rem;
}

.scoreRow .score {
    color: #111;
    font-weight: 500;
    font-size: 0.875rem;
}

.topGenres {
    font-size: 0.675rem;
}

.topGenresLabel {
    font-weight: 500;
}
