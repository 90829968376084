.container {
    display: flex;
    flex-direction: column;
    gap: var(--4);
    align-items: stretch;
    margin-top: var(--3);
}

.container menu {
    display: flex;
    justify-content: flex-end;
}

.orderingSelect {
    min-width: 17.5rem;
}
