.form {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.form > * {
    box-sizing: border-box;
}

.formSplitSection {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}

.costColumn {
    display: flex;
    flex-direction: column;
}

.formSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.375rem;
}

.formLabel {
    margin: 0.65rem 0 0.2rem;

    font-weight: 500;
    font-size: 0.9rem;
}

.costField {
    display: flex;
    flex-direction: row;
    gap: 0.225rem;
    align-items: flex-start;
}

.costField > * + * {
    margin-left: 0.3125rem;
}

.notesInput {
    width: 100%;;
    height: 100%;
    min-height: 7.1rem;
    padding-top: 0.6rem;

    font-weight: 400;
    font-size: 0.925rem;
    line-height: 1.15;
}

.notesInput::placeholder {
    font-size: 0.925rem;
}

.currencySelect,
.costInput,
.notesInput,
.notesInput:focus,
.contactDetails,
.paypalEmail {
    background-color: #ffffff;
    border-color: #c2cfe0;
}

.currencySelect {
    display: flex;
    flex: 1 2;
    align-items: center;

    background-color: #e4e7ec;
}

.costInput {
    flex: 1 2;
    max-width: 10rem;
}

.costInput,
.contactDetails,
.paypalEmail {
    height: 2.4rem;
    padding: 0.5rem;

    font-size: 0.9rem;

    border-style: solid;
    border-width: 0.0625rem;
    border-radius: 0.3rem;
}

.errorMessage {
    margin-top: 0.25rem;

    color: red;
    font-size: 0.75rem;
}
