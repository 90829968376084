.campaignColumn {
    width: 20rem;
    max-width: 20rem;
}

.campaignContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.campaignActionsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* This combined with visibility: hidden will hide the container, but keep the
    parent cell wide enough to hold the content */
    height: 0;

    column-gap: 0.25rem;

    visibility: hidden;
    opacity: 0;

    transition: height 75ms linear, opacity 100ms linear;
    transition-delay: 50ms;
}

.campaignActionButton {
    padding: 0;

    color: var(--Text-Weak, #767676);
    font-weight: var(--Weight-500, 500);
    font-size: var(--Size-10, 10px);

    /* P Extra Small Medium */
    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-16, 16px);
    letter-spacing: var(--Letter-Spacing-0, 0);

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.campaignActionButton:hover,
.campaignActionButton:active {
    color: var(--Text-interactive, #2823f6);
}

.table tbody tr:hover .campaignActionsContainer {
    height: 1rem;

    visibility: visible;
    opacity: 1;
}

.teamContainer {
    display: flex;
    align-items: center;
}

.teamContainer > * + * {
    margin-left: -0.75rem;
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30rem;
}

.deleteCampaign {
    color: var(--Text-destructive, #ff003f);
}

.plusIcon {
    width: 0.875rem;
    height: 0.875rem;
}

.amountSpentContainer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    max-width: 8rem;
}

.amountSpent {
    composes: p-small-bold from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;

    color: var(--Text-Base, #242424);
}

.spentPercentage {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weakest, #b0b0b0);
}

.spentTrack {
    height: 0.25rem;

    background: var(--Background-dark, #e7e7e7);
}

.spentBar {
    height: 0.25rem;

    color: var(--Campaign-Status-Planned);
}

.dailyChange {
    width: 8.25rem;
}

.actionsCell {
    width: 1.25rem;
}
