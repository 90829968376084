.select input {
    height: auto;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

/* Detailed targeting input */
.showButton {
    padding: 0.5rem 1rem;

    transition: color 0.25s ease-in-out;
}

.showButton + .showButton {
    margin-left: 0.5rem;
}

/* Selection Form */
.selectionForm {
    margin: 0.5rem 0;
}

.selectionFormTitle {
    margin: 0;

    font-weight: 400;
    font-size: 0.8rem;
}

.optionLabel {
    margin: 0;
}

.optionType {
    color: #999999;
}

.infieldButton {
    color: #7a7a7a;

    background-color: transparent;
    border: none;

    transition: color 0.25s ease-in-out;
}

.infieldButton:hover {
    color: #444444;

    cursor: pointer;
}

/* Selection list */
.selectionList {
    max-height: 15rem;
    margin: 0.5rem 0;
    overflow-y: auto;

    background-color: #fbfbfd;
}

.selectionDescriptionContainer {
    box-sizing: border-box;
    width: 350px;

    background-color: white;
    border-radius: 0.2em;
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.24);
}
