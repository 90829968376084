.tabs {
    width: 100%;
}

.header {
    position: relative;

    width: 100%;
}

.activeTabSelector {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 4px;

    background-color: #334d6e;

    transition: all 0.6s ease-in;
}

.header ul {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 45px;
    margin: 0;
    padding: 0;

    color: #9faab9;

    list-style: none;
    background-color: #ffffff;
    border-bottom: 2px solid #9faab9;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.header ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    height: 100%;

    font-weight: 600;
    font-size: 0.7rem;

    cursor: pointer;
}

.header ul li.activeTab {
    color: #334d6e;
}

.content {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.contentInner {
    display: flex;
    width: 100%;

    transition: all 0.6s ease-in;
}

.contentInner > div {
    align-self: flex-start;
    min-width: 100%;
    height: 0;

    background-color: #fff;
}

.contentInner > .activeContent {
    position: relative;
    z-index: 1;

    height: initial;

    box-shadow: 0 6px 18px #0000000f;
}
