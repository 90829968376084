@layer base {
    .textarea {
        box-sizing: border-box;
        width: 100%;
        height: 46px;
        min-height: 46px;
        padding-left: 0.6rem;

        font-size: 1rem;
        font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 0.3rem;
        outline: none;

        resize: vertical;
    }

    .textarea:focus {
        color: #626262;

        background-color: #eee;
        border: 1px solid rgba(0, 0, 0, 0.2);
    }

    .textarea:disabled {
        resize: none;
    }
}
