.bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: white;

    background: linear-gradient(90.1deg, #60678f 20.25%, #838bb3 102.04%);
}

.infoColumns {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.toolbar {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-end;
    min-width: 10rem;
}

.infoColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;

    white-space: nowrap;
}

.infoColumn.name {
    min-width: 14rem;
}

.infoColumnTitle {
    font-weight: 500;
    font-size: 0.8125rem;
}

.infoColumnValue {
    color: #dae3ee;
    font-weight: 400;
    font-size: 0.6875rem;
}

.infoColumnTitle, .infoColumnValue {
    max-width: 100%;
    overflow: hidden;

    text-overflow: ellipsis;
}

.barButton {
    padding: 0 1.25rem;

    color: #ffffff;
    font-weight: 600;
    font-size: 0.75rem;

    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandButton > svg {
    transform: rotate(-90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(-180deg);
}
