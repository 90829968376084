.container {
    position: relative;

    display: flex;
    flex-direction: column;
    padding: 0.275rem 0.75rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}

.container > * + * {
    margin-top: 0.25rem;
}

.title {
    margin: 0;

    color: #90a0b7;
    font-weight: 600;
    font-size: 0.4775rem;
    white-space: nowrap;
    text-transform: uppercase;
}

.values {
    display: flex;
}

.values > * + * {
    margin-left: 0.3125rem;
}

.value {
    margin: 0;

    font-weight: 600;
    font-size: 0.525rem;
}

.additionalValue {
    color: #58698e;
    font-size: 0.475rem;
    font-style: oblique;
    white-space: nowrap;
}

.hintIconContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.25rem;
    height: 1.25rem;

    color: #90a0b7;

    background-color: #e4e7ec;
    border-radius: 50%;
    cursor: help;
}

.hintIcon {
    width: 0.75rem;
    height: 0.75rem;
}

.hintPopover {
    max-width: 15rem;
    padding: 0.5rem;

    font-size: 0.75rem;

    background-color: white;
    border: 1px solid #90a0b7;
    border-radius: 0.25rem;
}

@media screen and (min-width: 600px) {
    .container {
        padding: 0.625rem 1rem;
    }

    .title {
        font-size: 0.5275rem;
    }

    .value {
        font-size: 0.725rem;
    }

    .additionalValue {
        font-size: 0.525rem;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        padding: 0.875rem 1.5rem;
    }

    .title {
        font-size: 0.6875rem;
    }

    .value {
        font-size: 0.875rem;
    }

    .additionalValue {
        font-size: 0.625rem;
    }
}
