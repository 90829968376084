.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;

    background: transparent;

    border: none;
    cursor: pointer;
}

.button:hover {
    background: transparent;
}

.button svg {
    width: 0.875rem;
    height: 0.875rem;

    color: #6B7280;
}

.button:hover svg {
    color: #111;
}
