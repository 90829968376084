.valueContainer {
    font-size: 0.8125rem;

    cursor: pointer;
}

.dropdown {
    width: 18.75rem;
    max-height: 20rem;
    padding: 0.5rem 0;
    overflow-y: auto;
}

.presetOption {
    display: flex;
    flex-direction: row;
    padding: 0.5rem;

    font-weight: 500;
    font-size: 0.8125rem;

    cursor: pointer;
}

.presetOption > * + * {
    margin-left: 0.5rem;
}

.presetOption:hover {
    background-color: var(--color-background-4);
}

.presetOption.selected {
    color: var(--color-accent-blue);
}

.customMetricsList {
    height: 0;
    overflow: hidden;

    visibility: hidden;

}

.customMetricsList.visible {
    height: auto;

    visibility: visible;
}

.customMetricOption {
    display: flex;
    flex-direction: row;
    padding: 0.125rem 1rem;

    color: black;
    font-weight: 400;
    font-size: 0.8125rem;

    cursor: pointer;
}

.customMetricOption:hover {
    background-color: var(--color-background-4);
}

.customMetricOption > * + * {
    margin-left: 0.5rem;
}
