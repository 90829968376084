.header {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    overflow: hidden;

    background-color: #e4e7ec;
    border-radius: 0.375rem 0.375rem 0 0;
}

.releaseContainer {
    min-width: 26%;
}

.tabNavigation {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-right: 1.25rem;
}

.tabLink {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    padding: 1rem 1.25rem;

    color: #58698e;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;

    border-radius: 0.375rem 0.375rem 0 0;
}

.tabLink.active {
    background: white;
}

.tabLinkIcon {
    margin-right: 0.625rem;
}

.content {
    padding: 1.225rem;

    border-radius: 0 0 0.1875rem 0.1875rem;
}

@media screen and (min-width: 1200px) {
    .tabLink {
        padding: 1rem 2.1875rem;
    }

    .content {
        padding: 1.875rem;

        border-radius: 0 0 0.1875rem 0.1875rem;
    }
}
