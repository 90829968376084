.row {
    color: #707683;
    font-size: 0.8rem;
    letter-spacing: 0.1px;

    background-color: white;
    cursor: pointer;
}

.row.readonly {
    cursor: default;
}

.td {
    padding: 0.375rem 1.125rem 0.375rem 0.375rem;

    white-space: nowrap;

    border: 1px solid #e7eef6;
}

.td input:hover,
.td input:focus {
    background-color: rgb(231, 238, 246, 0.9);
}

textarea:hover,
textarea:focus {
    background-color: rgb(231, 238, 246, 0.9);
}

.enabled {
    color: #2ed47a;
}

.disabled {
    color: #b0acacee;
}

.cursorPointer {
    cursor: pointer;
}

.accountCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.accountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    white-space: nowrap;
}

.accountContainer > * + * {
    margin-left: 0.375rem;
}

.accountDetails {
    display: flex;
    flex-direction: column;
}

.influencerThumbnail {
    width: 2.25rem;
    height: 2.25rem;
    object-fit: cover;

    border-radius: 50%;
}

.influencer {
    color: #4a87cd;
    text-decoration: none;
}

.contactCell {
    min-width: 9rem;
}

.creativeField {
    min-width: 8rem;
    max-width: 8rem;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.statusButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.editableHover:hover {
    box-shadow: inset 0 0 1px #0090ff;
}

.statusButton.readonly {
    cursor: inherit;
}

.status {
    color: #58698e;
    font-weight: 500;
    font-size: 0.75rem;
}

.linkIcon {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 1.5rem;

    color: var(--color-grey-4);

    cursor: pointer;
}

.statusContainer:hover > .linkIcon {
    color: var(--color-primary-2);
}

.editorCell {
    background-color: #fdffef;
}

.notesCell {
    min-width: 10rem;
    max-width: 12rem;
    padding: 0.2rem 1.125rem 0.2rem 0.4rem;

    background-color: #fdffef;
    border: 1px solid #e7eef6;
}

.invalid {
    outline: 1px auto rgba(247, 104, 91, 0.5);
}

.wideInput {
    width: 100%;
    min-height: 1.15em;
    max-height: 2.9em;
    padding: 0.3rem;
    overflow: scroll;

    color: inherit;
    overflow-wrap: normal;

    background-color: #fdffef;
    border: none;

    resize: none;
    scrollbar-width: none;
}

.wideInput::-webkit-scrollbar {
    display: none;
}

.instagramInfluencerModalButton {
    margin-left: 0.625rem;
    padding: 0.25rem;

    color: #90a0b7;

    visibility: hidden;
}

.instagramInfluencerModalButton:hover {
    color: #58698e;

    background-color: #e7eef680;
}

.accountCell:hover .instagramInfluencerModalButton {
    visibility: visible;
}

@keyframes highlightFlash {
    12% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    24% {
        outline: none;
    }

    36% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    48% {
        outline: none;
    }

    60% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    72% {
        outline: none;
    }

    84% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }
}

.highlighted {
    animation-name: highlightFlash;
    animation-duration: 2s;
}

.paymentRequestContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
}

.paymentRequestButton {
    min-width: 1rem;
    padding: 0.125rem;
}

.paymentRequestIcon {
    width: 1.125rem;
    height: 1.125rem;
}

.paymentRequestContainer .paid {
    color: var(--color-success);
}

.paymentRequestContainer .inProgress {
    color: var(--color-warning);
}

.paymentRequestContainer .failed {
    color: var(--color-danger);
}

.paymentRequestContainer .requested {
    color: var(--color-info)
}

.paymentRequestContainer .unrequested {
    color: var(--color-grey-4);
}
