.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.header {
    composes: font-bold-2xl from 'ui/utils.css';

    margin: 3.75rem 0 2.5rem;

    text-align: center;
}

.main {
    composes: scroll from 'ui/utils.css';

    flex: 1;
    max-width: 42rem;
    overflow-y: auto;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 1.75rem 2.5rem;

    column-gap: 0.75rem;

    border-top: 1px solid var(--color-neutral-300);
}
