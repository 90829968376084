.content {
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
}

.topRow {
    display: flex;
    flex-wrap: wrap;
    gap: 0.875rem;
    align-items: flex-start;
    justify-content: flex-start;
}

.dataCards {
    display: grid;
    flex: 3;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 0.875rem;
}

@media screen and (min-width: 1200px) {
    .dataCards  {
        display: flex;
        gap: 1.25rem;
    }
}

.refreshDataButtonIcon {
    margin-right: 0.3125rem;
}

.actionButtons {
    flex: 1;
    flex-wrap: wrap;
    gap: 0.625rem;
    max-width: 40%;
}

.actionButtons > * {
    flex: 1;
}

.actionButton {
    padding: 0.625rem;

    color: #323c47;
    white-space: nowrap;

    border-color: #c2cfe0;
}

@media screen and (min-width: 1200px) {
    .actionButtons {
        flex-wrap: nowrap;
    }

    .actionButton {
        padding: 0.625rem 1.25rem;
    }
}

.actionButton:disabled {
    color: #323c4750 !important;

    background-color: #c2cfe050 !important;
    border-color: transparent;
}

.actionButtonIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.625rem;
}

.navigationsRow {
    display: flex;
    flex: 1;
    gap: 0.875rem;
    justify-content: space-between;
    width: 100%;
    margin-top: 1.875rem;
}

.tabs {
    flex: 1;
    max-width: 50%;
}

.tabNavItem {
    flex: 1;
    padding: 0.625rem;
}

.tabIcon {
    height: 1.2rem;
    margin-right: 0.625rem;
}

.toolbar {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.25rem;
}

.toolbar > * + * {
    margin-left: 0.625rem;
}

.microsPostInputWrapper {
    display: flex;
    align-items: center;
}

.closeIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.3125rem;
    padding: 0.25rem;

    background-color: #192a3e;
    border: none;

    border-radius: 0.25rem;
    cursor: pointer;
}

.closeIconWrapper svg {
    width: 0.5rem;
    height: 0.5rem;
}

.closeIconWrapper svg path {
    fill: white;
}

.microsPostInput {
    flex: 1;
    width: 18rem;
    padding: 0.3125rem;

    color: white;
    font-weight: 500;
    font-size: 0.75rem;

    background-color: #192a3e90;
    border: 1px solid #192a3e;
    outline: none;
}

.microsPostInput input::placeholder {
    color: #cbd2dc;

    opacity: 1;
}

.navigationsRowBadges {
    display: none;
    flex: 1;
    justify-content: flex-end;
}

.navigationsRowBadges.visible {
    display: flex;
}

@media screen and (min-width: 1200px) {
    .tabs {
        max-width: 32rem;
    }
}

.dataCardValue {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    overflow: hidden;
}

.currencyMismatchWarning {
    color: var(--color-status-warning);

    background-color: transparent;
}

.currencyMismatchWarning > svg {
    width: 100%;
    height: 100%;
}
