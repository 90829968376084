.badge {
    max-width: 3.125rem;
    padding: 0 0.3125rem;
    overflow: hidden;

    color: white;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;

    background: #7a61df;
    border-radius: 0.25rem;
}

.clickable {
    cursor: pointer;
}
