.button {
    color: var(--color-neutral-400, #9CA3AF);
}

.button svg {
    width: 1.25rem;
    height: 1.25rem;
}

.button:hover {
    color: #111;
}

.button:disabled {
    color: var(--color-neutral-200, #E5E7EB);
}
