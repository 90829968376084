.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-width: 7.5rem;
    margin-bottom: -0.325rem;
}

.row > * {
    box-sizing: border-box;
    margin-right: 0.325rem;
    margin-bottom: 0.325rem;
}

.row > *:last-child {
    margin-right: 0;
}
