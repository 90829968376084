.container {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    justify-content: space-between;
}

.linkIcon {
    width: 1rem;
    height: 1rem;

    color: var(--color-neutral-400)
}

.container .linkIcon:hover {
    color: var(--color-grayscale-100);
}

.linkIcon path {
    stroke-width: 2;
}
