.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}

.input {
    flex: 1;
    padding: 0 0.625rem;

    background-color: transparent;
    border: none;
    outline: none;
}
