.filterIcon {
    width: 1.25rem;
    height: 1.125rem;

    color: var(--color-neutral-400);
}

.conditionsList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.5rem;
}

.conditionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.typeSelect {
    min-width: fit-content;
}

.tagsSelect {
    width: 15.625rem;
}

.icon {
    width: 1.125rem;
    height: 1.125rem;
}

.removeConditionButton {
    height: min-content;
    padding: 0.25rem;
}

.addConditionButton {
    width: min-content;
    padding: 0.5rem;
}
