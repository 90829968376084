.badge {
    composes: font-sm from 'ui/utils.css';

    display: inline-flex;
    gap: 0.375rem;
    align-items: center;
    height: 1.875rem;
    padding: 0.25rem 0.5rem 0.25rem 0.75rem;

    color: var(--color-grayscale-100);

    white-space: nowrap;

    background-color: var(--color-neutral-200);
    border: none;
    border-radius: 1.25rem;
    box-shadow: none;
}

.badge.interactive {
    cursor: pointer;
}

.badge.interactive:hover {
    background: var(--color-neutral-300);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

.disabled {
    cursor: not-allowed;
}
