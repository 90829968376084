.searchInput {
    background-color: #f5f6f8;
    border: 1px solid #c2cfe0;
}

.list {
    min-height: 4.75rem;
    max-height: 18.75rem;
    margin-top: 0.625rem;
}

.noOptionsLabel {
    display: block;
    padding: 1rem 0;

    color: #707683;
    text-align: center;
}
