.nextLabel,
.prevLabel {
    color: #192a3e;
}

.nextLabel {
    transform: rotate(90deg);
}

.prevLabel {
    transform: rotate(-90deg);
}
