.countBadge {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    align-items: center;
    justify-content: center;

    width: 1.125rem;
    height: 1.125rem;

    color: white;

    background-color: var(--color-brand-secondary);
    border-radius: 50%;
}
