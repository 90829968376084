.header {
    background: linear-gradient(90deg, #2C3E50 0%, #3498DB 100%);
}

.goBackContainer {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;

    background-color: rgba(0, 0, 0, 0.05);
}

.goBackButton {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: inline-flex;
    gap: 0.125rem;
    align-items: center;

    color: white;
    text-decoration: none;
}

.goBackButton:active,
.goBackButton:visited,
.goBackButton:focus {
    color: white;
}

.goBackButtonIcon {
    color: white;

    transform: rotate(90deg);
}

.reportData {
    padding: 1rem 1.5rem;
}

.tabsNav {
    padding: 0.5rem 1.5rem 0;
}

.menu {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0.5rem 1.5rem 0;
}

.campaigns {
    padding: 0 1.5rem;
}
