.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1.25rem;
    width: 100%;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.375rem;
    width: 100%;
}

.influencerRow {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.influencerSelectContainer {
    max-width: 14rem;
}

.selectLabel {
    composes: text-font-sm from 'ui/utils.css';

    color: var(--color-grayscale-50, #767676);
}
