.container {
    margin: 2rem;
    padding: 2.25rem;
}

.container > * + * {
    margin-top: 0.625rem;
}

.title {
    margin: 0;

    font-weight: 500;
    font-size: 1.2rem;
}

.searchInput {
    max-width: 20rem;
}
