.moneyInput {
    align-items: flex-start;
}

.spendingType {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
}
