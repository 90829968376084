.container > * + * {
    margin-top: 1.25rem;
}

.title {
    margin: 0;
}

.numberOfPostsUsed {
    margin: 0;

    color: #707683;
    font-size: 0.8125rem;
}

.audioTypesRow {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.audioTypesRow > * + * {
    margin-left: 0.625rem;
}
