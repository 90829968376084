.container {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;
    min-width: 12rem;

    color: var(--color-neutral-500);
}

.container a {
    color: var(--color-grayscale-100);
    text-decoration: none;
}

.container a:hover {
    text-decoration: underline;
}

.title {
    composes: font-medium from 'ui/utils.css';
    composes: line-clamp-2 from 'ui/utils.css';
}

.image {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
    aspect-ratio: 1/1;
}
