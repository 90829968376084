.container {
    position: relative;

    width: 5.625rem;
    height: 1.875rem;
}

.tooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.tooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.spinnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}
