.value {
    font-weight: 500;
    font-size: 0.75rem;
}

.value.paid {
    color: #48d98a;
}

.value.unpaid {
    color: #f7685b;
}

.value.invoiceReceived {
    color: #0090ff;
}
