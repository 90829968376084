.modal {
    padding: 1.875rem
}

.form {
    width: 26.875rem;
}

.form > * + * {
    margin-top: 0.8125rem;
}

.title {
    margin: 0;
}

.section {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.status {
    width: 20rem;

    font-size: 0.7rem;
}


.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.row > * {
    flex: 1;
}

.row > * + * {
    margin-left: 0.65rem;
}

.error {
    color: red;
    font-size: 0.75rem;
}
