.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.5rem;
}

.titles {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    /* image width + gap*/
    max-width: calc(100% - 3.375rem);
}

.image {
    width: 2.875rem;
    height: 2.875rem;

    border-radius: 50%;
}

.nickname {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
}

.username {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-grayscale-40);
    text-decoration: none;
}

.username:hover {
    text-decoration: underline;
}
