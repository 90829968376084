.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    color: #707683;
    font-size: 0.75rem;
}

.container a {
    color: #192A3E;
    text-decoration: none;
}

.container a:hover {
    text-decoration: underline;
}

.title {
    font-weight: 500;
    font-size: 0.825rem;
}

.image {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
    aspect-ratio: 1/1;
}
