.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.container > * + * {
    margin-left: 0.625rem;
}

.label {
    min-width: 6.875rem;

    font-weight: 500;
    font-size: 0.75rem;
}

.tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.2rem;
}

.tag {
    padding: 0.125rem 0.3125rem;

    font-weight: 500;
    font-size: 0.625rem;
}

.tag > * + * {
    margin-left: 0.3125rem;
}

.tag.category {
    background-color: #7A61DF;
}

.tag.location {
    background-color: #EF5DA8;
}

.deleteButton {
    padding: 0;

    background: transparent;
    border: none;
    cursor: pointer;
}

.deleteIcon {
    width: 0.5rem;
    height: 0.5rem;

    color: white;
    stroke-width: 2;
}

.addTagButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}
