.wrapper > * + * {
    margin-top: 1.25rem;
}

.title {
    margin: 0;

    font-weight: 500;
    font-size: 1.15rem;
}

.filters {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-start;
}

.filters > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
}

.filters label {
    margin-left: 0.25rem;

    font-weight: 500;
    font-size: 0.875rem;
}

.datePicker {
    min-width: 14rem;
    min-height: 2.125rem;

    font-weight: 500;

    border: 1px solid hsl(0deg,0%,80%);
    border-radius: 0.25rem;
}
