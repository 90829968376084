.tabsNav {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    column-gap: 0.75rem;
}

.tab {
    display: flex;
    gap: var(--1);
    align-items: center;
    padding: 0.375rem 0.75rem;

    color: white;
    font-weight: 500;
    font-size: 0.75rem;
    font-family: Inter, sans-serif;

    background-color: rgba(255, 255, 255, 0.20);
    border: none;
    border-radius: 0.25rem 0.25rem 0 0;
    cursor: pointer;
}

.tab:not(.active):hover {
    background-color: rgba(255, 255, 255, 0.30);
}

.tab.active {
    color: inherit;

    background-color: white;
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.inactiveIcon {
    color: var(--Icon-white);
}
