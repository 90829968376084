.modal {
    min-width: 40rem;
    min-height: 25rem;
}

.hidden {
    display: none;
}

.briefField {
    min-width: 38rem;
    min-height: 12rem;
}

.turnaroundField {
    resize: none;
}
