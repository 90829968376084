.button {
    width: min-content;
    height: min-content;
    padding: 0;

    background: transparent;
    border: none;

    cursor: pointer;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--color-neutral-400)
}

.button:hover .icon{
    color: var(--color-neutral-500)
}
