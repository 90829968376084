.field {
    display: flex;
    flex-direction: column;
}

.field + .field {
    margin-top: 0.8125rem;
}

.field > * + * {
    margin-top: 0.5rem;
}

.field input,
.field textarea {
    padding: 0.625rem 1rem;

    font-size: 0.8125rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.1875rem;
}

.field input::placeholder,
.field textarea::placeholder {
    color: #90a0b7;
}
