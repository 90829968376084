.container {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
}

.container > * + * {
    margin-left: 0.25rem;
}

.bar {
    flex-grow: 0;
    flex-shrink: 0;
    width: 0.25rem;

    border-radius: 4.375rem;
}

.tooltip {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    padding: 0.25rem 0.375rem;

    background-color: #ffffff !important;
    border: 1px solid #e7eef7 !important;
    box-shadow: 0 2.7730770111083984px 9.705769538879395px 0 #0000000f !important;
}

.tooltip .timestamp {
    color: #323c47;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1rem;
}

.tooltip .value {
    color: #58698e;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
}
