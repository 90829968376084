.inputContainer {
    display: flex;
    align-items: center;

    padding: var(--15) var(--Spacing-Spacing-35, 0.875rem);

    color: var(--Text-primary, #1C1C1C);
    column-gap: 0.5rem;

    background: var(--Input-surface, #FFF);
    border: 1px solid var(--Input-border, rgba(0, 0, 0, 0.10));

    border-radius: var(--Radius-Radius-rounded, 0.25rem);
}

.inputContainer:has(.input:disabled) {
    color: var(--Text-disabled, rgba(0, 0, 0, 0.20));

    background: var(--Surface-disabled, rgba(0, 0, 0, 0.03));

    border: 1px solid var(--Borders-dark, rgba(0, 0, 0, 0.10));
    cursor: not-allowed;
}

.inputContainer.error {
    border: 1px solid var(--Borders-destructive-solid, #FF003F);
}

.inputContainer:has(.input:focus),
.inputContainer:has(.input:active) {
    border: 1px solid var(--Borders-interactive-solid, #007FFF);
    box-shadow: 0 0 0 1px var(--Borders-interactive-solid, #007FFF);
}

.inputContainer.error:has(.input:focus),
.inputContainer.error:has(.input.active) {
    box-shadow: 0 0 0 1px var(--Borders-destructive-solid, #FF003F);
}


.input {
    composes: text-small-regular from 'ui-new/whitelabel/utils/utils.css';

    flex: 1;
    width: 100%;
    padding: 0;

    color: inherit;

    border: none;
    outline: none;
}

.input::placeholder {
    color: var(--Text-light, #A9A9A9);
}
