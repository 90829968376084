.modal {
    width: calc(100% - 0.5rem);

    color: #000000;
}

@media screen and (min-width: 700px) {
    .modal {
        width: auto;
    }
}

.body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: var(--4);
}

.copy {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.bold {
    font-weight: var(--Weight-600);
}

.banner {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0.75rem var(--4);

    background-color: var(--Button-Destructive-destructive-disabled);
    border-radius: var(--rounded);
    row-gap: 0.625rem;
}

.link {
    display: inline-flex;
    align-items: center;

    column-gap: 0.5rem;
}

.link,
.link:active,
.link:visited {
    color: inherit;
    text-decoration: none;
}

.link:hover {
    text-decoration: underline;
}

.linkIcon {
    width: 0.75rem;
    height: 0.75rem;
}

.link:hover,
.link:hover .linkIcon {
    color: var(--Text-interactive);
}
