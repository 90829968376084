.valueContainer {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    align-items: center;
}

.flag {
    min-width: 15px;
}
