.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;

    color: white;

    background: linear-gradient(134deg, #2a5484 14.64%, #4a87cd 85.36%);
}

.infoColumns {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.infoColumns > * + * {
    margin-right: 2rem;
}

.infoColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    min-width: 6rem;
    max-width: 12.5rem;

    white-space: nowrap;
}

.infoColumn.groupName {
    min-width: 16rem;
}

.infoColumn.amountSpent {
    max-width: 10rem;
}

.heading {
    font-weight: 500;
    font-size: 0.8125rem;
}

.value {
    color: #dae3ee;
    font-size: 0.6875rem;
}

.groupButton {
    display: flex;
    gap: 0.2rem;
    align-items: center;
    justify-content: center;
    padding: 0 1.25rem;

    color: #ffffff;
    font-weight: 600;
    font-size: 0.75rem;
    white-space: nowrap;

    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandButton {
    align-self: stretch;
    width: 3.675rem;

    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.expandButton > svg {
    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(180deg);
}

.input {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    padding-right: 0.3125rem;

    color: inherit;
    font-weight: 500;
    text-overflow: ellipsis;

    background: transparent;
    border: 1px solid transparent;
    outline: none;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
    display: none;
    margin: 0;

    -webkit-appearance: none;
}

.input[type='number'] {
    -moz-appearance: textfield;
}

.input[disabled],
.input[disabled]:hover,
.input[disabled]:focus {
    background-color: transparent;
    border: 1px solid transparent;
}

.input:hover,
.input:focus {
    background-color: rgba(255, 255, 255, 0.2);
}

.input:focus {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.amountSpentContainer {
    display: flex;
    width: 100%;
}

.amountSpentContainer .input {
    flex: 1;
    min-width: 0;

    color: #ffffff;

    font-size: 0.75rem;
}

.infoColumn.liveToggleContainer {
    flex-grow: 0;
    flex-shrink: 1;
    /* width of "in proposal" state to align live/in proposal vertically */
    min-width: 3.875rem;
}

.liveToggle {
    min-height: 1rem;
    padding: 0.1rem;
}

.liveToggle.live {
    background-color: var(--color-success);
}

.liveToggle.live > div {
    right: 0.1rem;
}

.liveToggleContainer .value {
    margin-top: 0.175rem;
}

.warningIcon {
    width: 1.125rem;
    height: 1.125rem;

    margin-left: 0.75rem;

    color: var(--color-danger);
}

.warning {
    background-color: var(--color-danger);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.actionButtons {
    display: flex;
}

.reorderingButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reorderingButton {
    color: white;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.reorderingButton.increaseOrder {
    transform: rotate(180deg);
}

.infoColumn.invoiceRequestStatus {
    min-width: fit-content;
}
