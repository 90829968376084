.reference {
    max-width: 20rem;
}

.editContainer {
    width: 100%;
    max-width: 20rem;
}

.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.input {
    width: 100%;
    min-width: 0;
    max-width: 20rem;

    border: none;
    outline: none;

    resize: none;
}

.input:hover, .input:focus {
    background-color: inherit;
}

.invisible {
    visibility: hidden;
}
