.react-datepicker {
    border: 1px solid rgba(25, 42, 62, 0.2) !important;
}

.react-datepicker-popper {
    z-index: 100 !important;
}

.react-datepicker__day-names {
    border: none !important;
}

.react-datepicker__header {
    background: #fff !important;
    border-bottom: 1px solid rgba(25, 42, 62, 0.2) !important;
}

.react-datepicker__current-month {
    color: #192a3e !important;
    font-weight: 500 !important;
}

.react-datepicker__week,
.react-datepicker__header {
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.react-datepicker__triangle {
    left: 50% !important;
}

.react-datepicker__navigation {
    width: auto !important;
    height: auto !important;
    overflow: auto !important;

    text-indent: unset !important;

    border: none !important;
}

.react-datepicker__navigation--next {
    top: 5px !important;

    transition: all 0.1s ease-in-out;
}

.react-datepicker__navigation--next:hover {
    transform: translateX(2px);
}

.react-datepicker__navigation--next:active {
    transform: translateX(4px);
}

.react-datepicker__navigation--previous {
    top: 5px !important;

    transition: all 0.1s ease-in-out;
}

.react-datepicker__navigation--previous svg {
    transform: rotate(180deg);
}

.react-datepicker__navigation--previous:hover {
    transform: translateX(-2px);
}

.react-datepicker__navigation--previous:active {
    transform: translateX(-4px);
}

.react-datepicker__navigation-icon {
    width: auto !important;
}

.react-datepicker__navigation-icon:before {
    display: none !important;
}

.react-datepicker__navigation-icon svg {
    width: 1rem !important;
}

.react-datepicker__day {
    font-size: 14px;
}

.react-datepicker__day:hover {
    background: #e0f0fd !important;
}

.react-datepicker__day--today {
    color: #109cf1 !important;
    font-weight: 500 !important;
}

.react-datepicker__day--selected {
    color: #fff !important;
    font-weight: 400 !important;

    background: #109cf1 !important;
}

.react-datepicker__day--selected:hover {
    background: #109cf1 !important;
}

.react-datepicker__input-container input {
    padding-left: 10px !important;
}

.react-datepicker__tab-loop {
    margin: 0 !important;
}
