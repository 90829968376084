.container {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    padding: 0.25rem;

    background: transparent;
    border: 1px solid transparent;

    border-radius: 0.875rem;
}

.container.open {
    background: #F3F4F6;
    border: 1px solid #D1D5DB;
}

.masterTab {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;
    height: 2.125rem;
    padding: 0.25rem 0.625rem;

    color: #111111;

    background: transparent;
    border: 1px solid transparent;
    border-radius: 0.75rem;
    cursor: pointer;
}

.masterTab.active {
    color: white;

    background: #111111;
}

.masterTab:not(.active):hover {
    background: #E5E7EB;
    border: 1px solid #E5E7EB;
}

.tabsWrapper {
    overflow: hidden;

    transition: width 0.15s ease-in-out;
}

.tabs {
    display: inline-flex;
    gap: 0.625rem;
    align-items: center;
}
