.modal {
    width: 70%;
}

@media only screen and (max-width: 768px) {
    .modal {
        width: 100%;
    }
}

.modalContent {
    position: relative;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;

    background-color: transparent;
    border: none;
    cursor: pointer;

    fill: #90a0b7;
}

.container {
    box-sizing: border-box;
    min-height: 100vh;
    padding: 2.8125rem 1.875rem 1.875rem;

    background-color: white;
}

.container > * + * {
    margin-top: 1.25rem;
}


.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.9375rem 1.875rem;

    background-color: #e4e7ec;
    border-radius: 0.625rem;
}

.header > * + * {
    margin-left: 1rem;
}

.audioCover {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
}

.titleContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
}

.title:hover {
    text-decoration: underline;
}

.author {
    color: #58698e;
    font-size: 0.75rem;
    text-decoration: none;
}

.author:hover {
    text-decoration: underline;
}

.headlineStats {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    column-gap: 2rem;
}

.headlineStats > * {
    flex: 1;
}

.topPostsHeader {
    margin-bottom: 1.25rem;
}

.topPostsContainer .heading {
    margin: 0;

    color: #192a3e;
    font-weight: 500;
}

.topPostsContainer p {
    margin: 0;

    color: #707683;
    font-size: 0.6rem;
}
