.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.75rem;
}

.image {
    width: 2.875rem;
    height: 2.875rem;

    border-radius: 50%;
}

.titles {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
}

.title {
    composes: font-sm from '../../../../../../ui/utils.css';

    color: var(--color-grayscale-100);
}

.subTitle {
    composes: font-xs from '../../../../../../ui/utils.css';

    color: var(--color-grayscale-40);

    text-decoration: none;

    cursor: pointer;
}

.subTitle:hover {
    text-decoration: underline;
}
