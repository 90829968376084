.logoFull {
    width: 4.5rem;
    height: 1.25rem;
}

.logoFull > path {
    fill: white;
}

.logo {
    position: absolute;
    top: 2rem;
    right: 1rem;

    display: none;

    height: 2rem;
}

@media screen and (min-width: 768px) {
    .logo {
        display: block;
    }

    .logoFull {
        width: 12rem;
        height: 3.25rem;
    }
}
