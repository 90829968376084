.title,
.subtitle {
    margin: 0;
}

.title {
    margin: 0;

    color: #323c47;
    font-weight: 500;
}

.subtitle {
    color: #707683;
    font-size: 0.65rem;
}
