.text {
    display: inline-block;
    width: 100%;
    overflow: hidden;

    white-space: nowrap;
    text-decoration: inherit;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.content {
    composes: font-xs from 'ui/utils.css';

    max-width: 10rem;
    padding: 0.75rem;

    color: white;

    word-break: break-word;

    background-color: var(--color-grayscale-100);
    border-radius: 0.75rem;
}

.arrow {
    position: absolute;
    left: 50%;

    width: 0;
    height: 0;

    border-top: 0.5rem solid var(--color-grayscale-100);
    border-right: 0.5rem solid transparent;
    border-left: 0.5rem solid transparent;
    transform: translateX(-50%);
}
