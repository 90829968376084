.container {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;

    column-gap: 0.75rem;
}

.content {
    flex: 1;
}

.tick {
    color: var(--color-status-success);

    visibility: hidden;
}

.tick.visible {
    visibility: visible;
}
