.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3.75rem;
    margin-top: 0.625rem;
    overflow-x: auto;

    color: #323c47;

    background: #e4e7ec;
    border-radius: 0.2rem;
}

.statsWrapper {
    display: flex;
    gap: 8rem;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
}

.invoiceDetailsContainer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    width: 50%;
    padding: 0.625rem 1.25rem;
    overflow: hidden;

    text-overflow: ellipsis;
}

.invoiceStatsContainer {
    display: flex;
    flex-direction: row;
    gap: 0.8rem;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    padding: 0.625rem 0;
}

.groupInfoColumn {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    align-items: flex-start;
    max-height: 2rem;
    overflow: hidden;

}

.groupInfoColumnValue {
    display: flex;
    gap: 0.25rem;
    min-width: 5rem;
    overflow: hidden;

    font-weight: 500;
    font-size: 0.8125rem;
}

.groupInfoColumnSubValue {
    color: var(--color-grey-4);
    font-weight: 500;
    font-size: 0.75rem;
}

.dropdownIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 2.5rem;
    margin: 0 1rem;
}

.invoiceNumberColumn {
    flex: 1 3;
    min-width: 5rem;
}

.brandsColumn {
    flex: 1;
    min-width: 7rem;
}

.referenceColumn {
    flex: 2;
    min-width: 24rem;
}

.ellipsedValue {
    display: -webkit-box;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.referenceValue {
    display: -webkit-box;
    overflow: hidden;

    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.invoiceStat {
    min-width: 7rem;
}

.revenueWarning {
    color: var(--color-danger)
}

.spentPercentage {
    color: var(--color-primary);
}

.invoicePaid {
    color: var(--color-success);
}

.expandButton {
    background-color: #e4e7ec;
    border: none;
    cursor: pointer;
}

.expandButton svg {
    transform: rotate(90deg);
}

.expanded svg {
    transform: rotate(180deg);
}

.barSkeletonWrapper {
    width: 100%;
    height: 3.75rem;
    margin-top: 0.625rem;
}

.barSkeleton {
    width: 100%;
    height: 100%;

    background-color: #e4e7ec;
}

.tableRowSkeleton {
    width: 100%;
    height: 4rem;
    margin-top: 0.5rem;
}
