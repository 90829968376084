.tableContainer {
    margin: 2rem 3rem;
    padding: 1.875rem;
}

.mobileContainer {
    padding: 0.875rem;

    background: white;
}
