.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    white-space: nowrap;
}

.container > * + * {
    margin-left: 0.375rem;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.icon {
    width: 1rem;
    height: 1rem;
}

.expectedByLabel {
    font-size: 0.575rem;
    font-weight: 400;
    color: var(--color-grey-4)
}

@media screen and (min-width: 600px) {
    .expectedByLabel {
        font-size: 0.75rem;
    }
}
