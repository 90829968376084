.container > * + * {
    margin-top: 1.25rem;
}

.title {
    margin: 0;

    font-weight: 600;
    font-size: 1.5rem;
}

.heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
