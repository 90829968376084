.success {
    color: var(--color-status-success);
}

.neutral {
    color: var(--color-status-warning);
}

.failure {
    color: var(--color-status-fail);
}
