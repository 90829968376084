.chartTabs > * {
    flex: 1;
}

.tooltipContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tooltipContent p {
    margin: 0;

    text-align: justify;
    text-wrap: none;
}

.tooltipDot {
    width: 0.5rem;
    min-width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;

    background-color: #7a61df;
    border-radius: 50%;
}

.tooltipValue {
    margin-left: 0.5rem;
}
