.deleteAsset,
.deleteAsset * {
    z-index: 1000;
}

.deleteAsset {
    text-align: center;
}

.submit {
    flex: 1;
}
