.table {
    width: 100%;
    min-width: 80rem;

    text-align: left;

    border-collapse: collapse;
}

.table thead {
    background-color: #f5f6f8;
}

.table thead th {
    padding: 0.8125rem 0.625rem;

    color: #58698e;
    font-weight: 700;
    font-size: 0.625rem;

    white-space: nowrap;
}

.table td {
    padding: 0.375rem 1.125rem 0.375rem 0.375rem;

    color: #707683;

    font-size: 0.75rem;
    letter-spacing: 0.1px;

    border: 1px solid #e7eef6;
}


.table tbody td input,
.table tbody td textarea,
.costTotal {
    width: 100%;

    color: inherit;
    font-size: inherit;
    text-overflow: ellipsis;

    background-color: transparent;
    border: none;
}

.table tbody td input:hover,
.table tbody td input:focus,
.table tbody td textarea:hover,
.table tbody td textarea:focus {
    background-color: rgb(231, 238, 246, 0.9);
}

.table tbody td input.invalidInputValue {
    outline: 1px auto rgba(247, 104, 91, 0.5);
}

.table tfoot {
    color: #58698e;
    font-weight: 600;

    background-color: #f5f6f8;
}


.editorCell {
    min-width: 5rem;
}

.editorCell,
.editorColumn {
    background-color: #fdffef;
}

.editableCell:hover {
    box-shadow: inset 0 0 1px #0090ff;
}

.negativeCell {
    background-color: rgba(247, 104, 91, 0.4)
}

.positiveCell {
    background-color: rgba(31, 182, 99, 0.4);
}

.notesInput {
    min-width: 8.5rem;
    max-width: 10.5rem;
    min-height: 1.15em;
    max-height: 2.9em;
    padding: 0.3rem;

    overflow: scroll;

    color: inherit;
    overflow-wrap: normal;

    resize: none;
    scrollbar-width: none;
}

.notesInput::-webkit-scrollbar {
    display: none;
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.statusButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.statusButton.readonly {
    cursor: inherit;
}

.status {
    color: #58698e;
    font-weight: 500;
    font-size: 0.75rem;
}

.linkIcon {
    width: 0.9rem;
    height: 0.9rem;
    margin-left: 1.5rem;

    color: var(--color-grey-4);

    cursor: pointer;
}

.statusContainer:hover > .linkIcon {
    color: var(--color-primary-2);
}

/* footer */
.costTotal {
    font-weight: inherit;

    outline: none;
}

.paymentIcon {
    width: 1.125rem;
    height: 1.125rem;
}

.paymentRequestStatus {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
}

.paymentRequestStatus .paid {
    color: var(--color-success);
}

.paymentRequestStatus .inProgress {
    color: var(--color-warning);
}

.paymentRequestStatus .failed {
    color: var(--color-danger);
}

.paymentRequestStatus .requested {
    color: var(--color-info)
}

.paymentRequestStatus .unrequested {
    color: var(--color-grey-4);
}

.paymentRequestStatus .button {
    min-width: 1rem;
    padding: 0.125rem;
}
