.form {
    display: flex;
    flex-direction: column;
    gap: var(--6, 1.5rem);
    align-items: flex-start;
    align-self: stretch;
}

.form > * {
    width: 100%;
    max-width: 26rem;
}
