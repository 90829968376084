.loadingSpinner {
    align-self: center;
}

.subject {
    font-weight: 600;
    font-size: 0.9rem;
}

.body {
    width: 100%;
    max-height: 70vh;
    padding: 0.5rem 1rem;
    overflow-y: auto;

    font-size: 0.9rem;

    background-color: #fafafa;

    border-radius: 0.25rem;
}

.body::-webkit-scrollbar {
    width: 0.25rem;

    border-radius: 0.625rem;
}

.body::-webkit-scrollbar-track {
    background-color: rgba(221, 221, 221, 0.10);
    border: none;
}

.body::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 0.625rem;
}
