.insight {
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 18px;
    letter-spacing: 0.1px;
}

@media only screen and (max-width: 1400px) {
    .insight {
        padding: 10px;
    }
}
