.badge {
    --height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--height);

    color: var(--Text-Weak, #767676);
    font-weight: 500;
    font-size: 0.6875rem;

    background-color: var(--Surface-White, #FFF);

    border: 1px solid #D1D1D180;
    border-radius: calc(var(--height) / 2);
    aspect-ratio: 1;
}
