.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
}

.previewModal {
    top: 10%;

    display: flex;

    min-width: 46.875rem;
    max-height: 80%;

    transform: translate(-50%, 0);
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 0.5rem;
    align-items: stretch;
}

.filter {
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
    align-items: stretch;
    min-width: 10rem;
    row-gap: 0.375rem;
}

.filterLabel {
    color: #90a0b7;
    font-size: 0.75rem;
}

.filterInput {
    flex: 1;
    padding: 0.5rem 1rem;

    color: #333333;
    font-size: 13px;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}

.actionsRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.actionButtons {
    visibility: hidden;
}

.actionButtons.visible {
    visibility: visible;
}

.rowsDisplayedText {
    margin: 0;

    color: #90a0b7;
    font-size: 0.75rem;
    text-align: right;
}

.multipleDeadlinesPopover {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.5rem;

    background-color: white;
}

.multipleDeadlinesSaveButton {
    padding: 1rem 0;
}

.emailHistoryModal {
    height: 30rem;

    border-radius: 0.75rem;
}
