.modal {
    width: 30rem;
}

.detailsContainer {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-self: stretch;
    margin: 0;

    color: var(--color-grayscale-100);
}

.detailsContainer > div {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    align-self: stretch;
    height: 2.75rem;
    padding: 0.625rem 0.875rem;

    background: var(--color-neutral-100);
    border-radius: 0.75rem
}

.detailsContainer dd {
    width: 3.8215rem;
    margin: 0;

    color: var(--color-grayscale-40)
}

.detailsContainer dt {
    flex: 1;
}

.bold {
    composes: font-semi-sm from 'ui/utils.css';
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.editIcon {
    width: 1.25rem;
    height: 1.25rem;

    color: var(--color-neutral-400);

    cursor: pointer;
}

.editIcon:hover {
    color: var(--color-neutral-500);
}

.ghostInput {
    composes: font-semi-sm from 'ui/utils.css';

    flex: 1;

    width: 100%;
    margin-left: -0.125rem;
    padding: 0.175rem 0.125rem;

    text-overflow: ellipsis;

    background-color: white;
    border: none;
    border-radius: 0.25rem;
}

.costEditInput {
    width: 100%;

    background-color: var(--color-neutral-100);
    border: none;
    box-shadow: none;
}

.costEditInput * {
    background-color: var(--color-neutral-100) !important;
}
