.buttonsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.875rem;
}

.button {
    padding: 0;
}

.createFromTemplateButton {
    width: 100%;
}
