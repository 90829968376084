.skeleton {
    height: 3.725rem;
    margin-bottom: 0.325rem;
}

.noGroupsMessage {
    margin-top: 1.5rem;

    color: var(--color-grey-4);

    font-weight: 500;
    font-size: 0.875rem;

    text-align: center
}
