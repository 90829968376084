.headerContainer {
    display: flex;
    gap: 2rem;
    align-items: baseline;
    justify-content: space-between;
    width: 100%
}

.filters {
    display: flex;
    flex: 1;
    gap: 1rem;
    align-items: flex-end;
}

.filter {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-left: 0.25rem;
}

.filter label {
    color: var(--color-grey);
    font-size: 0.75rem
}

.searchBar {
    width: 30%;

    font-size: 0.825rem;
}
