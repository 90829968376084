.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6.25rem;

    height: 6.25rem;

    background-color: #F4F4FF;
    border-radius: 0.625rem;
    cursor: pointer;
}

.hidden {
    display: none;
}

.imagePreview {
    width: 100%;
    height: 100%;
    object-fit: cover;

    border-radius: 0.625rem;
}

.icon {
    width: 1.5rem;
    height: 1.5rem;
}
