.modal {
    min-width: 40rem;
}

.content {
    margin: 0;
}

.submitButton {
    flex: 1;
}

.deleteButton {
    width: 20%;
}

.renderActions {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.25rem;
}
