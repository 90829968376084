.badge {
    padding: 0.25rem 0.4rem;

    color: whitesmoke;

    background-color: var(--color-primary-2);
    border-radius: 0.25rem;
    cursor: pointer;
}

.popover {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0.5rem 1rem;

    font-size: 0.875rem;

    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.value {
    color: #323C47;
    font-weight: 600
}

.subValue {
    color: #323C47;
    font-weight: 400;
    font-size: 0.825rem
}
