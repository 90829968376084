.container {
    padding: 1em;
}

.container p {
    margin: 0;

    font-size: 0.9rem;
}

.label {
    font-weight: 700;
}
