.overlay {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';

    z-index: 10;

    background: rgba(17, 17, 17, 0.20);
}

.modal {
    position: absolute;
    top: 50%;
    left: 50%;

    display: flex;
    flex-direction: column;

    max-height: 90vh;

    background-color: white;
    border-radius: var(--rounded-md);

    transform: translate(-50%, -50%);
}

@media screen and (min-width: 700px) {
    .modal {
        top: 4.5rem;

        max-height: calc(100vh - 6rem);

        transform: translate(-50%, 0);
    }
}

@media screen and (min-height: 1024px) {
    .modal {
        top: 9rem;

        max-height: calc(100vh - 13.5rem);
    }
}

.closeIcon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    width: 1rem;
    height: 1rem;

    cursor: pointer;
}

.header {
    padding: var(--6);

    border-bottom: 1px solid var(--Borders-Grey---Extra-light, #E7E7E7);
}

.title {
    composes: heading-small from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.body {
    flex: 1;
    padding: var(--6);
    overflow: auto;
}

.actions {
    display: flex;
    justify-content: flex-start;
    margin: 0;

    padding: var(--6);

    column-gap: var(--4);
}
