.container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.3125rem;
}

.star {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    padding: 0;

    color: var(--color-accent-blue);

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.star.disabled {
    cursor: not-allowed;
    opacity: 0.8;
}

.star svg {
    width: 100%;
    height: 100%;

    fill: white;
}

.star.selected svg {
    fill: currentColor;
}

.star:hover svg {
    filter: drop-shadow(0 0 1px rgba(19, 65, 207, 0.2)) drop-shadow(0 0 1px rgba(19, 65, 207, 0.2));
}
