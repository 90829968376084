.button {
    min-width: 7.25rem;
    padding: 0.475rem 0.825rem;

    color: white;

    background-color: #192a3e;
    border: 1px solid #e7eef6;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;

    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
}

@media screen and (min-width: 1200px) {
    .button {
        min-width: 10rem;
        padding: 0.625rem 1.25rem;
    }
}

.button.disabled {
    cursor: auto;
}

.button.disabled:hover {
    color: white;

    background-color: #192a3e;
}

.button:hover .iconContainer {
    background-color: rgba(144, 160, 183, 0.1);
}

.button.disabled:hover .iconContainer {
    background-color: rgba(255, 255, 255, 0.2);
}

.button:hover {
    color: #192a3e;
}

.buttonContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.buttonContent > * + * {
    margin-left: 0.625rem;
}

.content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}

.value {
    font-weight: 500;
    font-size: 0.875rem;
}

.label {
    font-weight: 400;
    font-size: 0.7rem;
}

@media screen and (min-width: 1200px) {
    .value {
        font-weight: 500;
        font-size: 1rem;
    }

    .label {
        font-weight: 400;
        font-size: 0.75rem;
    }
}

.popoverContainer {
    padding: 1.25rem;
    overflow-y: auto;

    background-color: white;
    box-shadow: 0 0.875rem 1.875rem 0 #19283b14;
}
