.container {
    display: inline-grid;
    align-items: center;
}

.container::after {
    height: 0;
    padding: 0 0.5rem;

    visibility: hidden;

    content: attr(data-value) ' ';
}

.container::after,
.input {
    width: auto;
    min-width: 1rem;

    font: inherit;
}

.skeleton {
    width: 20rem;
    margin-left: 0.125rem;
}

.input {
    padding: 0;

    color: var(--text-white);
    text-overflow: ellipsis;

    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.1875rem;
    cursor: pointer;
}

.input:hover,
.input:focus {
    background: var(--White-100, rgba(255, 255, 255, 0.20));
    border: 1px solid rgba(255, 255, 255, 0.25);
}

.input:focus,
.input:active {
    outline: none;
    cursor: text;
}
