.editContainer {
    width: 100%;
}

.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
}

.input {
    width: 100%;
    min-width: 0;

    border: none;
    outline: none;
}

.input:hover, .input:focus {
    background-color: inherit;
}

.invisible {
    visibility: hidden;
}
