.container {
    display: flex;
    flex-direction: column;
    gap: var(--4) var(--6);

    padding: var(--4) 1.5rem;

    color: var(--text-white);
}

@media screen and (min-width: 700px) {
    .container {
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
}

.title {
    composes: heading-large from 'ui-new/whitelabel/utils/utils.css';

    min-width: min(100%, 35rem);
    max-width: 60rem;

    margin: 0 0 var(--1) 0;
    overflow-x: hidden;

    white-space: nowrap;

    text-overflow: ellipsis;
}

.description {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    min-width: min(100%, 40rem);
    max-width: 40rem;

    margin: 0;
}

.runDatesContainer {
    display: flex;
    gap: var(--1);
}

.duration {
    color: rgba(255, 255, 255, 0.5);
}

.goBackContainer {
    composes: p-bold from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 1rem;
    padding: 1rem 1.5rem;

    color: var(--text-white, #fff);

    background-color: rgba(0, 0, 0, 0.05);
}

.teamLogo {
    width: 100%;
    height: 100%;

    border-radius: 50%;
}

.team {
    display: flex;
    gap: 0.6875rem;
    align-items: center;
}

.roundLogo {
    width: 1.75rem;
}
