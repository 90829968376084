.badge {
    composes: font-xs from 'ui/utils.css';

    padding: 0.25rem 0.625rem;

    border-radius: 1.25rem;
}

.pending {
    color: rgba(212, 153, 0, 1);

    background-color: rgba(255, 184, 0, 0.2);
}

.needs_to_chase {
    color: var(--color-status-fail);

    background-color: rgba(254, 0, 43, 0.2);
}

.needs_to_be_invoiced {
    color: rgba(147, 106, 0, 1);

    background-color: rgba(255, 184, 0, 0.4);
}

.submitted_to_uniport,
.other {
    color: var(--color-grayscale-100);

    background-color: var(--color-neutral-200);
}

.invoiced {
    color: rgba(0, 148, 32, 1);

    background-color: rgba(34, 197, 94, 0.2);
}
