.container {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    min-width: 22.5rem;
    max-width: 22.5rem;
    padding: 0;

    background: transparent;
    box-shadow: none;
}

.selectionContainer {
    composes: shadow-md-topless from '../../../ui/utils.css';
    composes: font-sm from '../../../ui/utils.css';

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.875rem 1.25rem;

    background: white;
    border-radius: 0.75rem;
}

.selectionContainer svg, .filterIcon {
    min-width: 1.25rem;
    min-height: 1.25rem;

    color: #9CA3AF;
}

.selectionsRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
}

.selection {
    display: flex;
    flex: 1;
    gap: 0.375rem;
    align-items: center;
    width: 8.8125rem;
    height: 2.5rem;
    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300, #D1D5DB);
    border-radius: 0.75rem;
    cursor: default;
}

.selection > span {
    flex: 1;

    color: var(--color-grayscale-40, #8D8D8D);
    white-space: nowrap;
}

.renderValue {
    composes: font-sm from '../../../ui/utils.css';

    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.renderValue span + span {
    margin-left: 0.25rem
}

.selection.active > span {
    color: var(--color-grayscale-100, #111);
}

.dateValue {
    composes: font-md-sm from '../../../ui/utils.css';
}

.calendar {
    composes: shadow-md from '../../../ui/utils.css';
}
