.label {
    composes: text-small-medium from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-primary);
}

.subLabel {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weakest);
}
