.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
}

.loadingSpinner {
    position: relative;

    color: inherit;

    opacity: 1;

    transition: opacity linear 0.1s;
}

.loadingSpinner:before {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 1.5rem;
    height: 1.5rem;

    border: solid 3px #eee;
    border-bottom-color: inherit;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    opacity: inherit;

    animation: 2s linear infinite spinner;
    animation-name: rotateClockwise;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    content: "";
    will-change: transform;
}

@keyframes rotateClockwise {
    0% { transform: translate3d(-50%, -50%, 0) rotate(0deg); }

    100% { transform: translate3d(-50%, -50%, 0) rotate(360deg); }
}
