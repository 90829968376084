.navMenuIcon {
    color: white;
    scale: 1.2;

    cursor: pointer;
}

.navMenuIcon.menuIsOpen,
.navMenuIcon:hover {
    -webkit-filter: drop-shadow(0.125rem 0.125rem 0 #393e44);
    filter: drop-shadow(0.125rem 0.125rem 0 #393e44);
    scale: 1.2;
}

.menuWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3.5rem;
    padding: 0 1.5rem;

    background-color: #000000;
}

.logoContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoContainer hr {
    width: 0.0625rem;
    height: 1.875rem;
    margin: 0 0.1rem;

    background-color: var(--Icon-white, #fff);
    border: none;
}

.roundLogo {
    box-sizing: content-box;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0 0.25rem;

    color: white;
}

.sonyLogo {
    box-sizing: content-box;
    width: 2rem;
    height: 2rem;
    padding: 0 0.25rem;

    color: white;
}

.menuDropdownList {
    position: absolute;
    top: 3.5rem;
    right: 0;
    left: 0;
    z-index: 10;

    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;

    background: #000000;
    border-top: 0.125rem solid #393e44;
    border-radius: 0 0 0.625rem 0.625rem;
}

.navItem {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    color: #ffffff;

    text-decoration: none;

    border: 4px solid transparent;
    cursor: pointer;
}

.arrowIcon {
    width: 1rem;
    height: 1rem;

    color: #4b4b4b;
}

.menuDropdownList > .navItem {
    margin-top: 0.25rem;
}

.isNavGroupOpen {
    display: flex;
}

.activeRouteNavItem {
    background-color: #ffffff1a;
}

.dropdown {
    position: relative;
    left: 5px;
    z-index: 10;

    width: 100%;

    padding: 0.35rem 0.625rem;

    border-left: 0.125rem solid #393e44;
    border-radius: 0 0.625rem 0.625rem 0;
}

.dropdown > .navItem {
    justify-content: flex-start;

    color: #fff;
    text-align: left;

    cursor: pointer;
}

.label {
    padding-bottom: 0.125rem;

    color: #ffffff;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;

    border-bottom: 0.125rem solid transparent;
}

.logout {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--Icon-white);
}
