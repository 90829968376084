.release {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1.25rem;

    background-color: #dcdfe5;
    cursor: pointer;
}

.releaseDropdownIcon {
    width: 0.85rem;
    height: 0.85rem;
    margin-left: 0.875rem;

    color: #58698e;

    transform: rotate(180deg);
}

.releaseDropdownIcon.releaseDropdownOpen {
    transform: rotate(0);
}

.brandImage {
    width: 2.8125rem;
    height: 2.8125rem;
    object-fit: cover;
    object-position: center;

    border-radius: 50%;
}

.releaseDescription {
    flex: 1;
    margin-left: 0.9375rem;
}

.brandName,
.releaseName {
    margin: 0;
}

.releaseName {
    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
}

.brandName {
    color: #58698e;
    font-size: 0.75rem;
}

.releaseDropdown {
    box-sizing: border-box;
    width: 100%;
    padding: 1.25rem;

    background-color: white;
    border-radius: 0 0 0.1875rem 0.1875rem;
    box-shadow: 0 0.875rem 1.875rem 0 #19283b14;
}

.searchInput {
    background-color: #f5f6f8;
    border: 1px solid #c2cfe0;
}

.releasesList {
    min-height: 4.375rem;
    max-height: 13.75rem;
}

.releasesListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 0.9375rem;

    cursor: pointer;
}

.releasesListItem:hover {
    background-color: #fafafa;
}

.releaseItemImage {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
}

.releaseItemContent {
    flex: 1;
    margin-left: 0.5rem;
}

.releasesListItem .label {
    margin: 0;

    color: #323c47;
    font-weight: 500;
    font-size: 0.875rem;
}

.releasesListItem .subtitle {
    margin: 0;

    color: #707683;
    font-size: 0.6875rem;
}
