.container > * + * {
    margin-top: 1.25rem;
}

.heading,
.subheading {
    margin: 0;
}

.subheading {
    color: #707683;
    font-size: 0.8125rem;
}

.table th {
    font-size: 0.625rem;
}

.table td {
    min-width: 0;
}

a:focus,
a:active {
    color: inherit;
    text-decoration: none;
}

.table td > a {
    color: inherit;
    text-decoration: none;
}

.table td > a:hover {
    text-decoration: underline;
}
