.table td:first-of-type {
    padding-left: 1rem;
}

.table td:not(.table td:first-of-type) {
    min-width: 8.75rem;
    max-width: 15rem;
}

.notesInput {
    composes: scroll from 'ui/utils.css';

    /* subtract padding */
    width: calc(15rem - 1.5rem);

    resize: none;
}

.input,
.notesInput {
    composes: font-sm from 'ui/utils.css';

    border: none;
}

.input:hover,
.input:focus,
.notesInput:hover,
.notesInput:focus {
    background-color: var(--color-neutral-100);
    outline: none;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.releaseContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.brandPicture {
    width: 2.875rem;
    height: 2.875rem;
    margin-right: 0.75rem;

    border-radius: 50%;
}

.releaseDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;

    overflow: hidden;
}

.releaseName {
    composes: font-md-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
    white-space: nowrap;

    text-decoration: none;
}

.releaseName:hover {
    text-decoration: underline;
}

.brandName {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-grayscale-40);
    white-space: nowrap;
}

.platformIcon {
    color: var(--color-neutral-400);
}

.amountContainer {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    height: 100%;
}

.amountSpentLabels {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}

.amountSpentTrack {
    height: 0.3125rem;

    background-color: var(--color-neutral-200);
}

.amountSpentBar {
    height: 0.3125rem;

    background-color: var(--color-neutral-400);
}

.hint {
    composes: font-xs from 'ui/utils.css';

    padding: 0.3125rem;

    color: white;

    background-color: var(--color-text);
    border-radius: 0.3125rem;
}
