.container {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.image {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
}

.details {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
}

.nickname {
    color: #323C47;
    font-weight: 500;
    font-size: 0.875rem;
}

.username {
    color: #4c5862;
    text-decoration: none;
}

.username:hover {
    text-decoration: underline;
}
