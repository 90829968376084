.track {
    position: relative;

    width: 1.6rem;
    min-height: 1.2rem;
    padding: 0.1875rem;

    background-color: #c2cfe0;
    border-radius: 6.25rem;
    cursor: pointer;
}

.track.on {
    background-color: #0090ff;
}

.track.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.thumb {
    position: absolute;

    width: 0.8rem;
    height: 0.8rem;

    background-color: white;
    border-radius: 50%;
}

.thumb.on {
    right: 0.1875rem;
}
