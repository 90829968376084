.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 14rem;

    background-color: #FFF;

    box-shadow: 0 0.875rem 1.875rem 0 #19283b14;
}
