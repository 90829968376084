.dailyChangeContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-end;
}

.audioIcon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}

.chartContainer {
    position: relative;

    width: 5.625rem;
    height: calc(3.0625rem - 1rem);
}

.chartTooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.chartTooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.pointer {
    cursor: pointer;
}
