.table tbody {
    background-color: white;
}

.clientBadgeContainer {
    display: flex;
    flex-wrap: wrap;
}

.clientBadge {
    margin: 0 0.625rem 0.625rem 0;
    padding: 0.25rem 0.5rem;

    color: white;
    font-weight: 500;
    font-size: 0.75rem;
    white-space: nowrap;

    background-color: #192a3e;
    border-radius: 0.25rem
}

.table td:nth-child(3) {
    width: 50%;
}

.deactivateButton {
    padding: 0.325rem 0.5rem;
}
