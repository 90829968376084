.container {
    display: flex;
    align-items: center;
    padding: 0.625rem 0.875rem;

    border: 1px solid transparent;
    border-radius: 0.75rem;
    cursor: pointer;
}

.icon {
    color: var(--color-neutral-400);
}

.container:hover .icon {
    color: var(--color-grayscale-100);
}
