.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
}

.input {
    composes: text-small-regular from 'ui-new/whitelabel/utils/utils.css';

    padding: var(--15) var(--Spacing-Spacing-35, 0.875rem);

    color: var(--Text-primary, #1C1C1C);

    background: var(--Input-surface, #FFF);
    border: 1px solid var(--Input-border, rgba(0, 0, 0, 0.10));

    border-radius: var(--Radius-Radius-rounded, 0.25rem);
    outline: none;
}

.input::placeholder {
    color: var(--Text-light, #A9A9A9);
}

.input:disabled {
    color: var(--Text-disabled, rgba(0, 0, 0, 0.20));

    background: var(--Surface-disabled, rgba(0, 0, 0, 0.03));

    border: 1px solid var(--Borders-dark, rgba(0, 0, 0, 0.10));
    cursor: not-allowed;
}

.input:focus,
.input:active {
    border: 1px solid var(--Borders-interactive-solid, #007FFF);
    box-shadow: 0 0 0 1px var(--Borders-interactive-solid, #007FFF);
}

.input.error {
    border: 1px solid var(--Borders-destructive-solid, #FF003F);
}

.input.error:focus,
.input.error.active {
    box-shadow: 0 0 0 1px var(--Borders-destructive-solid, #FF003F);
}

.moneyInput {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    column-gap: 0.75rem;

    background-color: #FFF;
}

.moneyInput:focus-within {
    border: 1px solid var(--Borders-interactive-solid, #007FFF);
    box-shadow: 0 0 0 1px var(--Borders-interactive-solid, #007FFF);
}

.moneyInput.error:focus-within {
    box-shadow: 0 0 0 1px var(--Borders-destructive-solid, #FF003F);
}

.moneyInput input {
    color: var(--Text-primary);
    font-weight: 600;

    border: none;
    outline: none;
}

.moneyInput .inputField {
    min-width: 0;
}

.moneyInput.disabled {
    color: var(--Text-disabled, rgba(0, 0, 0, 0.20));

    background: var(--Surface-disabled, rgba(0, 0, 0, 0.03));
    cursor: not-allowed;
}
