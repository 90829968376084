.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.2rem;
}

.tag {
    padding: 0.125rem 0.3125rem;

    font-weight: 500;
    font-size: 0.625rem;

    background-color: #7A61DF;
}

.tag > * + * {
    margin-left: 0.3125rem;
}

.deleteButton {
    padding: 0;

    background: transparent;
    border: none;
    cursor: pointer;
}

.deleteIcon {
    width: 0.5rem;
    height: 0.5rem;

    color: white;
    stroke-width: 2;
}
