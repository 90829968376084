.container {
    display: flex;
    flex: 1;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    padding: 0.5rem;

    color: #90A0B7;

    border: 1px solid #C2CFE0;
    border-radius: 0.3125rem;
}

.container:focus-within {
    outline: 1px solid -webkit-focus-ring-color;
}

.container input {
    flex: 1;
    align-self: stretch;
    width: 0;

    font-size: 1rem;
    text-align: right;

    border: none;
    outline: none;
}
