.container {
    composes: font from 'ui/utils.css';

    margin: 2rem 3rem;

    background-color: white;
    border-radius: 0.375rem;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.625rem 1.25rem;
}

.optionsContainer {
    display: flex;
    gap: 1.625rem;
    align-items: center;
}

.toolbar {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 1.625rem;
    align-items: center;
}

.title {
    composes: font-semi-xl from 'ui/utils.css';

    margin: 0;

    color: var(--color-grayscale-100);
}
