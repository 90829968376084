.container {
    width: 100%;
    max-width: 24.375rem;
}

.heading {
    color: #323c47;
    font-weight: 500;
    font-size: 1.75rem;
}

.link {
    color: #4a87cd;
    font-size: 0.75rem;
    text-decoration: none;
}

.createAccountCopy {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.75rem;

    font-size: 0.75rem;
    white-space: pre-wrap;
}

.input {
    min-width: 0;
}

.error {
    display: block;
    margin-top: 0.75rem;

    color: red;
    font-size: 0.75rem;
}

.submit {
    width: 100%;
    margin-top: 1.875rem;
    padding: 0.86rem 1rem;
}

.row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
