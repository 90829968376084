.status {
    display: flex;
    align-items: center;

    column-gap: 0.5rem;
}

.icon.completed,
.label.completed {
    color: var(--color-link);
}

.icon.live,
.label.live {
    color: var(--color-status-success);
}

.icon.planned {
    color: var(--color-neutral-400);
}

.label.planned {
    color: var(--color-grayscale-100);
}
