.button {
    width: 100%;
    height: 2.6rem;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;

    background: #109cf1;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.24);
    cursor: pointer;
}

.button:hover {
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.12);
}
