.container {
    display: flex;
    flex-direction: column;
    gap: 0.325rem;
    padding: 0.5rem 0.25rem 0;

    border-top: solid 3px #eef0f2
}

.groupSkeleton {
    width: 100%;
    height: 3.75rem;
}
