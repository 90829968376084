.content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

h1 {
    font-weight: 500;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 3.5rem 2.5rem;

    color: #192a3e;
    text-align: left;
}

.toolbar {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem 0;
}

.searchContainer {
    flex: 1;
}

.search {
    max-width: 18.75rem;

    background-color: white;
    border-color: transparent !important;
}

.addPlanButton {
    height: 2.6rem;
    padding: 0 1rem;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;

    background: #109cf1;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.24);
    cursor: pointer;

    transition: transform 0.2s;
}

.addPlanButton:hover {
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.12);
    transform: translate(3px);
}

.addPlanButton:active {
    outline: none;
}

.tableContainer {
    background-color: white;
}

.table {
    width: 100%;
    padding-left: 2.7rem;
}

.columnHeaderRow {
    height: 3rem;

    color: #334d6e;
    font-size: 0.7rem;
    letter-spacing: 0.2px;
    text-align: left;

    opacity: 0.5;
}

.columnHeaderRow th {
    font-weight: 400;
}

.tableRow {
    height: 4rem;

    color: #323c47;
    font-size: 0.75rem;
    line-height: 18px;
    letter-spacing: 0.1px;

    border-top: #d3d8dd 1px solid;
    cursor: pointer;
}

.deleteButton {
    height: 2rem;
    padding: 0 1rem;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;

    background: #b10303;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: pointer;

    transition: transform 0.2s;
}

.deleteButton:hover {
    transform: translate(3px);
}

.loadMoreContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loadMoreButton {
    flex: 1;

    font-weight: 400;
}
