.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    margin: 1.875rem;

    color: #192a3e;
}

.title {
    margin: 0;

    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
}

.copy {
    margin: 0;

    text-align: center;
}
