.tooltip {
    position: relative;
    top: 0.5rem;

    max-width: 24rem;
    padding: 0.875rem;

    color: white;
    font-size: 0.8rem;

    background-color: #323c47;
    border-radius: 5px;
}
