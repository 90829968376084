.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.375rem;
}

.label {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-50);

    transition: color 0.1s ease-in-out;
}

.input {
    composes: font-sm from 'ui/utils.css';

    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
    outline: none;

    transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.input::placeholder {
    color: var(--color-grayscale-40);
}

.input:disabled {
    color: var(--color-grayscale-40);

    background-color: var(--color-neutral-100);
    cursor: not-allowed;
}

.field:not(.disabled):hover .label,
.field:not(.disabled):focus-within .label {
    color: var(--color-grayscale-100);
}

.field:not(.disabled):hover .input {
    box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.10);
}

.field:not(.disabled):focus-within .input {
    border-color: var(--color-brand-primary);
    box-shadow: 0 0 0 3px rgba(254, 0, 43, 0.10);
}

.input.error {
    border-color: var(--color-status-fail);
}

.moneyInput,
.dateInput {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;

    column-gap: 0.75rem;

    background-color: #FFF;
}

.moneyInput input {
    border: none;
    outline: none;
}

.moneyInput .inputField {
    min-width: 0;
}

.moneyInput.disabled,
.dateInput.disabled {
    color: var(--color-grayscale-40);

    background-color: var(--color-neutral-100);
    cursor: not-allowed;
}

.dateInput {
    justify-content: space-between;
}

.dateIcon {
    width: 1rem;
    height: 1rem;

    color: var(--color-neutral-400);
}

.dropdown {
    composes: shadow-md from 'ui/utils.css';

    border-radius: 0.75rem;
}
