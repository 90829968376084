.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
}

.input {
    flex: 1;
    width: 100%;

    color: inherit;

    background: transparent;
    border: none
}

.input:focus {
    outline: none;
}
