.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.625rem;

    font-family: Poppins, sans-serif;
    text-align: left;

    background-color: white;
    border: none;
    border-radius: 0.375rem;
    cursor: pointer;;

    transition: background-color 0.2s ease-in-out;
}

.container:hover {
    background-color: white;
}

.container.disabled {
    cursor: not-allowed;
}

.container.disabled:hover {
    background-color: white;
}

.container > * + * {
    margin-left: 0.5rem;
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;

    border-radius: 10rem;
}

.iconContainer.disabled {
    opacity: 0.8;
}

.icon {
    stroke: #323c47;
}

.icon.loading {
    animation-name: spin;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.labelsContainer.disabled {
    opacity: 0.8;
}

.label {
    margin: 0;

    color: #323c47;
    font-weight: 500;
    font-size: 0.9rem;
}

.subLabel {
    margin: 0;

    color: #90A0B7;
    font-size: 0.6125rem;
    text-transform: lowercase;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
