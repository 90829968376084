.container {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.pageControls {
    display: flex;
    gap: 0.875rem;
    align-items: center
}

.button {
    min-width: 2rem;
    min-height: 2rem;
    padding: var(--Spacing-Spacing-1) var(--Spacing-Spacing-2);
}

.button svg {
    width: 1rem;
    height: 1rem;
}
