.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    height: 100vh;
    padding: 1.5rem;
}

.header {
    display: flex;
    justify-content: space-between;
}

.menu {
    display: flex;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.myReportsFilter {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;

    color: #000;
}

.title {
    margin: 0;

    font-weight: 700;
    font-size: 1.75rem;
}

.searchInput {
    max-width: 20rem;
}

.tableContainer {
    flex: 1;
}
