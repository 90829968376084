.page {
    width: 100%;
    min-height: 100vh;
}

.container {
    margin: 1.875rem;
    padding: 1.25rem;
}

.container > * + * {
    margin-top: 1.25rem;
}
