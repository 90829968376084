.upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 178px;
    padding: 30px;

    background: #f2f9ff;
}

.upload p {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 25px 0 0;

    color: #90a0b7;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
}

.upload p span {
    font-size: 13px;
    line-height: 18px;

    opacity: 0.5;
}

.browse {
    position: relative;

    margin-top: -4px;

    color: #109cf1;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
}

.browse input {
    position: absolute;
    top: 0;
    left: 0;

    display: flex !important;
    width: 100%;
    height: 100%;

    font-size: 0;

    cursor: pointer;
    opacity: 0;
}

.previews {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    max-width: 50%;
    height: 100%;
    overflow: auto;
}

.previews img {
    display: block;
    max-width: calc(50% - 10px);
    padding: 5px;
}

.uploadHasPreview {
    flex-direction: row;
}

.uploadArea {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadAreaHasPreview {
    flex-direction: column;
    align-items: center;
    min-width: 50%;
}
