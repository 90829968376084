.modal {
    min-width: 30rem;
}

.actions {
    margin-top: 3rem;
}

.error {
    color: red;
    font-size: 0.75rem;
}
