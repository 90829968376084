.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1rem;
    height: 1rem;
    overflow: hidden;

    border: 1px solid var(--Borders-Grey);
    border-radius: 0.25rem;
    cursor: pointer;
}

.checkbox.checked {
    background-color: var(--Button-Primary-primary);
    border-color: var(--Button-Primary-primary);
}

.checkbox svg {
    color: white;
}

.check {
    width: 1rem;
    height: 1rem;
}

.checkbox.disabled {
    cursor: not-allowed;
}
