.table {
    width: 100%;

    table-layout: auto;
}

.table th {
    font-size: 0.625rem;
}

.table td {
    min-width: 0;
}
