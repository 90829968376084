.thead {
    color: #90a0b7;
    font-weight: 500;
    font-size: 0.9375rem;

    background: #f5f6f8;
    border-right: 1px solid #f5f6f8;
    border-left: 1px solid #f5f6f8;
}

.thead th {
    padding: 0.8125rem 0.625rem;

    white-space: nowrap;
}

.tbody {
    font-size: 0.625rem;
}

.tbody tr:hover {
    background-color: #f2f9ff;
}

.tbody td {
    display: table-cell !important;
    min-width: auto;
    padding: 0;

    color: #152e4a;

    border: 1px solid #e7eef6;
}

td.start_date.editorUser:hover,
td.end_date.editorUser:hover,
td.cost.editorUser:hover,
td.channel.editorUser:hover,
td.format.editorUser:hover,
td.objective.editorUser:hover,
td.targeting.editorUser:hover,
td.creatives.editorUser:hover,
td.optimisation_metric.editorUser:hover,
td.buy_platform.editorUser:hover,
td.buy_method.editorUser:hover,
td.estimated_cost.editorUser:hover,
td.xero_invoice_id.editorUser:hover,
td.campaigns.editorUser:hover,
td.alerts_enabled.editorUser:hover {
    box-shadow: inset 0 0 1px #0090ff;
}

.cellContent {
    max-width: 6.25rem;
    padding: 0.625rem;
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.selectCellContent {
    width: 6.25rem;
    padding: 0;
}

.tbody p {
    margin: 0;
}

.id {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox {
    margin-right: 0.5rem;
}

.cellInput {
    box-sizing: border-box;
    width: 100%;

    color: inherit;
    font-weight: inherit;
    font-size: inherit;

    background-color: transparent;
    border: none;
    outline: none;
}

.cellInput:hover,
.cellInput:focus,
.dateInput:hover,
.dateInput:focus {
    background-color: rgb(231, 238, 246, 0.9);
}

.cellInput[disabled]:hover,
.cellInput[disabled]:focus,
.dateInput.disabled:hover,
.dateInput.disabled:focus {
    background-color: transparent;
    cursor: inherit;
}

.cellInput.invalid {
    outline: 1px auto rgba(247, 104, 91, 0.5);
}

.amountSpentContainer {
    min-width: 7rem;
    padding: 0 0.625rem;
}

.amountSpent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    color: #192a3e;
    font-weight: 600;
}

.amountSpentPercentage {
    color: #90a0b7;
    font-weight: 400;
}

.targetingContainer,
.creativesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 11rem;
}

.badges {
    flex: 1;
    padding: 0.625rem 0 0.625rem 0.625rem;
}

.badges > * {
    min-width: calc(50% - 0.325rem);

    text-align: center;
}

.addTargetingButton,
.addCreativeButton {
    padding: 0.625rem;

    color: #152E4A;
}

.addTargetingButton:hover,
.addCreativeButton:hover {
    background-color: #e6f4ff;
}

.alertsCellContent {
    width: 2.25rem;
    padding: 0 0.625rem;
}

.linkedCampaignsContent {
    display: flex;
    align-items: center;
    justify-content: center;
}

.linkIcon {
    cursor: pointer;
}

.linkIcon.linked > * {
    stroke: #0090ff;
}

.unlinkedPopover {
    padding: 0.5rem 0.9375rem;

    font-size: 0.75rem;

    background-color: white;
    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
    cursor: default;
}

.invoiceContainer {
    padding: 0 0.5rem;

    cursor: pointer;
}

.invoiceNum {
    overflow: hidden;

    font-weight: 400;
    font-size: 0.75rem;
    text-overflow: ellipsis;
}

.invoiceNum:hover {
    background-color: rgb(231, 238, 246, 0.9);
}

@keyframes highlightFlash {
    12% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    24% {
        outline: none;
    }

    36% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    48% {
        outline: none;
    }

    60% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }

    72% {
        outline: none;
    }

    84% {
        outline: 3px solid var(--color-danger);
        outline-offset: -0.2rem;
    }
}

.highlighted {
    animation-name: highlightFlash;
    animation-duration: 2s;
}
