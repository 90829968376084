.filter {
    min-width: 14rem;
}

.filterIcon {
    width: 1.25rem;
    height: 1.25rem;

    color: var(--color-neutral-400);
}
