.container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.container > * {
    flex: 1;
}

.track {
    position: relative;

    height: 0.2rem;

    background-color: #eef0f2;
}

.indicator {
    position: absolute;

    height: 100%;

    background-color: #192a3e;
}
