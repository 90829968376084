.list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.icon {
    width: 1.25rem;
    margin-right: 0.5rem;

    stroke: #323c47;
}

.button {
    padding: 0.625rem 1.5625rem;

    color: #323c47;

    border-color: #c2cfe0;
}
