.container {
    width: 100%;
    overflow-x: auto;

    white-space: nowrap;

    border-bottom: 1px solid var(--Borders-Grey---Extra-light);
}

.stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 86rem;

    padding: 1.5rem;
}

.item {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;
    padding: var(--4);
}

.item dd {
    margin: 0;
}

.label {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weak);
}

.value {
    min-width: 5rem;

    color: #242424;
    font-weight: 600;
    font-size: 1.25rem;
    font-family: var(--Fonts-Body);
    line-height: 1.5rem
}
