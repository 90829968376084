.header {
    background: linear-gradient(90deg, #2c3e50 0%, #3498db 100%);
}

.tabsNav {
    padding: 0.5rem 1.5rem 0;
}

.campaigns {
    padding: 0 1.5rem;
}
