.table td:first-of-type {
    padding-left: 1rem;
}

.table td:not(.table td:first-of-type) {
    min-width: 8.75rem;
    max-width: 15rem;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.postLink {
    color: #3451FF;
    white-space: nowrap;
    text-decoration: none;
}

.postLink:hover {
    text-decoration: underline;
}

.reference {
    white-space: nowrap;
}

.currencyInput {
    padding: 0.625rem 0.75rem;

    border: 1px solid transparent;
    border-radius: 0.75rem;
}

.currencyInput:hover {
    background: #F3F4F6;
    border: 1px solid #D1D5DB;
}

.currencyInput:focus {
    background: #F3F4F6;
}

.paymentCell {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-start;

    white-space: nowrap;
}
