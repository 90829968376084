.container {
    display: flex;
    gap: 1rem;
}

.infoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.image {
    width: 3.25rem;
    height: 3.25rem;
    object-fit: cover;

    border-radius: 50%;
}

.title {
    font-weight: 500;
    font-size: 1rem;
}

.title.small {
    font-size: 0.8rem;
}

.badge {
    margin-left: 0.5rem;
    padding: 0.125rem 0.5rem;

    color: white;
    font-size: 0.6rem;

    border-radius: 0.3rem;
}

.badge.original {
    background-color: #ed7200;
}

.badge.official {
    background-color: #2a5484;
}
