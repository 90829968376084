.projectContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.75rem;
}

.projectDetails > p {
    margin: 0;
}

.projectImage {
    width: 2rem;
    height: 2rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.projectName {
    font-weight: var(--Weight-500, 500);
}

.brandName {
    color: var(--Text-Weak, #767676);
}
