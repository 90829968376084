.container {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.statusBadge {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.25rem;
    padding: 0.125rem 0.5rem;

    color: var(--text-white);

    border-radius: 0.25rem
}

.live {
    background: #48D98A;
}

.planned {
    background: #F3F4F5;
}

.linkIcon {
    color: var(--Icon-secondary, #D1D1D1);
}

.linkIcon:hover {
    color: var(--Icon-base, #4B4B4B);
}
