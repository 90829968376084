.inner {
    box-sizing: border-box;
    width: 100%;
    padding: 30px;
}

.header {
    width: 100%;
}

.title {
    margin: 5px 0 0;

    color: #192a3e;
    font-size: 18px;
    line-height: 28px;
}

.close {
    position: absolute;
    top: 17px;
    right: 27px;

    width: 17px;

    cursor: pointer;
    opacity: 0.3;

    transition: all 0.2s ease-in-out;
}

.close path {
    fill: #192a3e;
}

.close:hover {
    opacity: 1;
}

.content {
    box-sizing: border-box;
    width: 100%;
    margin-top: 15px;
    padding: 10px;

    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
}

.inProgress {
    cursor: wait;
}

.videos {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 40px;
}

@media only screen and (max-width: 1400px) {
    .close {
        top: 20px;
        right: 20px;
    }
}
