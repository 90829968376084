.loadDataCell {
	height: 3.15rem;
	padding: 1rem;

	color: #707683;
	font-weight: 400;
	font-size: 12px;
	font-style: normal;
	line-height: 18px;
	letter-spacing: 0.1px;
	text-align: center;

	cursor: pointer;
}

.loadDataCell:hover {
	opacity: 0.5;
}

.loadingDataCell {
	height: 3.15rem;
	padding: 1rem;
}
