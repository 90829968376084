.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.875rem;

    color: #192a3e;
}

.container > * + * {
    margin-top: 1rem;
}

.title {
    margin: 0;

    font-weight: 600;
    font-size: 1.2rem;
}

.copy {
    margin: 0;

    text-align: center;
}
