.table {
    width: 100%;
    overflow: hidden;

    table-layout: fixed;
    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
}

.table thead tr th {
    border-top: none;
}
