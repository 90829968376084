.overlay {
    /* It's a workaround for previous modals compatability */
    z-index: 35;

    background: rgba(17, 17, 17, 0.20);
    backdrop-filter: blur(1px);
}

.modal {
    composes: font from 'ui/utils.css';

    position: absolute;
    top: 10rem;
    left: 50%;

    padding: 1.75rem;

    background-color: white;
    border-radius: 0.75rem;

    transform: translateX(-50%);
}

.closeIcon {
    position: absolute;
    top: 0.775rem;
    right: 0.775rem;

    width: 1.125rem;
    height: 1.125rem;

    color: var(--color-neutral-300);

    cursor: pointer;
}

.header {
    composes: font-bold-xl from 'ui/utils.css';

    margin-bottom: 1.5rem;
}

.actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.75rem;

    column-gap: 0.75rem;
}
