.legend {
    margin-bottom: 0.25rem;

    color: #192a3e;
    font-weight: 400;
    font-size: 8px;
    font-style: normal;
    line-height: 9px;
    letter-spacing: 0.2px;
}

.topRow {
    display: flex;
    justify-content: space-between;
    padding: 30px;
}

.labelChoices {
    display: flex;

    color: #192a3e;
    font-size: 8px;
    letter-spacing: 0.2px;
}

.datasetLabel {
    padding-right: 0.5rem;

    cursor: pointer;
}

.activeDatasetLabelUnderline {
    height: 1px;

    background: #109cf1;
}

.pills {
    display: flex;
}

.pill {
    margin: 0 0.25rem;
    padding: 0.5rem 1rem;

    color: #000000;
    font-weight: 400;
    font-size: 9px;
    font-style: normal;
    line-height: 6px;
    letter-spacing: 0.2px;
    text-align: center;

    background: #c2cfe0;
    border-radius: 4px;
    cursor: pointer;
}

.selectedPill {
    opacity: 0.5;
}
