.table {
    width: 100%;
}

.postStatus {
    display: flex;

    color: #4c5862;
    font-weight: 500;

    column-gap: 0.875rem;
}

.linkIcon {
    width: 0.9rem;
    height: 0.9rem;
    stroke-width: 1rem;

    color: var(--color-grey-4);
}

.linkIcon path {
    stroke-width: 0.125rem;
}

.lastContactedAtContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.historyButton {
    padding: 0.25rem;

    visibility: hidden;
}

.lastContactedAtContainer:hover .historyButton {
    visibility: visible;
}

.historyIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
}
