.videosItem {
    width: calc(20% - 24px);
    margin-right: 30px;
    margin-bottom: 30px;
}

.videosItemHeader {
    display: flex;
    width: 100%;
    height: 14px;
}

.videosItemWrapper {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 10px 0;
    padding-top: 140%;
    overflow: hidden;

    background: #f5f6f8;
}

.videosItem video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: flex;
    width: auto;
    max-width: 100%;
    height: 100%;
    margin: auto;
}

.videosItem:nth-child(5n) {
    margin-right: 0;
}

.videosItem input {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin-right: 5px;
    padding: 0;
    overflow: hidden;

    font-weight: 700;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.2px;
    white-space: nowrap;
    text-overflow: ellipsis;

    border: 1px solid #fff;
    outline: none;
    cursor: pointer;
}

.videosItem input:focus {
    border: 1px solid #000;
}

.videosItemBtnAccept,
.videosItemBtnDelete {
    min-width: 14px;

    cursor: pointer;
}

.videosItemBtnAccept {
    margin-right: 4px;
}

.videosItemBtnAcceptInProgress,
.videosItemBtnDeleteInProgress {
    cursor: wait;
}

@media only screen and (max-width: 1400px) {
    .videosItem {
        width: calc(33.3% - 20px);
    }

    .videosItem:nth-child(5n) {
        margin-right: 30px;
    }

    .videosItem:nth-child(3n) {
        margin-right: 0;
    }
}

@media only screen and (max-width: 768px) {
    .videosItem {
        width: calc(50% - 15px);
    }

    .videosItem:nth-child(5n) {
        margin-right: 30px;
    }

    .videosItem:nth-child(3n) {
        margin-right: 30px;
    }

    .videosItem:nth-child(2n) {
        margin-right: 0;
    }
}
