.header {
    position: absolute;

    display: flex;
    justify-content: flex-end;
    width: 100%;

    margin: 0;
    padding: 1.5rem;

    color: white;

    background: linear-gradient(90deg, #2c3e50 0%, #3498db 100%);
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0 1.5rem;

    text-align: center;


    row-gap: 0.5rem;
}

.linkIcon {
    width: 7.5rem;
    height: 7.5rem;
}

.title {
    composes: heading-large from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.copy {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.roundLogo {
    width: 1.75rem;
}
