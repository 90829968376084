.container {
    position: relative;

    cursor: pointer;
}

.container.readonly {
    cursor: default;
}

.editContainer {
    composes: shadow from 'ui/utils.css';

    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;

    display: none;
    padding: 0.625rem 0.875rem;

    background: white;

    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
    transform: translateY(-50%);
}

.editContainer.visible {
    display: initial;
}
