.table {
    /* These vars help account for the thick border on the left edge of the table rows */
    --cell-side-padding: var(--Spacing-Spacing-3, 0.75rem);
    --table-edge-marker-width: 0.5rem;
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    width: 100%;

    color: var(--Text-Base, #242424);

    white-space: nowrap;

    background: var(--Tables-surface, #FFF);
    border-spacing: 0;
}

.table tbody {
    position: relative;
}

/* This acts as a thick border on the left edge of the table rows */
.table tbody::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: var(--table-edge-marker-width);

    background: var(--Campaign-Status-Planned, #5AF);

    content: '';
}

.table th {
    padding: var(--Spacing-Spacing-1, 0.25rem) var(--cell-side-padding);

    background: var(--Tables-header-grey, #F6F6F6);
    border-bottom: 1px solid var(--Tables-border, #D1D1D1);
}

.table thead th, .table tfoot {
    /* P Small Medium */
    font-weight: var(--Weight-500, 500);
    font-size: var(--Size-12, 0.75rem);

    font-family: var(--Fonts-Body, Inter);
    font-style: normal;
    line-height: var(--Line-Height-16, 1rem); /* 133.333% */
    letter-spacing: var(--Letter-Spacing-0, 0);
}

.table th:first-child{
    padding-left: calc(var(--cell-side-padding) + var(--table-edge-marker-width));
}

.table td {
    padding: var(--Spacing-Spacing-2, 0.5rem) var(--Spacing-Spacing-3, 0.75rem);

    border-bottom: 1px solid var(--Tables-border, #D1D1D1);
}

.table tbody td {
    height: 3.625rem;
    min-height: 3.625rem;
}

.table tfoot td {
    padding: var(--Spacing-Spacing-1, 0.25rem) var(--Spacing-Spacing-3, 0.75rem);
}

.table td:first-child {
    padding-left: calc(var(--cell-side-padding) + var(--table-edge-marker-width));
}

.table td:last-child {
    border-right: 1px solid var(--Borders-Grey---Extra-light, #E7E7E7);
}

.table tbody tr:not(:has(*[aria-label="no posts"])):hover {
    background: var(--tables-surface-hover, #F4F4FF);
}

.table tfoot td:not(:empty) {
    background: #EFEFEF;
}

.table tfoot td:first-child {
    border-left: 1px solid var(--Borders-Grey---Extra-light, #E7E7E7);
}

.table tfoot td:last-child {
    border-right: 1px solid var(--Borders-Grey---Extra-light, #E7E7E7);
}

.table.empty > tbody {
    background: var(--Background-light, #F9FAFB);
}

/* This ensures that the table scrolls independently of parent table */
.scrollView {
    position: absolute;
    right: 0;
    left: 0;

    overflow-x: auto;
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
}
