.messageContainer {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 0.625rem;
    align-items: center;
}

.warningContainer {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 0;

    color: var(--color-status-fail)
}

.spinnerContainer {
    height: 1.5rem;
}

.spinner {
    width: 1.5rem;
    height: 1.5rem;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
}

.messageContainer .icon {
    color: var(--color-neutral-400);
}

.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
