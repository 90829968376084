.overlay {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';

    --transition-duration: 0.125s;
    z-index: 10;

    background-color: rgba(17, 17, 17, 0.20);

    animation: var(--transition-duration) linear fade;
}

.modal {
    position: absolute;
    top: 2.5rem;
    bottom: 0;
    left: 0;

    width: 100%;

    overflow-y: auto;

    background-color: white;
    border-radius: var(--rounded-lg) var(--rounded-lg) 0 0;

    animation: var(--transition-duration) linear slide;
}

@keyframes slide {
    0% {
        transform: translateY(100%);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fade {
    0% {
        background-color: transparent;
    }

    100% {
        background-color: rgba(17, 17, 17, 0.20);
    }
}
