.menuContainer {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    margin: 0;
    padding: 1.625rem 1.25rem;

    column-gap: 1rem;

    border-top: 1px solid var(--color-neutral-200, #E5E7EB);
}

.filtersContainer {
    flex: 1;
}

.filtersContainer, .actions {
    display: flex;
    align-items: center;

    column-gap: 1rem;
}

.deadlineSelectPopover {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.5rem;
}

.setDeadlineSubmit {
    justify-content: center;

    margin: 0 0.5rem 0.5rem;
}

.actionButton {
    align-self: stretch;
    padding: 0 0.5rem;
}

.showOnlySelectedFilter {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    flex-flow: row nowrap;

    align-items: center;

    color: var(--color-grayscale-100);
}
