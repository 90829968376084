.container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 0.5rem;

    background-color: #cccccc;
    opacity: 0.5;
}

.image {
    object-fit: cover;
}
