.container {
    display: flex;
    gap: 0.75rem;
}

.button {
    padding: 0.375rem 0.75rem;
}

.disabled {
    opacity: 0.1;
}
