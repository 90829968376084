.container {
    padding: 0.5rem 1.5rem;
}

.actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.plusIcon {
    width: 0.875rem;
    height: 0.875rem;
}
