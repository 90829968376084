.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    background-color: rgba(0, 0, 0, 0.4);
}

.dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 35;

    min-width: 350px;
    padding: 1rem;

    background: white;
    border-radius: 5px;
    transform: translate(-50%, -50%);
}

.dialog p {
    margin: 0;
}

.dialog > * + * {
    margin-top: 1rem;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;

    cursor: pointer;
}

.dialogTitle {
    font-size: 1.2rem;
}

.dialogBody {
    font-weight: 300;
    font-size: 1rem;
}

.buttonContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
}

.buttonContainer > * + * {
    margin-left: 0.5rem;
}
