.th {
	cursor: pointer;
}

.cell {
	display: flex;
	align-items: center;
}

.cell svg {
	width: 0.5rem;
	height: 0.5rem;
	margin-left: 0.5rem;
}
