.valueContainer {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    align-items: center;
}

.flag {
    min-width: 15px;
}

.label {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
}
