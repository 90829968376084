.modalContent {
    max-height: 65vh;
    padding-bottom: 0.25rem;
    overflow-y: auto;
}

.form > * + * {
    margin-top: 1.25rem;
}

.row {
    display: flex;
    flex-direction: row;
}

.row > * + * {
    margin: 0 0 0 1.875rem !important;
}

.evenFields > * {
    flex: 1;
}

.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inline > * + * {
    margin: 0 0 0 0.2rem !important;
}

.genderLabel {
    display: inline-block;
    margin-bottom: 1rem;
}

.submit {
    flex: 1;
}

.discardChanges,
.discardChanges *,
.deleteTargetingTemplate,
.deleteTargetingTemplate * {
    z-index: 1000;
}

.discardChangesContent {
    width: 31.25rem;
}

.deleteTargetingTemplate {
    text-align: center;
}
