.table thead {
    color: #90a0b7;
    font-size: 0.9375rem;

    background: #f5f6f8;
    border-right: 1px solid #f5f6f8;
    border-left: 1px solid #f5f6f8;
}

.table th {
    padding: 0.8125rem 0.625rem;

    color: #58698e;
    font-weight: 600;
    white-space: nowrap;
}

.table tbody {
    color: #707683;
    font-size: 0.625rem;
}

.table tbody tr:hover {
    background-color: #f2f9ff;
}

.table td {
    display: table-cell !important;
    min-width: auto;
    max-width: 6.25rem;
    padding: 0.625rem;
    overflow: hidden;

    color: inherit;

    white-space: nowrap;
    text-overflow: ellipsis;

    border: 1px solid #e7eef6;
}

.table tfoot {
    padding: 0.375rem 1.125rem 0.375rem 0.375rem;

    color: #58698e !important;
    font-weight: 600;
    font-size: 0.75rem;

    background-color: #f5f6f8;
    border: 1px solid #e7eef6;
}

.table a {
    color: #4a87cd;
    text-decoration: none;

    cursor: pointer;
}

.table a:hover {
    text-decoration: underline;
}
