.card {
    position: relative;

    padding: 0.5rem 0.625rem;

    background-color: white;
    border-radius: 0.375rem;
}

.card.cursorHelp {
    cursor: help;
}

.iconContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;

    background-color: #C2CFE080;
    border-radius: 50%;
}

.icon {
    width: 0.6125rem;
    height: 0.6125rem;

    color: #90A0B7;
}

.tooltip {
    padding: 0.5rem;
}
