.table {
    width: 100%;
    overflow: hidden;

    table-layout: fixed;
    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
}

.table thead th {
    border-top: none;
}

.reference {
    composes: shadow from 'ui/utils.css';

    width: 100%;
    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
    outline: none;
}

.statusContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.statusContainer svg, .spinnerContainer {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
}

.spinner:before {
    width: 1.25rem;
    height: 1.25rem;
}

.failure {
    color: var(--color-status-fail);
}

.success {
    color: var(--color-status-success);
}
