.postLink {
    color: var(--color-link);
    text-decoration: none;

    cursor: pointer;
}

.postLink:hover {
    text-decoration: underline;
}

.paymentDetails {
    white-space: pre-line;
}

.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.paymentActions {
    composes: font-sm from 'ui/utils.css';
}

.paymentActions button {
    padding: 0.375rem 1.5rem;

    font-weight: 400;

    border: 1px solid var(--color-neutral-300, #D1D5DB);
    border-radius: 0.75rem;
}

.total {
    composes: font-semi-sm from 'ui/utils.css';
}
