.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    padding: 0 1.5rem;

    row-gap: 0.5rem;

    text-align: center;
}

.title {
    composes: heading-large from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}

.copy {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;
}
