.container {
    position: sticky;
    top: 0;
    z-index: 1;

    background-color: white;
}

.groupContainer {
    overflow-y: auto;

    border-bottom: 1px solid #dddddd;
}

.groupHeader {
    position: sticky;
    top: 0;

    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 0.5rem;

    font-weight: 500;

    font-size: 0.875rem;

    background-color: #ededed;

    border-bottom: solid 1px #e5e5e5;

    cursor: default;
}

.groupHeader.isOpen {
    border-color: #dddddd;
}

.labelsContainer {
    z-index: 2;

    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.groupHeader:hover {
    background-color: #dddddd;
}

.countLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.25rem;

    color: #999;
    font-weight: 600;
    font-size: 0.75rem;

    background-color: #f5f5f5;
    border-radius: 50%;
}

.rotated {
    transform: rotate(180deg);
}

.clearButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;

    color: white;
    font-weight: 500;
    font-size: 0.675rem;

    background-color: #292D32;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
}
