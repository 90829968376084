.dropdownIcon {
    margin-left: 0.3rem;

    transition: transform 0.2s linear;

    will-change: transform;
}

.dropdownIcon.isOpen {
    transform: rotate(180deg);
}

.dropdown {
    z-index: 10;

    min-width: 150px;
    padding: 0.25em;

    background-color: white;
    border-radius: 0.3em;
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.24);
}
