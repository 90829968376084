.container {
    position: relative;

    overflow: hidden;
}

.image {
    width: 100%;
    height: 100%;

    border-radius: 50%;
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    background-color: #00000050;
}

.overlay.hidden {
    display: none;
}

.container:hover .overlay.hidden {
    display: flex;
}

.spinner div:before {
    border-color: #f7f7f750;
    border-bottom-color: white;
}
