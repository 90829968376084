.post {
    display: flex;
    flex-flow: row nowrap;

    column-gap: 0.5rem;
}

.postLink {
    color: var(--color-neutral-400);

    text-decoration: none;
}

.postLink:hover {
    color: var(--color-grayscale-100);

    text-decoration: underline;
}

.postLinkIcon {
    width: 1rem;
    height: 1rem;
}
