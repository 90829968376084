.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.25rem;
}

.header {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.title {
    margin: 0;

    font-weight: 500;
    font-size: 1.125rem;
}

.activeCampaigns {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: 0.8rem;
}

.activeCampaignsBadge {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 0.5rem;
    padding: 0.1rem 0.5rem;

    color: white;
    font-weight: 700;

    background-color: #2cbe6f;
    border-radius: 1rem;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.activeCampaignsBadgeIcon {
    margin-right: 0.25rem;
}

.searchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.125rem;
}

.search {
    height: auto;
}

.search::placeholder {
    color: #90a0b7;
}
