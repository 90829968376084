.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;

    cursor: pointer;
}

.container:hover {
    background-color: #f2f9ff;
}

.checkbox {
    margin-top: 0.3rem;

    cursor: pointer;
}

.label {
    margin: 0 0 0 0.5rem;

    color: #323c47;
    font-weight: 500;
    font-size: 0.8rem;
}

.id {
    color: #aeaeae;
    font-size: 0.8rem;
}
