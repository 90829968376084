.field {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
}

.field input,
.field textarea {
    height: 36px;
    padding-left: 0.6rem;

    color: hsl(0deg, 0%, 20%);
    font-size: 1rem;
    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: none;
}

.field input:focus,
.field textarea:focus {
    color: rgb(98, 98, 98);

    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
}
