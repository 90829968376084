.container {
    composes: shadow-lg from 'ui-new/whitelabel/utils/effects.css';

    display: flex;
    flex-direction: column;
    gap: var(--4, 1rem);
    align-items: flex-start;
    width: 16.3125rem;
    padding: var(--2, 0.5rem);

    background: var(--Background-white, #FFF);

    border-radius: var(--rounded-md, 0.375rem);
}

.overlay {
    /*TODO: We should create a whitelabel popover wrapper, so we only need to do this in one place */
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.label {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-primary)
}

.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
    width: 100%;
}

.button {
    color: var(--Icon-secondary);
}

.button[data-open="true"],
.button:hover {
    color: var(--icon-base);
}

.spinner {
    width: 1rem;
    height: 1rem;
}
