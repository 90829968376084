.headlineDataContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    margin-top: 1.25rem;
}

.headlineDataContainer > * {
    width: 100%;
}

.tabItem {
    composes: text-small-regular from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-body);
}

.activeTab {
    composes: text-small-medium from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-interactive);
}

.activeTabIndicator {
    background-color: var(--Borders-interactive-solid);
}

.activeTabIndicatorTrack {
    height: 0.0938rem;

    background-color: var(--Borders-dark);
}
