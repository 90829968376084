.scrollWrapper {
    width: 100%;
}

.table {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    white-space: nowrap;
}

.badge {
    color: var(--text-white);
}

.official {
    background-color: var(--Badges-Live);
}

.original {
    background-color: var(--Badges-Completed);
}

.table tr:last-child td:first-child {
    border-bottom-left-radius: 0;
}

.table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
}

.noDataLabel {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;

    color: var(--text-light);
    text-align: center;
}

.noDataLabel p {
    margin: 0.5rem 0;
}

.noDataLabel .error svg {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--Icon-destructive);
}

.videoCountContainer {
    display: flex;
    gap: var(--2, 0.5rem);
    align-items: center;
}

.growthValue {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';
}

.positive {
    color: var(--Text-success);
}

.negative {
    color: var(--Text-destructive);
}

td.audio {
    max-width: 14rem
}

.audioContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.audioThumbnail {
    width: 2rem;
    height: 2rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.audioTitle {
    margin: 0;
    overflow: hidden;

    color: var(--Text-Base);

    font-weight: 600;
    text-overflow: ellipsis;
}

.dailyChange {
    width: 8.25rem;
}

.dailyChangeContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: flex-end;
}

.audioIcon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}

.chartContainer {
    position: relative;

    width: 5.625rem;
    height: calc(3.0625rem - 1rem);
}

.chartTooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.chartTooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}
