.container {
    display: flex;
    flex-direction: column;
    grid-row: 2 / span 1;
    grid-column: 1 / span 2;
    gap: 0.625rem;

    margin: 0;
}

.item {
    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 0.25rem;
    justify-content: space-between;

    white-space: nowrap;
}

@media screen and (min-width: 700px) {
    .container {
        display: grid;
        grid-row: 2 / span 1;
        grid-column: 2 / span 1;
        grid-template-columns: repeat(2, 1fr);
        gap: 0.625rem 3rem;
    }

    .item {
        display: flex;
        flex-direction: column;
        justify-content: unset;
    }
}

@media screen and (min-width: 900px) {
    .container {
        display: flex;
        flex-direction: row;
    }

}

@media screen and (min-width: 1200px) {
    .container {
        column-gap: 6.625rem;
    }

    .item {
        flex: unset;
    }
}

.label {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-white, #FFF);
}

.value {
    composes: p-bold from 'ui-new/whitelabel/utils/utils.css';

    margin: 0;

    color: var(--Text-white, #FFF);
}

.value svg {
    color: var(--Text-white, #FFF);
}
