.table thead {
    color: #90a0b7;
    font-weight: 500;
    text-transform: uppercase;

    background-color: #f5f6f8;
    border: 1px solid #f5f6f8;
}

.table th {
    padding: 0.5rem;

    font-size: 0.625rem;
}

.table tbody tr:hover {
    background-color: inherit;
}

.table tbody tr:nth-child(even),
.table tbody tr:nth-child(even):hover {
    background-color: #fafafa;
}

.table td {
    padding: 0.5rem;

    border: 1px solid #eef0f2;
}

.table tfoot {
    display: none;
}
