.fieldLabel {
    font-weight: 400;
    font-size: 0.8rem;
}

.selectContainer {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.selectContainer > * + * {
    margin-left: 0.5rem;
}

.select {
    flex: 1;
}

.select input {
    height: auto;
}

.customAudienceList {
    margin: 0.5rem 0;
    padding: 0.5rem;

    background-color: #fbfbfd;
    border-radius: 0.2rem;
}

.customAudienceListItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem;

    background-color: white;
    border-radius: 0.2rem;

    transition: background-color 0.2s ease-in-out;
}

.customAudienceListItemValue {
    flex: 1;
}

.menuTabsContainer {
    position: relative;

    margin-bottom: 0.2rem;
}

.menuTabsIndicator {
    position: absolute;

    height: 2px;

    background-color: #0c8cda;
}

.menuTabList {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;

    list-style: none;
}

.menuTab {
    flex: 1;
    padding: 0.25rem 0;

    text-align: center;

    border-bottom: 2px solid transparent;
    cursor: pointer;
}

.menuTab:hover {
    border-bottom: 2px solid #0c8cda30;
}

.menuTab.active {
    border-bottom: 2px solid #0c8cda;
}

.optionContainer {
    display: flex;
    justify-content: space-between;

    font-weight: 500;
    font-size: 0.75rem;
}

.optionSubtype {
    color: #999999;
    font-weight: 400;
    font-size: 0.75rem;
}

.optionDetails {
    box-sizing: border-box;
    width: 350px;

    background-color: white;
    border-radius: 0.2em;
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.24);
}

.detailsContainer {
    padding: 0.5rem;
}

.detailsRow {
    margin: 0.5rem;

    font-weight: bolder;
    font-size: 0.8rem;
}

.detailsValue {
    margin-left: 0.3rem;

    font-weight: 400;
}

.removeCustomAudienceIcon {
    margin-left: 0.5rem;

    cursor: pointer;

    fill: #999999;
}

.removeCustomAudienceIcon:hover {
    fill: black;
}
