.container {
    display: inline-flex;
    gap: 0.25rem;
    align-items: center;

    white-space: nowrap;
}

.container svg {
    width: 1rem;
    height: 1rem;
}

.warning {
    color: var(--color-status-warning);
}

.danger {
    color: var(--color-status-fail);
}

.hasTooltip {
    cursor: help;
}

.tooltip {
    composes: font-xs from 'ui/utils.css';

    padding: 0.3125rem;

    color: white;

    background-color: var(--color-text);
    border-radius: 0.3125rem;
}
