.selects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    row-gap: 0.5rem;
}

.locationTypeLabel {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-neutral-400);
}

.spinnerContainer {
    margin: 0 0.5rem;
}

.spinner:before {
    width: 0.75rem;
    height: 0.75rem;

    border-color: inherit;

    border-width: 1px;
    border-bottom-color: transparent;
}
