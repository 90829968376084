.tableContainer {
    height: 100%;
}

.table {
    white-space: nowrap;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.table tbody tr {
    cursor: pointer;
}

.table tbody td {
    font: inherit;
}

.table thead th,
.table tbody td {
    min-width: 10rem;
}

.table tbody tr:has(.noDataContainer) {
    cursor: auto;
}

.table tbody td:not(:has(.noDataContainer)) {
    height: 3rem;
}

.table tbody tr:not(:has(*[aria-label="no reports"])):hover {
    background: var(--tables-surface-hover, #F4F4FF);
}

.noDataContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 22rem;
}

.titles {
    width: 100%;
}

.title {
    composes: p-small-bold from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Base);
}

.subTitle {
    composes: p-extra-small-medium from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weak);
}

.title, .subTitle {
    margin: 0;
}

.teamContainer {
    display: flex;
    align-items: center;
}

.teamContainer > * + * {
    margin-left: -0.75rem;
}

.table .actions {
    min-width: 2rem;
    max-width: min-content;
}

.deleteAction {
    color: var(--Text-destructive, #FF003F);
}
