.wrapper {
    width: 100%;
    overflow-x: auto;
}

.wrapper table {
    width: 100%;
    min-width: 800px;

    text-align: left;

    border-collapse: collapse;
}

.wrapper thead {
    width: 100%;

    background: #d5d5d5;
    border-radius: 4px 4px 0 0;
}

.wrapper thead th {
    height: 1.625rem;
    padding: 0 0.375rem;

    color: #323c47;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.625rem;
    letter-spacing: 0.006rem;

    border-bottom: 1px solid #ebeff2;
    border-left: 1px solid #ebeff2;
}

.wrapper thead th:first-child {
    border-left: none;
    border-top-left-radius: 4px;
}

.wrapper thead th:last-child {
    border-top-right-radius: 4px;
}

.wrapper tbody td {
    height: 1.875rem;
    padding: 0 0.375rem;

    color: #4c5862;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.875rem;

    border: 1px solid #ebeff2;
}
