.header {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    padding: 1.225rem 1rem;
}

.brandImageWrapper {
    position: relative;

    flex: 2;
    max-width: 9rem;
}

.brandImageWrapper::after {
    display: block;
    padding-bottom: 100%;

    content: "";
}

.brandImage {
    position: absolute;

    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    border-radius: 50%;
}

.releaseDetailsContainer {
    display: flex;
    flex: 3;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
}

.detailsHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.titlesColumn {
    display: flex;
    flex: 4;
    flex-direction: column;
}

.title {
    font-weight: 600;
    font-size: 1rem;
}

.subTitle {
    color: var(--color-grey-4);
    font-weight: 500;
    font-size: 0.675rem;
}

.links {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0.5rem;
    align-items: center;
    margin-left: 0.5rem;
}

.tabLink {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;

    color: #58698e;

    border-radius: 0.375rem 0.375rem 0 0;
}

.tabLink.active {
    background: #eee
}

.tabLinkIcon {
    width: 1.5rem;
    height: 1.5rem;
}

.dataCardContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
    margin-top: 0.5rem;
}
