.form > * + * {
    margin-top: 0.75rem;
}

.splitSection {
    display: flex;
    gap: 0.5rem;
}

.splitSection > * {
    flex: 1;
}

.error {
    margin-top: 0.25rem;

    color: red;
    font-size: 0.75rem;
}
