.list {
    min-width: 18.75rem;
    max-height: 18.75rem;
    margin: 0.625rem 0 0;
    padding: 0;
    overflow-y: auto;
}

.listItem {
    box-sizing: border-box;
    width: 100%;
    padding: 0.9375rem;

    cursor: pointer;
}

.listItem:hover {
    background-color: #fafafa;
}

.audio {
    color: #323c47;
    font-weight: 500;
    font-size: 0.875rem;
}
