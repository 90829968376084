.container {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.25rem;
}

.pageControls {
    display: flex;
    gap: 0.875rem;
    align-items: center
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.875rem;

    cursor: pointer;
    aspect-ratio: 1/1;
}

.button:disabled {
    cursor: not-allowed;
}

.button.page {
    color: #767676;
    font-size: 0.875rem;

    background-color: transparent;
    border: none;
    border-radius: 0.625rem;
}

.button.page:not(.active):not(:disabled):hover {
    color: #111;

    background-color: #F3F4F6;
}

.button.page.active {
    color: #FFF;

    background-color: #111;
}

.button.arrow {
    color: #6B7280;

    background: #F3F4F6;
    border: 1px solid #D1D5DB;
    border-radius: 0.75rem;
}

.button.arrow:disabled {
    color: #D1D5DB;
}

.button.arrow:not(:disabled):hover {
    background: #E5E7EB;
}

.goToPage {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;

    padding: 0.1875rem 0.375rem 0.1875rem 0.625rem;

    border: 1px solid var(--color-neutral-300);
    border-radius: 0.625rem;
}

.goToPageInput {
    composes: font-sm from 'ui/utils.css';

    width: 3.125rem;
    height: 100%;

    border: none;
    outline: none;
}

.goToPageInput::placeholder {
    color: var(--color-grayscale-20);
}

.goToPageIcon {
    width: 1.25rem;
    height: 1.25rem;

    color: var(--color-neutral-400);
    aspect-ratio: 1/1;
}
