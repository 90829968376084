.content {
    margin: 0;
}

.deletePlan,
.deletePlan * {
    z-index: 1000;

    text-align: center;
}

.deletePlanContent {
    width: 31.25rem;
}

.submitButton {
    flex: 1;
}

.deleteButton {
    width: 20%;
}

.renderActions {
    display: flex;
    gap: 0.6rem;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.875rem;
}
