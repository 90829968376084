.container {
    padding: 0.875rem 1.5rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}

.container > * {
    margin: 0;
}

.title {
    margin-bottom: 0.3125rem;

    color: #90a0b7;
    font-weight: 600;
    font-size: 0.6875rem;
}

.value {
    color: #192a3e;
    font-weight: 600;
    font-size: 0.875rem;
}

.subtitle {
    margin-left: 0.3125rem;

    color: #58698e;
    font-weight: 400;
    font-size: 0.625rem;
    font-style: italic;
}

.tooltip {
    min-width: auto;
}
