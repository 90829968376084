.tableContainer {
    overflow: auto;
}

.tableContainerContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: fit-content;
    min-width: 100%;
}

.groupsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 0.625rem;
}

.table {
    width: 100%;
    min-width: 80rem;

    text-align: left;

    border-collapse: collapse;
}

.table td input {
    width: 100%;

    color: inherit;
    font-size: inherit;
    text-overflow: ellipsis;

    background-color: transparent;
    border: none;
}

.table tbody tr:hover {
    background-color: #f2f9ff;
}

.table tbody.dropActive {
    background-color: var(--color-primary-3);
}

.tableHeading {
    color: #58698e;

    background-color: #f5f6f8;
}

.tableHeading th {
    padding: 0.8125rem 0.625rem;

    color: #58698e;
    font-weight: 600;
    font-size: 0.625rem;
    white-space: nowrap;
}

.tableFooter {
    color: #58698e;

    background-color: #f5f6f8;
}

.tableFooter .td {
    padding: 0.625rem 1.125rem 0.625rem 0.375rem;

    font-weight: 600;
    font-size: 0.75rem;

    border: 1px solid #e7eef6;
}

.tableFooter .td input {
    font-weight: inherit;
}

.tableToolBarContainer {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.tableToolBarItem {
    display: flex;
    align-items: baseline;
}

.row {
    color: #707683;
    font-size: 0.8rem;
    letter-spacing: 0.1px;

    cursor: pointer;
}

.exportContainer {
    display: flex;
    margin-left: 1rem;
    padding: 0 0.5rem;

    color: #6a707e;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.1px;
}

.export {
    margin-right: 0.6rem;
}

.rowsSelected {
    width: 5rem;
    padding-left: 0.5rem;

    color: #334d6e;
    font-size: 0.82rem;
    letter-spacing: 0.1px;
}

.addNewItemButton {
    color: #109cf1;
}

.deleteItemsButton {
    color: #f7685b;
}

.button {
    letter-spacing: 0.1px;
    text-decoration: none;

    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
}

.button:disabled {
    color: #334d6e;

    cursor: auto;
    opacity: 0.3;
}

.error {
    color: red;
    font-size: 0.8rem;
    text-align: center;
}

.hr {
    margin: 0 0 1px;

    opacity: 0.3;
}

.enabled {
    color: #2ed47a;
}

.disabled {
    color: #b0acacee;
}

.image {
    width: 3rem;
    object-fit: cover;

    border-radius: 50%;
}

.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;

    background-color: rgba(0, 0, 0, 0.4);
}

.popUp {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 20;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20rem;
    height: 8rem;
    padding: 1rem;

    background-color: white;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    margin-top: 1rem;
}

.deleteButton {
    width: 5rem;
    height: 2.6rem;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;

    background: #109cf1;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    box-shadow: 0 0.25rem 10px rgb(16 156 241 / 0.24);
    cursor: pointer;

    transition: transform 0.2s;
}

.deleteButton:hover {
    box-shadow: 0 0.25rem 10px rgb(16 156 241 / 0.12);
    transform: translate(3px);
}

.popupText {
    margin: 0.3rem;

    line-height: 1.1rem;
    text-align: center;
}

.alignCenter {
    text-align: center;
}

.editorCell {
    min-width: 5rem;

    background-color: #fdffef;
}

.negativeCell {
    background-color: rgba(247, 104, 91, 0.4)
}

.positiveCell {
    background-color: rgba(31, 182, 99, 0.4);
}

.groupSkeleton {
    width: 100%;
    height: 3.75rem;
}
