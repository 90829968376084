.modalContent {
    position: relative;

    box-sizing: border-box;
    width: 32rem;
    max-height: 65vh;
    padding-bottom: 0.25rem;
    overflow-y: auto;
}

.modalContent * {
    box-sizing: border-box;
}

.form > * + * {
    margin-top: 1.25rem;
}

.row {
    display: flex;
    flex-direction: row;
}

.row > * + * {
    margin: 0 0 0 1.875rem !important;
}

.evenFields > * {
    flex: 1;
}

.inline {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inline > * + * {
    margin: 0 0 0 0.2rem !important;
}

.genderLabel {
    display: inline-block;
    margin-bottom: 1rem;
}

.submit {
    flex: 1;
}

.discardChanges,
.discardChanges * {
    z-index: 1000;
}

.discardChangesContent {
    width: 31.25rem;
}

.deleteTargetingTemplate {
    text-align: center;
}

.audienceReach {
    margin-bottom: 1.25rem;
    padding: 0.5rem 0;
}

.audienceReach.stickToTop {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 100;

    padding: 0.5rem 2rem;

    background-color: white;
    box-shadow: 0 0.2rem 0.5rem #9faab930;
}
