.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.75rem;
}

.details > p {
    margin: 0;
}

.followerCount {
    color: var(--Text-Weak, #767676);
}

.image {
    width: 2rem;
    height: 2rem;

    border-radius: 50%;
}
