.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.group {
    justify-content: space-between;
    overflow-x: auto;

    color: white;

    background: linear-gradient(90.1deg, #152e4a 20.25%, #4b6583 102.04%);
}

.groupInfoColumns {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 0 0.625rem 1.25rem;
}

.groupInfoColumn {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 25%;
    min-width: 10rem;
    max-width: 12.5rem;
}

.groupInfoColumnHeading {
    font-weight: 500;
    font-size: 0.8125rem;
}

.groupInfoColumnValue {
    color: #dae3ee;
    font-weight: 400;
    font-size: 0.6875rem;
}

.groupName {
    box-sizing: border-box;
    width: 100%;
    padding: 0;
    padding-right: 0.3125rem;

    color: inherit;
    font-weight: 500;
    font-family: inherit;
    text-overflow: ellipsis;

    background: transparent;
    border: 1px solid transparent;
    outline: none;
}

.groupName[disabled],
.groupName[disabled]:hover,
.groupName[disabled]:focus {
    background-color: transparent;
    border: 1px solid transparent;
}

.groupName:hover,
.groupName:focus {
    background-color: rgba(255, 255, 255, 0.2);
}

.groupName:focus {
    border: 1px solid rgba(255, 255, 255, 0.5);
}

.amountSpent , .budget {
    font-weight: 600;
}

.percentSpentOfGroupBudget {
    margin-left: 0.5rem;

    font-weight: 400;
    white-space: nowrap;
}

.groupButton {
    padding: 0 1.25rem;

    color: #ffffff;
    font-weight: 600;
    font-size: 0.75rem;
    white-space: nowrap;

    background: transparent;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandButton {
    align-self: stretch;

    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-left: none;
}

.expandButton > svg {
    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(180deg);
}

.reorderingButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.reorderingButton {
    color: white;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.reorderingButton.increaseOrderIndexButton {
    transform: rotate(180deg);
}
