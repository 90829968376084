.postLink {
    color: var(--color-link);
    white-space: nowrap;
    text-decoration: none;

    cursor: pointer;
}

.postLink:hover {
    text-decoration: underline;
}
