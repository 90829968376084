.modal {
    min-width: 40rem;
}

.content {
    margin: 0;
}

.errorMessage {
    max-width: 100%;
    margin-top: 0.5rem;

    color: red;
    font-size: 0.7rem;
}
