.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: var(--6, 1.5rem) 0.5rem;
}

.field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.25rem;
    width: 100%;
}

.audioInputRow {
    display: flex;
    gap: var(--2);
    align-items: center;
}

.audioInput {
    flex: 1;
}

.changedLabelContainer {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.changedLabelContainer strong {
    font-weight: 500;
}


.undoButton {
    text-decoration: underline;
}
