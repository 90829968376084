.table {
    height: 100%;
    overflow-x: auto;

    background-color: #f5f6f8;
}

.headerContainer {
    width: 100%;

    background-color: #f5f6f8;
}

.itemInfoColumns {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 3rem;
}

.itemInfoColumn {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.875rem;
}

.itemInfoColumnValue {
    color: #90a0b7;
    font-size: 0.825rem;
}

.idColumn {
    max-width: 5%;
}

.itemColumn {
    flex-grow: 2.5;
}
