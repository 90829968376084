.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1.25rem;
    margin-top: 1.25rem;
}

.header {
    display: flex;
    justify-content: space-between;
}

.audioSelect {
    min-width: 300px;
}

.content, .centered {
    min-height: 300px;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.emailSectionActions {
    display: flex;
    align-items: stretch;

    column-gap: 0.5rem;
}

.emailConfigurationButton {
    padding: 0.5rem !important;

    color: #90a0b7;

    border-radius: 0.25rem !important;
}

.emailConfigurationButton:hover {
    color: #333333;

    background-color: #eef0f2;
}

.emailConfigurationButtonIcon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
}

.navItem {
    padding: 0.625rem 1.5rem;
}

.contactedCountBadge {
    margin-left: 0.25rem;
    padding: 0.125rem 0.25rem;

    color: white;
    font-weight: 600;
    font-size: 0.75rem;

    background-color: var(--color-primary);
    border-radius: 0.25rem;
}
