.rowSelectHint {
    padding: 0.4rem 0.2rem;

    font-size: 0.55rem;

    background-color: #f7f7f7;
    cursor: default;
}

.dropdownIcon {
    width: 0.5rem;

    color: #152e4a;

    visibility: hidden;
}

.container:hover .dropdownIcon {
    visibility: visible;
}
