.content {
    max-width: 80% !important;
}

.buttons {
    display: flex;
    flex-flow: row nowrap;

    column-gap: 0.75rem;
}

.results {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.failure {
    color: var(--color-danger);
}

.success {
    color: var(--color-success);
}
