.wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow-x: auto;

    color: white;

    background: linear-gradient(134deg, #2a5484 14.64%, #4a87cd 85.36%);
}

.wrapper.groupHidden {
    background: linear-gradient(134deg, #44586f 50.64%, #4e6a82 70.36%);
}

.infoColumnContainer {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0.975rem 0 0.975rem 1.25rem;

    border-right: 1px solid rgba(255, 255, 255, 0.1);

    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.infoColumn {
    display: flex;
    flex-direction: column;
    gap: 0.325rem;
    align-items: flex-start;
}

.heading {
    font-weight: 500;
    font-size: 0.6875rem;
}

.value {
    color: #dae3ee;
    font-weight: 400;
    font-size: 0.5875rem;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;

    background: transparent;
    border: none;

    cursor: pointer;
}

.expandButton {
    align-self: stretch;
    padding: 0 0.75rem;
}

.copyButton {
    padding: 0 1.25rem;
}

.expandButton > svg {
    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandButton.expanded > svg {
    transform: rotate(180deg);
}

.copyToTableDropdown {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
