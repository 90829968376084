.expandButton {
    width: 2rem;
    height: 3rem;

    color: var(--Icon-white, #fff);

    background: var(--Campaign-Status-Planned, #5af);
    border-radius: var(--rounded, 0.25rem);
}

.expandButton:hover {
    color: var(--Icon-white, #fff);

    background-color: color-mix(in sRGB, var(--Campaign-Status-Planned) 85%, var(--Icon-white) 15%);
}

.expandIconExpanded {
    transform: rotate(90deg);
}
