.overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 30;

    background-color: rgba(0, 0, 0, 0.4);
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 35;

    min-width: 26.875rem;
    padding: 1.875rem;

    background-color: white;
    transform: translate(-50%, -50%);
}

.closeIcon {
    position: absolute;
    top: 1.3125rem;
    right: 1.3125rem;

    cursor: pointer;

    fill: #90a0b7;
}

.title {
    margin: 0 0 1.875rem;

    color: #192a3e;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
}

.content {
    margin: 1.875rem 0;
}

.footer {
    display: flex;
    flex-direction: row;
    margin-top: 1.875rem;
}

.footer > * + * {
    margin-left: 0.5rem;
}

.footer.right {
    justify-content: flex-end;
}

.footer.center {
    justify-content: center;
}

.footer.left {
    justify-content: flex-start;
}
