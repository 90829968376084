.container {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    padding: 0.625rem 0.875rem;

    border: 1px solid transparent;
    border-color: var(--color-neutral-300);
    border-radius: 0.75rem;
}

.input {
    composes: font-sm from 'ui/utils.css';

    width: 100%;

    padding: 0;

    border: none;

    outline: none;
}

.input::placeholder {
    color: var(--color-grayscale-40);
}

.icon {
    color: var(--color-neutral-400);
}

.container:has(.input:not(:focus)):hover .icon {
    color: var(--color-grayscale-100);
}

.clearButton {
    align-items: center;
    justify-content: center;

    width: 1.25rem;
    height: 1.25rem;

    padding: 0;

    color: var(--color-neutral-500);

    background-color: transparent;

    border: none;

    visibility: hidden;
}

.clearButton.clearable {
    visibility: visible;
}
