.row {
    display: flex;
    align-items: center;
    width: 100%;
    height: 4rem;
    margin: 0;
}

.td {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 0.625rem;

    color: #58698e;
    font-size: 0.875rem;

    background-color: white;
    border: solid #e7eef6;
    border-width: 0.75px 1.5px;
}

.idColumn {
    max-width: 5%;
}

.idColumn a {
    color: #58698e;
    font-weight: 500;
    text-decoration: none;
}

.idColumn a:hover {
    text-decoration: underline;
}

.itemColumn {
    flex-grow: 2.5;
}

.influencerDetails {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-start;
}

.influencerName {
    color: black;
    font-weight: 400;
    text-decoration: none;
}

.influencerName:hover {
    text-decoration: underline;
}

.influencerFollowers {
    font-size: 0.85rem;
}

.influencerThumbnail {
    width: 2.25rem;
    height: 2.25rem;
    object-fit: cover;

    border-radius: 50%;
}

.spentColumn {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    align-items: stretch;
    justify-content: center;
}

.spentInfo {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    justify-content: space-between;
}

.spentValue {
    color: black;
}
