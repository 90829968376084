@keyframes slidein {
    from {
        right: -100vw;
    }

    to {
        right: 0;
    }
}

.Modal {
    position: fixed;
    top: 0;
    right: -100vw;

    width: 90%;
    height: 100vh;
    overflow-y: auto;

    background: #f5f6f8;
    outline: none;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.12);

    transition: right 500ms cubic-bezier(1, 1, 1, 1);
    animation-name: slidein;
    animation-duration: 0.5s;
}

@media only screen and (max-width: 768px) {
    .Modal {
        width: 100%;
    }
}

.showModal {
    right: 0;
}

.modalContent {
    min-width: 20rem;
}

@media only screen and (max-width: 1400px) {
    .modalContent {
        width: 100%;
        min-width: 0;
    }
}

@keyframes fadein {
    from {
        background-color: rgba(0, 0, 0, 0);
    }

    to {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.Overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;

    overflow: auto;

    background-color: rgba(0, 0, 0, 0);

    transition: all 300ms cubic-bezier(1, 1, 1, 1);
    animation-name: fadein;
    animation-duration: 0.5s;
}

.showOverlay {
    background-color: rgba(0, 0, 0, 0.4);
}
