.table td,
.table th {
    padding: 0.625rem;
}

.table th {
    font-size: 0.625rem;
}

.account {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountImage {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;

    border-radius: 50%;
}

.accountDetails {
    white-space: nowrap;
}

.accountDetails > * {
    margin: 0;
}

.accountNameRow {
    display: flex;
    flex-direction: row;
    gap: 0.375rem;
    align-items: center;
    margin: 0;
}

.accountNameRow > * {
    margin: 0;
}

.accountName {
    color: #323C47;
    font-weight: 500;
    font-size: 0.875rem;
}

.accountUsername {
    color: #58698E;
    font-weight: 400;
    font-size: 0.75rem;
    text-decoration: none;
}

.tagsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag, .badge {
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
    padding: 0.125rem 0.3125rem;

    font-weight: 500;
    font-size: 0.625rem;

    border-radius: 0.25rem;
}

.tag.categoryTag {
    background-color: #7A61DF;
}

.badge {
    background-color: var(--color-primary);
}

.location {
    display: flex;
    flex-direction: row;
    gap: 0.3125rem;
    align-items: center;
}

.flagIcon {
    min-width: 1rem;
    min-height: 1rem;
}
