.react-calendar {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: stretch;
    width: 22.5rem;
    height: max-content;

    padding: 2rem 1rem;

    font-family: Inter, sans-serif;

    background-color: #FFF;
    border: none;
    border-radius: 0.75rem;
}

    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;

    border: 0;
    outline: none;
}

.react-calendar__navigation {
    display: flex;
    height: 1.5rem;
    margin-bottom: 0;
}

.react-calendar__navigation__label__labelText{
    color: var(--color-grayscale-100, #111);
    font-weight: 600;
    font-size: 1rem;
    line-height: 150%;
}


.react-calendar__navigation__arrow {
    cursor: pointer;
}

.react-calendar__navigation button {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--color-neutral-500);

    background: none;
}

.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}

.react-calendar__month-view {
    display: flex;
    flex: 1;
    height: 100%;
}

.react-calendar__month-view > div {
    display: flex;
    flex: 1;
    align-items: stretch !important;
}

.react-calendar__month-view > div > div {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.react-calendar__month-view__weekdays {
    font-weight: 400;
    font-size: 0.75rem;
    text-transform: uppercase;
}

.react-calendar__month-view__weekdays__weekday {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    height: 2.625rem;
    padding: 0.5rem;

    color: var(--color-grayscale-20, #BDBDBD);
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
}

.react-calendar__month-view__days {
    row-gap: 0.25rem;

    height: 100%;
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--color-grayscale-20, #BDBDBD);
}

.react-calendar__tile {
    width: 2.25rem;
    height: 2.25rem;
    padding: 0;

    line-height: 1.25rem;
    text-align: center;

    background: none;
    cursor: pointer;
}

.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}

.react-calendar__tile--now {
    background: var(--color-neutral-100);
}


.react-calendar__tile--hover:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd) {
    background: var(--color-neutral-100);
}

.react-calendar__tile abbr {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.25rem;
    height: 2.25rem;

    font-size: 0.875rem;

    border-radius: 50%;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd,
.react-calendar__tile--hoverStart,
.react-calendar__tile--hoverEnd {
    color: white;
}

.react-calendar__tile--rangeStart abbr,
.react-calendar__tile--hoverStart abbr,
.react-calendar__tile--rangeEnd abbr,
.react-calendar__tile--hoverEnd abbr {
    background: var(--color-grayscale-100, #111) !important;
}

.react-calendar__tile--rangeStart {
    background: linear-gradient(90deg, transparent 50%, var(--color-neutral-100) 50%);
}

.react-calendar__tile--rangeEnd {
    background: linear-gradient(90deg, var(--color-neutral-100) 50%, transparent 50%);
}

/* react-calendar uses the direction of the partial range selection to determine hover start and end, rather than the date order.
This means that if you select a range from the 20th to the 10th, the 20th will be the hover start and the 10th will be the hover end.
This means we need to use the sibling selector to appropriately apply half backgrounds to the start and end tiles. */
.react-calendar__tile--hoverStart ~ .react-calendar__tile--hoverEnd {
    background: linear-gradient(90deg, var(--color-neutral-100) 50%, transparent 50%);
}

.react-calendar__tile--hoverStart:not(.react-calendar__tile--hoverStart ~ .react-calendar__tile--hoverEnd) {
    background: linear-gradient(90deg, transparent 50%, var(--color-neutral-100) 50%);
}

.react-calendar__tile--hoverEnd:not(.react-calendar__tile--hoverEnd ~ .react-calendar__tile--hoverStart) {
    background: linear-gradient(90deg, var(--color-neutral-100) 50%, transparent 50%);
}

/* if the range selection is incomplete, and only the hoverStart is hovered, we need to remove the half background   */
.react-calendar__tile--hoverBothEnds {
    background: transparent !important;
}

/* if the range selection is incomplete, but there is no active hover range (e.g. cursor is outside the calendar), we need to remove the half background */
.react-calendar__tile--rangeBothEnds:not(.react-calendar__tile--hoverStart):not(.react-calendar__tile--hoverEnd) {
    background: transparent !important;
}

.react-calendar__tile--active:not(.react-calendar__tile--rangeStart),
.react-calendar__tile--hover abbr {
    background: var(--color-neutral-100);
}

.react-calendar__tile:not(.react-calendar__tile--rangeStart):not(.react-calendar__tile--rangeEnd):not(.react-calendar__tile--active):hover abbr {
    background-color: var(--color-grayscale-10);
}
