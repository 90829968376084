.form {
    min-width: 40vw;
}

.form > * + * {
    margin-top: 1.25rem;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.row > * {
    flex: 1;
}

.row > * + * {
    margin-top: 0 !important;
    margin-left: 1.25rem;
}

.objectiveDescription {
    min-height: 8.125rem !important;
}

.dateInput {
    display: block;
    width: 100%;
    padding: 0.625rem 1rem;

    font-size: 0.75rem;
    text-align: left;

    border: 1px solid #c2cfe0;
    border-radius: 0.1875rem;
}

.errorMessage {
    max-width: 100%;
    margin-top: 0.5rem;

    color: red;
    font-size: 0.7rem;
}

.defaultRenderActions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.875rem;
}

.defaultSubmitButton {
    flex: 1;
}
