.cell {
    overflow-x: hidden;

    white-space: nowrap;
}

.cell p {
    margin: 0;
    overflow-x: hidden;

    text-overflow: ellipsis;
}

.subValue {
    color: var(--Text-Weak, #767676);
}
