.modal {
    min-width: 25rem;
}

.form > * + * {
    margin-top: 1rem;
}

.briefField {
    min-height: 8.125rem;
}

.errorBanner {
    margin: 1rem 0;
}
