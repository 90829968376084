.table tr td {
    height: 5rem;
}

.table tr:has(td[rowspan]) td:not([rowspan]) {
    height: 2.5rem;
}

.table td[rowspan="0"] {
    display: none;
}

.table a {
    text-decoration: none;
}

.table a:hover {
    text-decoration: underline;
}

.toApprove,
.contacted,
.team,
.liveDate  {
    color: var(--color-grayscale-50);
}

.accountContainer {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.75rem;
}

.brandImage {
    width: 2.875rem;
    height: 2.875rem;

    border-radius: 50%;
}

.titles {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
}

.title,
.subTitle {
    margin: 0;
}

.title {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
}

.subTitle {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-grayscale-40);
}

.table .editButton {
    padding: 0;

    color: var(--color-neutral-400);

    background: none;
    border: 0;
    cursor: pointer;
}

.table .editButton[disabled] {
    color: var(--color-neutral-200);

    cursor: not-allowed;
}
