@layer base {
    .container {
        position: relative;

        width: 1.125rem;
        height: 1.125rem;

        color: inherit;

        opacity: 1;

        transition: opacity linear 0.1s;
    }
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;

    min-width: 100%;
    min-height: 100%;

    border: solid 3px #eee;
    border-bottom-color: inherit;
    border-radius: 50%;
    transform: translate3d(-50%, -50%, 0);
    transform-origin: center;
    opacity: inherit;


    /**
     * animation duration and delay are set in component
     */
    animation: linear infinite spinner;
    animation-name: rotateClockwise;
    animation-timing-function: linear;
    animation-iteration-count: infinite;

    content: "";
    will-change: transform;
    aspect-ratio: 1/1;
}

@keyframes rotateClockwise {
    0% { transform: translate3d(-50%, -50%, 0) rotate(0deg); }

    100% { transform: translate3d(-50%, -50%, 0) rotate(360deg); }
}
