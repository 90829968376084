.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}


.image {
    width: 1.5rem;
    height: 1.5rem;

    border-radius: var(--rounded-lg, 0.5rem);
}

.container.column .image {
    width: 2.25rem;
    height: 2.25rem;
}

.container.row .names {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.title {
    composes: p-bold from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-body, #555)
}

.artistName {
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    color: var(--Text-Weakest, #B0B0B0)
}
