.box {
    width: 100%;
    height: 2.6rem;
    padding-left: 1rem;

    font-size: 0.8rem;
    letter-spacing: 0.01em;

    border: none;
    outline: none;
}

@media (max-width: 768px) {
    .box {
        padding-left: 0.5rem;
    }
}
