.modal {
    min-width: 40rem;
}

.content {
    margin: 0;
}

.submitButton {
    width: 100%;
}
