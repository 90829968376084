.container {
    position: relative;
    z-index: 2;

    color: white;

    --base-width: 4rem;
    --expanded-width: 11.75rem;
}

.spine {
    width: var(--base-width);
}

.navContainer {
    display: flex;
    flex-direction: column;
    gap: 3.125rem;

    width: var(--base-width);
    height: 100%;
    padding: 1.5rem 25%;
    overflow: hidden;

    white-space: nowrap;

    background-color: #000000;
}

.navContainer.isExpanded {
    position: absolute;
    top: 0;
    left: 0;

    width: var(--expanded-width);
}

.logoContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.logoContainer hr {
    width: 100%;
    height: 1px;

    background-color: var(--Icon-white, #fff);
    border: none;
}

.logoInline {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.roundLogo {
    box-sizing: content-box;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 0.5rem;
}

.sonyLogo {
    box-sizing: content-box;
    width: 2.5rem;
    height: 2.5rem;
    margin-left: -0.25rem;

    color: white;
}

.navList, .staticList {
    display: flex;
    flex-direction: column;
    min-width: 2rem;
    margin: 0;
    padding: 0;

    list-style: none;
}

.navList {
    flex: 1;
}

.navContainer .navList, .staticList {
    gap: 1.5rem;
}

.leaf .navList {
    gap: 0.5rem;
}

.navItem {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 100%;
    height: 2rem;
    padding: 0.5rem;

    font-weight: 600;
    font-size: 0.75rem;

    border-radius: 0.1875rem;
    cursor: pointer;
}

.container a {
    color: inherit;
    text-decoration: none;
}

.navItem:hover,
.navItem.active {
    background-color: #ffffff1a;
}

.navIcon {
    width: 1rem;
    height: 1rem;
}

.navTitle {
    flex: 1;
}

.arrowIcon {
    width: 1rem;
    height: 1rem;

    color: #4b4b4b;
}

.leaf {
    position: absolute;
    top: 0;
    left: var(--expanded-width);

    width: var(--expanded-width);

    height: 100%;
    padding: 3rem 2rem;

    background-color: #000000cc;
}

.logout {
    margin-top: auto;
}
