.container {
    box-sizing: border-box;
    padding: 1.875rem;

    background-color: #FBFBFD;
    border-radius: 0.625rem;
}

.chart {
    margin-top: 2.125rem;
}

.tooltip {
    position: fixed;
    z-index: 20;

    padding: 0.7rem;

    color: white;
    font-size: 0.8rem;

    background-color: rgb(47, 57, 95);
    border-radius: 0.2rem;
}

.tooltipMetrics,
.tooltipFooter {
    margin: 0;
}

.tooltipFooter {
    word-break: break-word;
}
