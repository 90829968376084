.page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
}

.content {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding: 3.5rem 2.5rem;

    color: #192a3e;
    text-align: left;

    background-color: #e5e5e5;
}

.table {
    width: 100%;

    background-color: white;
    border-collapse: collapse;
}

.columnHeaderRow {
    height: 3rem;

    color: #334d6e;
    font-size: 0.7rem;
    letter-spacing: 0.2px;
    text-align: left;

    opacity: 0.5;
}

.columnHeaderRow th {
    font-weight: 500;
}

.cell {
    padding: 1rem 2rem;
}
