.container {
    position: relative;

    min-height: 150px;

    background-color: #f5f6f8;
}

.container,
.container * {
    box-sizing: border-box;
}

.labelsContainer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    height: 100%;
    padding: 0.625rem;

    color: rgba(51, 77, 110, 0.5);
}

.container.hasPreview .labelsContainer {
    visibility: hidden;
}

.container.labelsVisible .labelsContainer,
.container.hasPreview:hover .labelsContainer {
    color: white;

    background-color: rgba(0, 0, 0, 0.4);
    visibility: visible;
}

.label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0.625rem;

    font-size: 0.75rem;

    border: 1px dashed;
    cursor: pointer;
}

.labelIcon {
    width: 2rem;
    height: 2rem;
    margin-bottom: 0.625rem;
}

.spinner {
    color: rgba(51, 77, 110, 0.5);
}
