.button {
    display: flex;
    align-items: center;
    justify-content: center;

    color: var(--color-neutral-400);

    background-color: transparent;
    border: none;

    cursor: pointer;
}

.button:disabled {
    cursor: not-allowed;
}

.button:not(:disabled):hover,
.button:not(:disabled):active {
    color: var(--color-grayscale-100);
}

.button svg,
.button:disabled svg{
    fill: var(--color-neutral-400);
}

.button:hover:not(:disabled) svg {
    fill: var(--color-grayscale-100);
}

.hint {
    composes: font-xs from 'ui/utils.css';

    padding: 0.75rem;

    color: white;

    background-color: var(--color-grayscale-100);
    border-radius: 0.75rem;
}

.arrow {
    position: absolute;
    bottom: 0;
    left: 50%;

    border-top: 0.3125rem solid var(--color-grayscale-100);
    border-right: 0.3125rem solid transparent;
    border-left: 0.3125rem solid transparent;
    transform: translate(-50%, 100%);
}
