.header {
    padding: 0;

    background: linear-gradient(90deg, #2C3E50 0%, #3498DB 100%);
}

.goBackContainer {
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;

    background-color: rgba(0, 0, 0, 0.05);
}

.goBackButton {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: inline-flex;
    gap: 0.125rem;
    align-items: center;

    color: white;
    text-decoration: none;
}

.goBackButton:active,
.goBackButton:visited,
.goBackButton:focus {
    color: white;
}

.goBackButtonIcon {
    color: white;

    transform: rotate(90deg);
}

.projectInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 1.5rem;

    color: var(--text-white);
    column-gap: 0.625rem;
    row-gap: 1.25rem;
}

@media screen and (min-width: 700px) {
    .projectInfo {
        row-gap: 2.375rem;
    }
}

.projectNameInputContainer {
    composes: heading-large from 'ui-new/whitelabel/utils/utils.css';

    margin-left: -0.625rem;

    padding: 0.125rem 0.5rem;
}

.projectSubTitle {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    width: max-content;
    min-width: 7.5rem;

    margin: 0;
}

.brandImage {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    width: 4rem;
    height: 4rem;

    border-radius: 0.25rem;
}

@media screen and (min-width: 700px) {
    .brandImage {
        grid-row: 1 / span 2;

        width: 8.75rem;
        height: 8.75rem;
    }
}

.projectDetails {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
}

.statItem.hidden {
    visibility: hidden;
}

@media screen and (min-width: 1200px) {
    /*ensure the chart is pushed to the right edge */
    .songStats {
        margin-left: auto;
    }
}

.teamContainer {
    display: flex;
    align-items: center;
}

.userBadge {
    width: 2rem;
    height: 2rem;

    color: var(--Text-black, #000);

    background-color: var(--Surface-White, #FFF);
    border-color: var(--Borders-Grey---Light, #D1D1D1);
}

.userBadge + .userBadge {
    margin-left: -0.75rem;
}

.songStatsContainer {
    display: flex;
    gap: 0.5rem;
    align-items: flex-end;
}

.songChartContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    max-width: min-content;
    padding: 0.5rem;

    background: var(--Alpha-White-75, rgba(255, 255, 255, 0.10));
    border: 1px solid var(--Borders-Disabled, rgba(0, 0, 0, 0.05));

    border-radius: var(--Corner-Radius-Table-cell, 0.1875rem);
}

.songIcon {
    width: 1rem;
    height: 1rem;

    color: var(--Icon-white);
}

.chartContainer {
    position: relative;

    width: 5.625rem;
    max-height: 1.875rem;
}

.chartTooltipStats {
    margin: 0;
    padding: 0;

    color: var(--text-white);

    white-space: nowrap;
}

.chartTooltipStatsItem {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.tabsNav {
    margin-top: 1.5rem;

    padding: 0 1.5rem;
}

.pointer {
    cursor: pointer;
}
