.error {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';

    height: 1rem;
    margin: 0 0 -1rem;

    color: red;

    visibility: hidden;
}

.error.visible {
    visibility: visible;
}
