.dateField {
    width: calc(100% - 15px);
    padding-left: 10px;

    color: rgb(51, 51, 51);
    font-size: 1rem;
}

.dateInput {
    min-width: 5rem;

    color: #707683;

    background-color: transparent;
    border: none;
    border-radius: 3px;

    transition: background-color 0.2s ease-in-out;
}

.dateInput:hover {
    background-color: rgba(100, 100, 100, 0.2);
    cursor: pointer;
}
