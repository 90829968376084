.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1.25rem;
}

.identifierField input::placeholder {
    font-size: 0.8125rem;
}

.switchUserInputButton {
    align-self: flex-start;
    padding: 0.25rem;

    color: var(--color-grayscale-50);
    font-weight: 500;
    font-size: 0.75rem;

    background-color: transparent;
}

.switchUserInputButton:hover {
    color: var(--color-grayscale-100);
    text-decoration: underline;

    background-color: transparent;
    text-underline-offset: 0.25rem;
}
