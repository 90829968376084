.table td {
    min-width: 8.75rem;
    max-width: 15rem;
}

.postLink {
    color: #3451FF;
    text-decoration: none;
}

.paid {
    color: #00D02E;
}

.inProgress {
    color: #FFB800;
}

.failed {
    color: #FF5050;
}
