.container {
    display: flex;
    width: 12rem;
}

.input {
    flex: 1;
    min-width: 0;

    border: none;
    outline: none;
}
