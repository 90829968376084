.container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badges {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    min-width: 7.5rem;
    margin-bottom: -0.325rem;
}

.badges > * {
    box-sizing: border-box;
    width: 3.125rem;
    min-height: 1rem;
    margin-right: 0.325rem;
    margin-bottom: 0.325rem;
}

.badges > *:last-child {
    margin-right: 0;
}

.createButton {
    padding: 0;
}
