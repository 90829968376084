.badge {
    --height: 1rem;
    composes: font-xs from 'ui/utils.css';

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 0.75rem;

    height: var(--height);
    padding: 0 0.25rem;

    color: white;

    background-color: var(--color-brand-secondary);
    /* ensures initial width creates a circle and element grows to a pill */
    border-radius: calc(var(--height) / 2)
}

.spinner {
    width: 0.75rem;
    height: 0.75rem;

    color: var(--color-brand-secondary);
}
