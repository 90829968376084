.navBar {
	display: flex;
	height: 3.5rem;
	padding: 0 1rem;

	background-color: #292D32;
	box-shadow: 6px 0 18px rgba(0, 0, 0, 0.06);
}

@media screen and (min-width: 700px) {
	.navBar {
		padding: 0 3rem;
	}
}

.navBarSection {
	display: flex;
	align-items: center;
	height: 100%;
}

.stretchSection {
	flex-grow: 1;
}

.logoContainer {
	width: 100px;
	height: 100%;
}

@media screen and (min-width: 700px) {
	.logoContainer {
		width: 120px;
	}
}

.logo {
	height: 100%;
}

.links {
	display: flex;
	flex-direction: row;
	height: 100%;
}

.menuItems {
	position: relative;
	z-index: 12;

	display: none;
	flex-direction: column;
	align-items: center;
	width: 250px;
	margin-top: 1.2rem;
	padding: 10px;

	background-color: #393E44;
	border-radius: 0 0 10px 10px;
}

.menuGroup {
	display: flex;
	flex-direction: column;
	width: 150px;

	font-size: 0.82rem;

	cursor: pointer;
}

.menuGroup:hover {
	background-color: #393E44;
}

.menuGroup:hover .menuItems {
	display: flex;
}

.menuHeading {
	display: flex;
	flex-direction: column;
	height: 3.5rem;
	margin: 0 2rem;

	color: #334d6e;
}

.menuHeadingText {
	margin-top: 1.2rem;

	color: #FFFFFF;
	font-weight: 500;
	font-size: 12px;
	text-align: center;
}

.menuItemContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
}

.menuItem {
	padding: 0.5rem 1.5rem;

	color: #FFF;
	font-size: 12px;
	text-decoration: none;

	border-radius: 10px;
	cursor: pointer;
}

.menuItem:hover {
	background-color: #4B525A;
}

.logout, .whitelabelLink {
	display: flex;
	align-items: center;
	padding: 0.25rem 0.5rem;

	color: white;
	font-weight: 500;
	font-size: 12px;
	text-decoration: none;

	background: transparent;
	border-radius: 0.25rem;

	cursor: pointer;
}

.logoutIcon {
	width: 0.875rem;
	height: 0.875rem;
	margin-left: 10px;
}

.whitelabelLink {
	margin-right: 1.25rem;

	border: 2px solid white;
}

.whitelabelLink:hover, .logout:hover {
	color: black;

	background: #f0f0f0;
}
