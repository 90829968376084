.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.valueLabel {
    display: inline-flex;
    gap: 0.25rem;
    align-items: center;

    height: 1.125rem;
    padding: 0 0.5rem;

    color: #FFF;
    font-weight: 500;
    font-size: 0.875rem;

    background: #000;
    border-radius: 1.25rem;
}

.input {
    width: 9.25rem;
    height: 2.5rem;
    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300, #D1D5DB);
    border-radius: 0.75rem;
}

.input.error {
    border-color: var(--color-status-fail);
}
