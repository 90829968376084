.groupsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin-top: 0.625rem;
}

.groupSkeleton {
    width: 100%;
    height: 3.5rem;
}
