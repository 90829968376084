.container {
    height: 100%;

    padding: 0.5rem 0;
}

.actions {
    padding: 0.625rem 1.25rem;
}

.goBackButton {
    padding: 0;

    transform: rotate(180deg);
}

.icon {
    width: 20px;
    height: 20px;
}
