.navItems {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: stretch;
    padding: 1rem 1.25rem;

    border-bottom: 1px solid var(--color-neutral-200);
}

.navItem {
    padding: 0.5rem 0.625rem;

    color: #111111;
    font-weight: 400;
    font-size: 0.875rem;

    border-radius: 0.75rem;
}

.activeNavItem {
    color: white !important;

    background-color: #000;
}

.activeNavItem:hover {
    background-color: #000;
}
