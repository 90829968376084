.container {
    display: flex;

    flex: 1;
    flex-direction: row;
    align-items: center;

    column-gap: 1rem;
}

.filters {
    composes: hidden-scroll from 'ui/utils.css';

    display: flex;

    flex: 1;
    flex-direction: row-reverse;
    align-items: center;
    width: 0;

    overflow-x: auto;

    column-gap: 1rem;
}
