.container {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    align-items: center;
    padding: 0 0.75rem 0 0.5rem;

    color: var(--color-grayscale-100);
    text-decoration: none;

    border-radius: 3.0625rem;
}

.container:hover {
    text-decoration: underline;
}

.tiktok {
    background-color: #E2E4EB;
}

.instagram,
.youtube {
    background-color: #FFECEC;
}

.icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.25rem;
}

.image {
    width: 2rem;
    height: 2rem;
    margin-right: 0.375rem;

    border: 2px solid var(--White, #FFF);
    border-radius: 6.25rem;
}
