.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.625rem 1.25rem;

    column-gap: 1rem;
}

.title {
    composes: font-semi-xl from 'ui/utils.css';

    margin: 0;

    color: var(--color-grayscale-100);
}

.actions {
    display: flex;

    flex: 1;
    flex-direction: row;
    align-items: center;

    column-gap: 1.625rem;
}
