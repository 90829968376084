.container {
    box-sizing: border-box;
    padding: 1.25rem 1.875rem;

    background-color: #FBFBFD;
    border-radius: 0.625rem;
}

.container > * + * {
    margin-top: 0.625rem;
}

.header {
    display: flex;
}

.header > * + * {
    margin-left: 0.625rem;
}

.metricsSelect {
    display: flex;
    align-items: stretch;
}

.metricsSelectIndicator {
    width: 3rem;
}

.metricsSelectIndicator.line {
    background-color: #FFA114;
}

.metricsSelectIndicator.bar {
    background-color: #7A61DF;
}
