.container {
    margin: 2rem;
    padding: 2.5rem 2.25rem;
}

.title {
    margin: 0;

    font-size: 1.15rem;
}

.filterOptionsContainer {
    margin-top: 1.25rem;
    margin-bottom: 2rem;
}

.filterOptionsContainer > * + * {
    margin-top: 0.5rem;
}

.filterOptions {
    display: flex;
    gap: 2rem;
    align-items: stretch;
}

.filterOptions.left {
    flex: 1;
}

.multiFilter {
    min-width: 25%;
}

.search {
    width: 25%;

    font-size: 0.8125rem;

    border: 1px solid #c2cfe0;
    border-radius: 5px;
}

.searchInput::placeholder {
    color: #90A0B7;
}

.excludeWithoutPlanItemsFilter {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: #707683;
    font-size: 0.8125rem;
}

.excludeWithoutPlanItemsFilter > * + * {
    margin-left: 0.5rem;
}

.dateRangeFilter {
    display: flex;
    align-items: center;
    justify-self: flex-end;

    color: #707683;
    font-size: 0.75rem;
}

.dateInput {
    width: 5.3125rem;
    margin: 0 0.375rem;
    padding: 0.3125rem 0.625rem;

    font-size: 0.75rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}


.paginationOptionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;
}

.pageSelectContainer {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    color: var(--color-grey-3);
}

.paginationArrowButtonContainer {
    display: flex;
    gap: 0.625rem;
    align-items: center;
    justify-content: center;

    color: var(--color-grey-3);
}

.paginationArrowButton {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 2rem;
    height: 2rem;

    border: 1px solid #ebeff2;
    border-radius: 0.3rem;
    cursor: pointer;
    opacity: 0.7;
}

.paginationArrowButton:hover {
    color: #098edf;

    border-color: #098edf;

    fill: #098edf;
}

.paginationButtonDisabled {
    background-color: #919eab;
    border-color: #919eab;
}

.paginationButtonDisabled:hover {
    border-color: #919eab;
}

.paginationIcon {
    margin: 0 0.5rem;

    cursor: pointer;
}

.paginationIconDisabled {
    cursor: not-allowed;

    fill: #c4cdd5;
}

.table {
    margin-top: 1.25rem;
}

.groupSkeleton {
    width: 100%;
    height: 3.75rem;
    margin-top: 0.625rem;
}
