.badge {
    font-weight: 500;
}

.pending {
    color: rgb(62, 49, 29);

    background-color: rgb(255, 224, 141);
}

.needs_to_chase {
    color: rgba(255, 255, 255, 0.9);

    background-color: rgb(159, 0, 5);
}

.needs_to_be_invoiced {
    color: white;

    background-color: rgb(244, 99, 10);
}

.submitted_to_uniport {
    color: rgb(110, 54, 17);

    background-color: rgb(254, 187, 154);
}

.invoiced {
    color: rgb(21, 98, 58);

    background-color: rgb(204, 235, 175);
}

.other {
    color: rgb(14, 14, 14);

    background-color: rgb(226, 229, 233);
}
