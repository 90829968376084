.container {
    display: flex;
    flex: 1;
    justify-content: space-between;

    column-gap: 1rem;
}

.search {
    width: 100%;
    max-width: 18.75rem;
}

.filterIcon {
    width: 1.25rem;
    height: 1.25rem;

    color: var(--color-neutral-400);
}
