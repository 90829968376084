.spacing {
    --1: 0.25rem;
    --2: 0.5rem;
    --3: 0.75rem;
    --4: 1rem;
    --6: 1.5rem;
    --10: 2.5rem;
    --15: 0.375rem;

    --Spacing-Spacing-px: 0.0625rem;
    --Spacing-Spacing-05: 0.125rem;
    --Spacing-Spacing-1: 0.25rem;
    --Spacing-Spacing-2: 0.5rem;
    --Spacing-Spacing-25: 0.625rem;
    --Spacing-Spacing-35: 0.875rem;
}
