.container {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';

    height: 100vh;
    overflow: auto;

    background-color: white;
}

.container *::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;

    background-color: transparent;
}

.container *::-webkit-scrollbar-thumb {
    background: #D1D5DB;
    border-radius: 0.5rem;
}
