.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.expandButton {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 3rem;

    color: var(--Icon-white, #fff);

    background: var(--Campaign-Status-Planned, #5af);
    border-radius: var(--rounded, 0.25rem);
}


.expandButton:hover {
    color: var(--Icon-white, #fff);

    background-color: color-mix(in sRGB, var(--Campaign-Status-Planned) 85%, var(--Icon-white) 15%);
}

.expandIconExpanded {
    transform: rotate(90deg);
}

.dragHandle {
    position: absolute;
    left: 1.75rem;

    display: none;
    gap: 0.625rem;
    align-items: flex-start;
    padding: 0.1rem;

    background: #FFF;
    border: var(--Spacing-Spacing-px, 1px) solid #DCDCDC;
    border-radius: var(--Corner-Radius-Table-cell, 0.1875rem);
}

.dragIcon {
    width: 1rem;
    height: 1rem;

    color: var(--icon-base);
}

.mobileDragHandle {
    display: flex;

    color: var(--icon-base);
}

@media screen and (min-width: 768px) {
    .mobileDragHandle {
        display: none;
    }

    tr:has(.dragHandle):not(:has(.isOtherDragging)):hover .dragHandle,
    .dragHandle.isDragging {
        display: inline-flex;
    }
}
