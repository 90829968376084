.tableContainer {
    overflow-x: auto;
}

.table {
    min-width: 100%;

    text-align: left;

    border-collapse: collapse;
}

.headerCell,
.bodyCell {
    padding: 1rem;
}

.headerCell {
    color: rgba(51, 77, 110, 0.5);
    font-weight: 500;
    font-size: 0.7rem;
}

.bodyCell {
    min-width: 6.5rem;

    color: #707683;
    font-size: 0.75rem;
}

.headerRow {
    border-bottom: 1px solid #ebeff2;
}

.tableRow:hover {
    background-color: #f2f9ff;
}

.tableRow.clickable {
    cursor: pointer;
}

.tableRow + .tableRow {
    border-top: 1px solid #ebeff2;
}

.tableRow.loading {
    cursor: wait;
    opacity: 0.5;
}

.noDataLabel {
    box-sizing: border-box;
    width: 100%;
    padding: 2rem;

    color: #ccc;
    text-align: center;
}

.tableFoot {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    padding: 0.9rem 0.5rem;

    color: #098edf;
    font-weight: 500;
    font-size: 0.75rem;

    border-top: 1px solid #ebeff2;
    opacity: 0.7;

    fill: #098edf;
}

.paginationIcon {
    margin: 0 0.5rem;

    cursor: pointer;
}

.paginationIconDisabled {
    cursor: not-allowed;

    fill: #707683;
}

.sortingIcon {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 1rem;

    color: #6A707E;

    opacity: 0.3;
}

.sortingIconActive {
    opacity: 1;
}
