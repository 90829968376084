@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('./ui/Calendar.css');

:root {
    --color-brand-primary: #FE002B;
    --color-brand-secondary: #111111;

    --color-grayscale-100: #111111;
    --color-grayscale-90: #242424;
    --color-grayscale-80: #373737;
    --color-grayscale-70: #4B4B4B;
    --color-grayscale-60: #606060;
    --color-grayscale-50: #767676;
    --color-grayscale-40: #8D8D8D;
    --color-grayscale-30: #A5A5A5;
    --color-grayscale-20: #BDBDBD;
    --color-grayscale-10: #D6D6D6;

    --color-neutral-500: #6B7280;
    --color-neutral-400: #9CA3AF;
    --color-neutral-300: #D1D5DB;
    --color-neutral-200: #E5E7EB;
    --color-neutral-100: #F3F4F6;
    --color-neutral-50: #F9FAFB;

    --color-status-success: #00D02E;
    --color-status-fail: #FF5050;
    --color-status-warning: #FFB800;

    --color-link: #3451FF;
}

html {
	overflow-x: hidden;
}

body {
	margin: 0;
	overflow-x: hidden;

	font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	margin: 0;

	-webkit-appearance: none;
}

input[type="number"] {
	-moz-appearance:textfield;
}
