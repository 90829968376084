.tableWrapper {
    width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    min-width: 800px;

    text-align: left;

    border-collapse: collapse;
}

.table thead {
    width: 100%;

    background: #d5d5d5;
    border-radius: 4px 4px 0 0;
}

.table thead th {
    height: 1.625rem;
    padding: 0 0.375rem;

    color: #323c47;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 1.625rem;
    letter-spacing: 0.006rem;
    white-space: nowrap;
    text-transform: uppercase;

    background-color: #f5f6f8;

    border-bottom: 1px solid #ebeff2;
    border-left: 1px solid #ebeff2;
}

.table thead th:first-child {
    border-left: none;
    border-top-left-radius: 4px;
}

.table thead th:last-child {
    border-top-right-radius: 4px;
}

.table tbody td {
    min-width: 7rem;
    height: 1.875rem;
    padding: 0 0.375rem;

    color: #4c5862;
    font-weight: 400;
    font-size: 0.8rem;
    line-height: 1.875rem;

    border: 1px solid #ebeff2;
}

.table .creativeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.table .targetingContainer {
    max-width: 12rem;
}

/* [BP-20]: This is a workaround to make the targeting popover appear above the modal */
div:has(.targetingPopover) {
    position: relative;
    z-index: 15;
}

.table .creativeContainer > * + * {
    margin-left: 0.3125rem;
}

.amountSpentContainer {
    min-width: 7rem;
    padding: 0 0.625rem;
}

.amountSpent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    color: #192a3e;
    font-weight: 600;
}

.amountSpentPercentage {
    color: #90a0b7;
    font-weight: 400;
}

.sortingIcon {
    width: 0.5rem;
    height: 0.5rem;
    margin-left: 0.75rem;

    color: #6A707E;

    opacity: 0.3;
}

.alwaysShownCell {
    width: 12rem;
    min-width: 12rem;
}

.countBadge {
    background-color: #90a0b7;
}
