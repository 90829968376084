.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    column-gap: 0.5rem;
}

.image {
    width: 2.5rem;
    height: 2.5rem;

    border-radius: 50%;
}

.titles {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
}

.title,
.subTitle {
    margin: 0;
}

.title {
    composes: font-sm from '../../../../../ui/utils.css';

    color: var(--color-grayscale-100);
}

.subTitle {
    composes: font-xs from '../../../../../ui/utils.css';

    color: var(--color-grayscale-40);
}
