.overlay {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
}

.target {
    cursor: pointer;
}

@layer base {
    .menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        margin: 0;
        padding: 0.5rem;

        background: var(--Surface-White, #FFF);
        border: 1px solid var(--Borders-Grey---Extra-light, #E7E7E7);
        border-radius: 0.125rem;
    }

    .item {
        composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

        display: flex;
        gap: 0.5rem;
        align-items: center;
        width: 100%;
        padding: 0.5rem;

        color: var(--Text-Base, #242424);

        /* reset default button styles */
        background: none;
        border: none;
        box-shadow: none;
        cursor: pointer;
    }

    .item:hover, .item:focus {
        background-color: var(--Button-Ghost-hover, #00000008);
    }

    .icon {
        color: var(--Icon-base, #4B4B4B);
    }

    .separator {
        width: 100%;
        min-height: 1px;
        margin: 0.5rem 0;
        padding: 0;

        background: var(--Borders-Grey---Light, #D1D1D1);
        border: none;
    }
}
