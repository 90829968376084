.content {
    padding: 2rem 1.5rem 1.5rem;
}

@media only screen and (max-width: 1400px) {
    .content {
        padding: 20px;
    }
}

.headingBar {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.closeIcon {
    position: absolute;
    top: 2rem;
    right: 2rem;

    cursor: pointer;
    opacity: 0.3;

    transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 1400px) {
    .closeIcon {
        top: 20px;
        right: 20px;
    }
}

.closeIcon path {
    fill: #192a3e;
}

.closeIcon:hover {
    opacity: 1;
}

.headingBarContent {
    display: flex;
    align-items: flex-start;
}

.headingBar a {
    color: inherit;
}

.contentContainer {
    margin: 1rem 0;
    padding: 1rem;

    background-color: white;
    border-radius: 4px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06);
}

@media only screen and (max-width: 1400px) {
    .contentContainer {
        width: 100%;
        padding: 0;
    }
}

.hashtagImage {
    width: 6.25rem;
    height: 6.25rem;
    margin-right: 1.5rem;
    object-fit: cover;

    border-radius: 50%;
}

@media only screen and (max-width: 767px) {
    .hashtagImage {
        margin: 0 0 20px;
    }
}

.hashtagTitle {
    padding-right: 3.5rem;

    color: #192a3e;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.1px;
}

.description {
    margin: 0.5rem 0 0;

    color: #4c5862;
    font-size: 14px;
}

.greyHeadingInline {
    padding: 0 1.5rem;
}

.greyHeading {
    display: flex;
    align-items: flex-end;
    padding-top: 5px;

    color: #192a3e;
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.1px;

    opacity: 0.5;
}

@media only screen and (max-width: 767px) {
    .headingBar {
        flex-direction: column;
    }

    .headingBarContent {
        flex-direction: column;
        justify-content: center;

        text-align: center;
    }

    .greyHeading {
        display: block;
    }

    .greyHeadingInline {
        padding: 0;
    }

    .hashtagTitle {
        padding: 0;
    }
}
