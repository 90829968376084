.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
}

.field {
    width: 100%;
}

@media screen and (min-width: 700px) {
    .field {
        width: unset;
        min-width: 25rem;
    }
}

.brandField {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

@media screen and (min-width: 700px) {
    .brandField {
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: flex-end;
    }
}

.createBrandButton {
    white-space: nowrap;
}

.createBrandButton:hover {
    color: var(--Text-interactive);

    background-color: #FFF;
}

.createBrandButton:focus {
    outline: none;
}
