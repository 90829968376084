.table {
    width: 100%;

    background-color: white;
    border-collapse: collapse;
}

.columnHeaderRow {
    height: 3rem;

    color: #334d6e;
    font-size: 0.7rem;
    letter-spacing: 0.2px;
    text-align: left;

    opacity: 0.5;
}

.columnHeaderRow th {
    padding: 0 0.5rem;

    font-weight: 500;
}

.tableRow {
    height: 4rem;
}

.displayCount {
    padding-bottom: 0.3rem;

    color: #334d6e;
    font-size: 10px;
    font-style: italic;
    letter-spacing: 0.1px;

    opacity: 0.5;
}
