.container {
    display: flex;

    flex: 1;
    flex-direction: row;
    align-items: center;

    column-gap: 0.75rem;
}

.filters {
    column-gap: 1.625rem;
}

.icon {
    color: var(--color-neutral-400);
}
