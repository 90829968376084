.badge {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0.3rem 0.5rem;

    color: white;
    font-size: 0.7rem;

    background-color: #6a707e;
    border-radius: 4px;

    fill: white;
}

.badge > * + * {
    margin-left: 0.5rem;
}
