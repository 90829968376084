.container {
    display: flex;
    flex-direction: column;
    max-width: 40rem;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    margin: 0;

    font-size: 0.875rem;
    line-height: 1.25;
}

.detailsContainer > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
}

.detailsContainer b {
    font-weight: 600;
}

.detailsContainer dd {
    margin: 0;
}

.container label {
    color: #767676;
    font-size: 0.875rem;

    line-height: 1.25;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 0.375rem;
    margin-top: 1.625rem;
}

.messageContainer textarea {
    position: relative;

    min-width: 21.75rem;
    max-width: 100%;
    min-height: 3.8125rem;
    padding: 0.5625rem 0.875rem;


    font-size: 0.875rem;
    line-height: 1.25;

    border: 1px solid #B9C9D1;
    border-radius: 0.75rem;
}

.editCostInput {
    right: 0;
    left: unset;

    max-width: 10rem;

    padding: 0.625rem;
}
