.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    height: 100vh;
    padding: 1.5rem;
}

.header {
    display: flex;
    justify-content: space-between;
}

.title {
    margin: 0;

    font-weight: 700;
    font-size: 1.75rem;
}

.menuBar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 0;
    padding: 0;
}

.search {
    flex: 0.25;
}

.myProjectsFilter {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: 0.5rem;
    align-items: center;

    color: #000;
}

.tableContainer {
    flex: 1;
}

@media (min-width: 768px) {
    .menuBar {
      flex-direction: row;
    }
}
