.button {
    composes: font-sm from 'ui/utils.css';

    display: inline-flex;
    flex-direction: row;
    align-items: center;


    padding: 0.625rem 1.5rem;

    column-gap: 0.375rem;

    border: 1px solid transparent;
    border-radius: 0.75rem;
    cursor: pointer;
}

.primary {
    color: white;

    background-color: var(--color-brand-secondary);
    border-color: var(--color-brand-secondary);
}

.primary:hover {
    background-color: var(--color-grayscale-80);
    border-color: var(--color-grayscale-80);
}

.primary:active {
    background-color: var(--color-grayscale-70);
    border-color: var(--color-grayscale-70);
}

.primary:disabled {
    background-color: var(--color-grayscale-80);
    border-color: var(--color-grayscale-80);
    cursor: not-allowed;
}

.contrast {
    color: white;

    background-color: var(--color-brand-primary);
    border-color: var(--color-brand-primary);
}

.contrast:hover {
    background-color: #FF284D;
    border-color: #FF284D;
}

.contrast:active {
    background-color: #E30026;
    border-color: #E30026;
}

.contrast:disabled {
    background-color: #FF284D;
    border-color: #FF284D;
    cursor: not-allowed;
}

.outlined {
    color: var(--color-grayscale-100);

    background-color: white;
    border-color: var(--color-neutral-300);
}

.outlined:hover {
    background-color: var(--color-neutral-100);
}

.outlined:active {
    background-color: var(--color-neutral-200);
}

.outlined:disabled {
    background-color: var(--color-neutral-100);
    cursor: not-allowed;
}

.ghost {
    color: var(--color-grayscale-100);

    background-color: white;
    border-color: transparent;
}

.ghost:hover {
    background-color: var(--color-neutral-100);
}

.ghost:active {
    background-color: var(--color-neutral-200);
}

.ghost:disabled {
    background-color: var(--color-neutral-100);
    cursor: not-allowed;
}

.spinner {
    width: 1rem;
    height: 1rem;

    animation: 0.75s linear infinite spin;
}

.primary .spinner,
.contrast .spinner {
    color: white;
}

.outlined .spinner,
.ghost .spinner {
    color: var(--color-neutral-400);
}

@keyframes spin {
    0% { transform: rotate(0deg); }

    100% { transform: rotate(360deg); }
}
