.container {
    --gap: 0.625rem;
    display: grid;
    grid-template-columns: repeat(2, calc(50% - (var(--gap) / 2)));

    gap: var(--gap);

    width: 100%;
}

@media screen and (min-width: 768px) {
    .container {
        --remainingWidth: calc(100% - (var(--gap) * 2));
        grid-template-columns: repeat(3, calc(var(--remainingWidth) / 3));
    }
}

@media screen and (min-width: 1024px) {
    .container {
        display: flex;
        flex-flow: row nowrap;
    }

    .container > * {
        flex: 1;
        width: 0;
    }
}

@media screen and (min-width: 1280px) {
    .container > * {
        flex-grow: 0;
        flex-shrink: 1;

        width: auto;

        max-width: 15rem;
    }
}

.totalPostsIcon {
    color: #FFAB2D;
}

.totalSpendIcon {
    color: #5795FF;
}

.totalViewsIcon {
    color: #5AE35F;
}

.engagementRateIcon {
    color: var(--color-success);
}

.cpmIcon {
    color: var(--color-status-warning)
}

.accountTeamIcon {
    color: #FF7461;
}
