.container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 1rem;
}

.filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    gap: 0.5rem;
    align-items: stretch;
}

.filter {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.375rem;
    min-width: 10rem;
}

.filter:has(.filterButton) {
    max-width: max-content;
}

.numberFilter {
    flex: unset;
    width: 6rem;
    min-width: unset;
}

.filterLabel {
    color: #90a0b7;
    font-size: 0.75rem;
}

.filterInput {
    flex: 1;
    padding: 0.5rem 1rem;

    color: #333333;
    font-size: 13px;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}

.flexUnset {
    flex: unset;
}

.filterCheckboxContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
}

.filterCheckbox {
    width: 1.125rem;
    height: 1.125rem;
    margin-left: 0.5rem;
}

.filterButton {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    min-width: max-content;
    min-height: 3.215rem;
    padding: 0 1rem;

    color: #252A31;

    font-size: 0.813rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
    cursor: pointer;
}

.filterIcon {
    width: 1rem;
    height: 1rem;

    color: #c2cfe0;
}

.filterIcon.active {
    color: #252a31;
}

.refreshTableButton {
    align-self: flex-end;
    padding: 0.7rem 2.25rem !important;
}

.previewModal {
    top: 10%;

    display: flex;

    min-width: 46.875rem;
    max-height: 80%;

    transform: translate(-50%, 0);
}

.actionsRow {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
}

.actionButtons {
    visibility: hidden;
}

.actionButtons.visible {
    visibility: visible;
}

.audioSelect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1rem;

    font-size: 0.8125rem;

    background-color: transparent;

    border: solid 1px #C2CFE0;
    border-radius: 0.375rem;
}

.audioSelect:hover {
    background-color: initial;
}

.audioSelect svg:hover path {
    fill: hsl(0deg,0%,60%);
}

.placeholder {
    color: hsl(0deg,0%,50%)
}

.audioSelectDropdown {
    min-width: 16rem;
    max-width: 22rem;

    border-radius: 4px;

    box-shadow: 0 0 0 1px hsla(0deg,0%,0%,0.1),0 4px 11px hsla(0deg,0%,0%,0.1);
}

.rowsCountLabel {
    color: #90a0b7;

    font-size: 0.75rem;
}

.ratingFilter {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}

.clearButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.75rem;
    height: 0.75rem;
    padding: 0.125rem;

    background-color: #252a31;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.clearButton svg {
    color:  white;
}

.popoutDropdown {
    display: flex;
    flex-direction: column;
    min-width: fit-content;
    padding: 1rem;

    background-color: #fff;
    border: 1px solid #c2cfe0;
    border-radius: 0.5rem;
    row-gap: 0.5rem;
}

.popoutDropdown article {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.375rem;
}

.genreSelect {
    width: 12.5rem;
}
