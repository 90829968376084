.container {
    composes: shadow-md-topless from 'ui/utils.css';

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.renderValue {
    composes: font-sm from 'ui/utils.css';

    display: flex;
    gap: 0.25rem;
    align-items: center;
    max-width: 12rem;
    overflow: hidden;

    white-space: nowrap;
}

.value {
    composes: font-md-sm from 'ui/utils.css';

    overflow-x: hidden;

    text-overflow: ellipsis;
}

.input {
    composes: font-sm from 'ui/utils.css';

    height: 2.5rem;
    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300, #D1D5DB);
    border-radius: 0.75rem;
}
