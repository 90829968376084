.placeholder {
    color: hsl(0deg,0%,50%)
}

.dropdown {
    min-width: 22rem;
    max-width: 30rem;
    padding: 1rem;

    background-color: white;

    border-radius: 4px;

    box-shadow: 0 0 0 1px hsla(0deg,0%,0%,0.1),0 4px 11px hsla(0deg,0%,0%,0.1);
}


.dropdown table {
    width: 100%;
    margin-bottom: 1rem;
}

.dropdown td, .dropdown th {
    padding: 0 0.75rem 0.375rem 0;
}

.dropdown td:first-child {
    padding-right: 1.5rem;
}

.dropdown th {
    margin-left: 0.25rem;

    color: #90A0B7;
    font-weight: 500;

    font-size: 0.75rem;

    text-align: left;
}

.dropdown input {
    max-width: 8rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.25rem;
}

.labels {
    flex: 1;
    margin-right: auto;

    font-size: 0.725rem;
}

.labels > div:first-child {
    font-weight: 500;
    font-size: 0.825rem;
}

.removeIcon {
    padding: 0.125rem;

    color: white;

    background-color: #292D32;

    border-radius: 0.175rem;
    cursor: pointer;
}

.removeIcon:hover {
    color: white;

    background-color: var(--color-danger);
}

.removeAllIcon {
    padding: 0.125rem;

    border-radius: 0.175rem;
    cursor: pointer;
}

.removeAllIcon:hover {
    color: white;

    background-color: var(--color-danger);
}
