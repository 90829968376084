.container {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    padding: 0.625rem 1.25rem 0.625rem 0.875rem;

    column-gap: 0.375rem;

    background-color: var(--color-neutral-100);

    border-radius: 0.75rem;
}

.iconContainer {
    width: 1.125rem;
    height: 1.125rem;
}

.iconContainer > * {
    width: 100%;
    height: 100%;
}

.data {
    flex: 1;
    overflow: hidden;
}

.data > p {
    margin: 0;
}

.data > p + p {
    margin-top: 0.375rem;
}

.title {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-50);
}

.value {
    composes: font-bold-sm from 'ui/utils.css';

    overflow: hidden;

    color: var(--color-grayscale-100);
    text-overflow: ellipsis;
}

.title, .value {
    white-space: nowrap;
}
