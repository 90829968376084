.container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.container svg {
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
}

.failure {
    color: var(--color-status-fail);
}

.success {
    color: var(--color-status-success);
}
