@layer base {
    .bar {
        margin-bottom: 2.5rem;
        padding: 0;
    }

    .bar li {
        all: unset;
    }
}

.bar {
    position: relative;

    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 100%;

    column-gap: 2.125rem;
}

.bar::after {
    position: absolute;
    bottom: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 2px;

    background-color: var(--color-neutral-200);

    content: '';
}

.item {
    composes: font-base from 'ui/utils.css';

    z-index: 1;

    padding: 0 0.125rem 0.625rem;

    color: var(--color-grayscale-50);

    border-bottom: 2px solid transparent;
    border-radius: 0;
}

.item:hover {
    color: inherit;

    background-color: transparent;
}

.active {
    composes: font-semi-base from 'ui/utils.css';

    border-bottom-color: var(--color-grayscale-100);
}
