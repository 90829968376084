.typography {
    /* stylelint-disable */
    --Fonts-Body: Inter;
    --Fonts-Heading: Inter;
    --Weight-700: 700;
    --Weight-600: 600;
    --Weight-500: 500;
    --Weight-400: 400;
    --Line-Height-40: 2.5rem;
    --Line-Height-20: 1.25rem;
    --Line-Height-16: 0.9375rem;
    --Letter-Spacing-0: 0;
    --Size-10: 0.625rem;
    --Size-28: 1.75rem;
    --Size-14: 0.875rem;
    --Size-12: 0.75rem;
    --Size-18: 1.125rem;
}
