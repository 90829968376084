.reference {
    composes: shadow from 'ui/utils.css';

    width: 100%;
    padding: 0.625rem 0.875rem;

    border: 1px solid var(--color-neutral-300);
    border-radius: 0.75rem;
    outline: none;

    resize: none;
}

.reference:focus {
    background-color: inherit;
}
