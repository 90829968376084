.releaseSection {
    --horizontal-padding: 1.25rem;
    display: grid;
    grid-template-rows: 5rem 1fr;
    grid-template-columns: 5rem 1fr;

    gap: 1rem;

    padding: 0 var(--horizontal-padding);
}

.releaseImage {
    composes: shadow from 'ui/utils.css';

    width: 5rem;
    height: 5rem;

    border-radius: 0.75rem;

    aspect-ratio: 1 / 1;
}

.releaseStats {
    grid-column: 1 / 3;
}

@media screen and (min-width: 768px) {
    .releaseSection {
        grid-template-rows: auto;
        grid-template-columns: 10rem calc(100% - var(--horizontal-padding) - 10rem);
    }

    .releaseImage {
        grid-row: 1 / 3;
        grid-column: 1 / 2;

        width: 10rem;
        height: 10rem;
    }

    .releaseStats {
        grid-row: 2;
        grid-column: 2 / 3;
    }
}

.releaseTitle {
    composes: font-bold-2xl from 'ui/utils.css';

    margin: 0;
}

.releaseSubTitle {
    composes: font-xl from 'ui/utils.css';

    margin: 0;
}

@media screen and (min-width: 768px) {
    .releaseTitle {
        composes: font-bold-xl from 'ui/utils.css';
    }

    .releaseSubTitle {
        composes: font-lg from 'ui/utils.css';
    }
}
