.table {
    width: 100%;
}

.description {
    display: inline-block;
    max-width: 10rem;

    color: inherit;
}

.thead th,
.tbody td {
    padding: 0.8125rem 0.625rem;
}

.thead th {
    font-size: 0.6rem;
}

.thead th:nth-child(3) {
    width:4.5rem;
}

.thead th span {
    margin-left: 3px;
}

.tbody td {
    min-width: auto;

    font-size: 0.625rem;
}

@media screen and (max-width: 1366px) {
    .thead th {
        min-width: 5rem;
    }
}

.location {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    column-gap: 0.5rem;
}
