/* Utility styles */
.hide {
    visibility: hidden;
}

/* General button styles */
.button {
    position: relative;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 0.625rem 2.25rem;

    font-weight: 600;
    font-size: 0.75rem;

    background-color: transparent;
    border: none;
    border-radius: 0.1875em;
    cursor: pointer;

    transition: background-color 0.2s linear;
}

.button:hover {
    background-color: #eee;
}

.button.disabled,
.button.colorPrimary.disabled,
.button.colorOrange.disabled,
.button.colorNegative.disabled,
.button.typeBordered.disabled,
.button.typeBordered.colorPrimary.disabled,
.button.typeBordered.colorNegative.disabled,
.button.typeBordered.colorOrange.disabled,
.button.typeElevated.disabled,
.button.typeElevated.colorPrimary.disabled,
.button.typeElevated.colorNegative.disabled,
.button.typeElevated.colorOrange.disabled {
    color: #bbb;

    background-color: #eee;
    cursor: not-allowed;
}

/*
    Button type styles (bordered, default, etc.).
    Classnames follow type[TypeName] pattern
*/
.button.typeBordered {
    border-style: solid;
    border-width: 0.1em;

    transition: color 0.2s linear;
}

.button.typeBordered:hover {
    background-color: inherit;
}

.button.typeElevated {
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.24);

    transition: all 0.2s linear;
}

.button.typeElevated:hover {
    box-shadow: 0 0.25rem 10px rgba(0, 0, 0, 0.12);
}

.button.typeLink {
    background-color: transparent;
    border: none;
    box-shadow: none;

    transition: all 0.2s linear;
}

/*
    Button color styles (negative, primary, etc.)
    Classnames follow color[ColorName] pattern
*/
.button.colorNegative {
    color: white;

    background-color: #f7685b;
}

.button.colorNegative:hover {
    background-color: #e74b3d;
}

.button.colorNegative:active {
    background-color: #f32c1a;
}

.button.colorNegative.typeBordered {
    color: #e74b3d;

    background-color: transparent;
}

.button.colorNegative.typeBordered:hover {
    color: #e74b3d;
}

.button.colorNegative.typeElevated {
    color: white;

    background-color: #f7685b;
}

.button.colorNegative.typeLink {
    color: #e74b3d;

    background-color: transparent;
}

.button.colorNegative.typeLink:hover {
    color: #f32c1a;
}

.button.colorOrange {
    color: white;

    background-color: #ffb946;
}

.button.colorOrange:hover {
    background-color: #ffac25;
}

.button.colorOrange:active {
    background-color: #ffa004;
}

.button.colorOrange.typeElevated {
    color: white;

    background-color: #ffb946;
}

.button.colorOrange.typeLink {
    color: #ffb946;

    background-color: transparent;
}

.button.colorOrange.typeLink:hover {
    color: #ffa004;
}

.button.colorPrimary {
    color: white;

    background-color: #109cf1;
}

.button.colorPrimary.typeBordered {
    color: #109cf1;

    background-color: transparent;
}

.button.colorPrimary.typeBordered:hover {
    color: #0c77b7;
}

.button.colorPrimary.typeElevated {
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.24);
}

.button.colorPrimary.typeElevated:hover {
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.12);
}

.button.colorPrimary.typeLink {
    color: #109cf1;

    background-color: transparent;
}

.button.colorPrimary.typeLink:hover {
    color: #0c77b7;
}

.button.colorBlack.typeFilled {
    color: white;

    background-color: #192a3e;
}

.button.colorBlack.typeFilled:hover {
    background-color: rgba(25, 42, 62, 0.8);
}

.button.colorBlack.typeFilled:active {
    background-color: rgba(25, 42, 62, 0.9);
}

.button.colorBlack.disabled,
.button.colorBlack.disabled:hover {
    background-color: #c2cfe0;
}

/* Spinner styles */
.spinnerContainer {
    position: absolute;
    top: 50%;
    left: 50%;

    height: auto;

    border-color: inherit;
    transform: translate(-50%, -50%);
}

.spinnerContainer > div:before {
    width: 0.6em;
    height: 0.6em;

    border-color: inherit;
    border-width: 0.1em;
    border-bottom-color: transparent;
}

/* Icon styles */
.icon {
    width: 0.75rem;
    height: 0.75rem;
}

.icon.colorNegative > * {
    stroke: #f7685b;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
