.toolBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.25rem;
}

.selectedRows {
    color: #323c47;
    font-weight: 500;
    font-size: 0.875rem;
}

.selectedRowsToolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.selectedRowsToolbar > * + * {
    margin-left: 1.25rem;
}

.button {
    padding: 0.625rem 1.5625rem;

    color: #323c47;

    border-color: #c2cfe0;
    border-width: 0.1rem !important;
}

.deleteButton {
    border-color: #f7685b;
}

.facebookErrorOrigin {
    margin: 0;

    font-weight: 600;
}

.facebookErrorTitle,
.facebookErrorMessage {
    margin: 0.5rem 0;

    font-size: 0.8rem;
}

.groupSkeleton {
    width: 100%;
    height: 3.75rem;
}

.group + .group {
    margin-top: 0.625rem;
}

.actionButtons {
    display: flex;
    flex-direction: row;
}

.actionButtons > * + * {
    margin-left: 0.9375rem;
}

.resultsIcon {
    margin-left: 0.625rem;
}

.button > svg {
    width: 1.15rem;
    margin-right: 0.5rem;
    object-fit: cover;
}

.button > svg * {
    stroke: #323c47;
    stroke-width: 2;
}

.copyToClipboardButton > svg * {
    stroke-width: 1.2;
}
