.container {
    composes: p-small from 'ui-new/whitelabel/utils/utils.css';
    composes: shadow-lg from 'ui-new/whitelabel/utils/effects.css';

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    max-width: 20rem;
    padding: var(--spacing-lg, 0.75rem);

    color: var(--Text-Base, #242424);

    background: var(--Background-light, #F9FAFB);
    border-radius: var(--radius-md, 0.5rem);
}
