.table td,
.table th {
    padding: 0.625rem;
}

.table th {
    font-size: 0.625rem;
}

.account {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountImage {
    width: 2.25rem;
    height: 2.25rem;
    margin-right: 0.625rem;

    border-radius: 50%;
}

.accountDetails {
    white-space: nowrap;
}

.accountDetails > * {
    margin: 0;
}

.accountUsername {
    color: #58698E;
    font-weight: 400;
    font-size: 0.75rem;
    text-decoration: none;
}
