.container {
    composes: font from 'ui/utils.css';
}

.title {
    composes: font-semi-xl from 'ui/utils.css';

    margin: 0 0 1.5rem;

    color: var(--color-grayscale-100);
}

.tabBar {
    margin-bottom: 0;
}

.platformIcon {
    margin-right: 0.375rem;
}
