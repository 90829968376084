.scrollWrapper {
    width: 100%;
}

.table {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    white-space: nowrap;
}

.table tr:last-child td:first-child {
    border-bottom-left-radius: 0;
}

.table tr:last-child td:last-child {
    border-bottom-right-radius: 0;
}

.noDataLabel {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    align-items: center;
    justify-content: center;
    height: 15rem;

    color: var(--text-light);
    text-align: center;
}

.noDataLabel p {
    margin: 0.5rem 0;
}

.noDataLabel .error svg {
    width: 1.5rem;
    height: 1.5rem;

    color: var(--Icon-destructive);
}

table tbody td.truncatedColumn {
    max-width: 15rem;
}

.truncatedTextContainer {
    display: flex;

    color: black;
}

.truncatedText {
    margin: 0;
    overflow: hidden;

    font-weight: 600;
    text-overflow: ellipsis;
}
