.container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100vh;
    padding: 1.5rem;
}

.header {
    display: flex;
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0;
    padding: 0;
}

.searchInput {
    width: 18rem;
}

@media screen and (min-width: 700px) {
    .addSong {
        margin-left: auto;
    }

    .menu {
        flex-direction: row;
    }
}

.tableContainer {
    flex: 1;
}

.title {
    margin: 0;

    font-weight: 700;
    font-size: 1.75rem;
}

.plusIcon {
    width: 0.875rem;
    height: 0.875rem;
}
