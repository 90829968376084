.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;

    background-color: transparent;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;
}

.button:hover {
    background-color: #eeeeee;
}

.button.disabled,
.button.disabled:hover,
.button.loading,
.button.loading:hover {
    background-color: #f7f7f7;
    cursor: not-allowed;
}

.icon.disabled,
.icon.loading {
    stroke: #cccccc;
}

.icon.loading {
    animation-name: spin;
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@keyframes spin {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}
