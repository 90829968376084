.title {
    margin: 0;

    font-weight: 500;
    font-size: 1.15rem;
    letter-spacing: 0.1px;
}

.toolBarContainer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 0.75rem 0;
}

.toolBarLeft {
    display: flex;
    align-items: flex-end;
    width: 60%;
}

.searchBoxItem {
    width: 60%;
    margin-right: 3rem;
}

.countItem {
    margin-right: 3rem;
}

.filterLabel {
    margin-bottom: 0.5rem;

    color: #90a0b7;
    font-size: 8px;
    letter-spacing: 0.2px;
}

.addHashtagButton {
    width: 14.5rem;
    height: 2.6rem;

    color: white;
    font-weight: 600;
    font-size: 0.8rem;
    letter-spacing: 0.01em;

    background: #109cf1;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.24);
    cursor: pointer;

    transition: transform 0.2s;
}

.addHashtagButton:hover {
    box-shadow: 0 0.25rem 10px rgba(16, 156, 241, 0.12);
    transform: translate(3px);
}

.addHashtagButton:active {
    outline: none;
}
