.container {
    display: flex;
    align-items: stretch;
    padding: 0.5rem;

    background-color: #fcfcfc;
    border: 1px solid #e7eef6;
    border-radius: 0.375rem;
}

.container > * + * {
    margin-left: 0.325rem;
}

@media screen and (min-width: 600px) {
    .container {
        padding: 0.525rem;
    }

    .container > * + * {
        margin-left: 0.475rem;
    }
}

@media screen and (min-width: 1200px) {
    .container {
        padding: 0.9375rem;
    }

    .container > * + * {
        margin-left: 0.625rem;
    }
}



.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.25rem;
    height: 1.25rem;

    border-radius: 50%;
}

@media screen and (min-width: 600px) {
    .iconContainer {
        width: 1.75rem;
        height: 1.75rem;
    }
}

@media screen and (min-width: 1200px) {
    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.25rem;
        height: 2.25rem;

        border-radius: 50%;
    }
}

.chartContainer {
    align-self: stretch;
    height: auto !important;
}

.valuesContainer {
    flex: 1;
}

.value,
.label {
    margin: 0;
}

.value {
    color: #192a3e;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
}

.label {
    color: #707683;
    font-size: 0.5rem;
    line-height: 0.625rem;
}

.subtitle {
    margin-left: 0.325rem;

    color: #90a0b7;
    font-weight: 500;
    font-size: 0.5rem;
}

@media screen and (min-width: 600px) {
    .value {
        color: #192a3e;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.25rem;
    }

    .label {
        color: #707683;
        font-size: 0.625rem;
        line-height: 0.875rem;
    }

    .subtitle {
        margin-left: 0.425rem;

        color: #90a0b7;
        font-weight: 500;
        font-size: 0.625rem;
    }
}

@media screen and (min-width: 1200px) {
    .value {
        color: #192a3e;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 1.5rem;
    }

    .label {
        color: #707683;
        font-size: 0.75rem;
        line-height: 1.125rem;
    }

    .subtitle {
        margin-left: 0.5rem;

        color: #90a0b7;
        font-weight: 500;
        font-size: 0.75rem;
    }
}
