.container {
    padding: 1.5rem 2.25rem;

    background-color: #fcfcfc;
    border-radius: 0.625rem;
}

.heading {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.title {
    margin: 0;

    font-weight: 500;
}

.errorMessage {
    margin: 0;

    color: #90a0b7;
}

.dateRangeContainer {
    display: flex;
    align-items: center;

    color: #707683;
    font-size: 0.75rem;
}

.dateInput {
    width: 5.3125rem;
    margin: 0 0.375rem;
    padding: 0.3125rem 0.625rem;

    font-size: 0.75rem;

    border: 1px solid #c2cfe0;
    border-radius: 0.375rem;
}
