.container {
    composes: variables from 'ui-new/whitelabel/utils/variables.index.css';
    composes: p from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    flex-direction: column;
    height: 100vh;

    background-color: white;
}

@media screen and (min-width: 700px) {
    .container {
        flex-direction: row;
    }
}

.container *::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;

    background-color: transparent;
}

.container *::-webkit-scrollbar-thumb {
    background: #D1D5DB;
    border-radius: 0.5rem;
}

.main {
    flex: 1;
    height: auto;
    overflow: auto;
}
