.form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: flex-start;
}

.form > * {
    width: 100%;
}

@media screen and (min-width: 700px) {
    .form > * {
        width: 25rem;
    }
}

.postType {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
}

.postType .nestedInput {
    padding-bottom: 0.5rem;
}

.postType .nestedInputContainer,
.postType .nestedInput {
    width: 100%;

    text-align: left;
}
