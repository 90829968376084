.track {
    height: 0.5rem;

    background-color: #f5f6f8;
    border-radius: 3rem;
}

.bar {
    height: inherit;

    background-color: #098edf;
    border-radius: inherit;
}
