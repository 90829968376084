.dateCell {
    cursor: pointer;
}

.popover {
    background-color: white;
}

.quickActions {
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.quickActions > * {
    flex: 1;
}

.action {
    padding: 1rem 0;
}
