.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
}

.field {
    width: 100%;
}

@media screen and (min-width: 700px) {
    .field {
        width: 25rem;
    }
}
