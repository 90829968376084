.row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.875rem;
}

.row > * {
    flex: 1;
}

.lastUpdatedLabel {
    margin: 0;

    color: #58698e;
    font-size: 0.6875rem;
}

.lastUpdated {
    font-weight: 700;
}

.dataCards {
    display: flex;
    flex-direction: row;
}

.dataCards > * + * {
    margin-left: 1.25rem;
}

.actionButtons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.actionButtons > * + * {
    margin-left: 0.9375rem;
}

.actionButtonIcon {
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;

    stroke: #323c47;
}

.actionButton {
    padding: 0.5rem 1.5625rem;

    color: #323c47;

    border-color: #c2cfe0;
}

.notesContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
}

.notesField {
    align-self: stretch;
    /* We calculate 5 rows */
    height: calc(0.82rem * 5 * 1.5 + 1rem);
    margin-top: 0.2rem;
    padding: 0.5rem;

    font-size: 0.82rem;
    line-height: 1.5;

    border-color: #c2cfe0;

    resize: none;
}

.notesField:focus {
    color: inherit;

    background-color: inherit;
    border-color: #c2cfe0;
}

.notesField::placeholder {
    color: #90a0b7;
}
