.navMenuIcon {
    color: white;
    scale: 1.2;

    cursor: pointer;
}

.navMenuIcon.menuIsOpen,
.navMenuIcon:hover {
    -webkit-filter: drop-shadow( 0.125rem 0.125rem 0 #393E44);
    filter: drop-shadow( 0.125rem 0.125rem 0 #393E44);
    scale: 1.2;
}

.menuWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%
}

.menuDropdownList {
    position: absolute;
    top: 3rem;
    right: 0;
    left: 0;
    z-index: 10;

    display: none;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.625rem;

    background: #292D32;
    border-top: 0.125rem solid #393E44;
    border-radius: 0 0 0.625rem 0.625rem;
}

.navItem {
    position: relative;

    display: flex;
    justify-content: center;

    text-decoration: none;

    border: 4px solid transparent;
    cursor: pointer;
}


.menuDropdownList > .navItem {
    margin-top: 0.25rem;
}

.isNavGroupOpen {
    display: flex;
}

.activeRouteNavItem {
    text-shadow: 0.125rem 0.125rem 0 #393E44;
}

.dropdown {
    position: relative;
    left: 5px;
    z-index: 10;

    padding: 0.35rem 0.625rem;

    border-left: 0.125rem solid #393E44;
    border-radius: 0 0.625rem 0.625rem 0;
}


.dropdown > .navItem {
    justify-content: flex-start;

    color: #fff;
    text-align: left;

    cursor: pointer;
}

.label {
    padding-bottom: 0.125rem;

    color: #ffffff;
    font-weight: 500;
    white-space: nowrap;
    text-align: center;

    border-bottom: 0.125rem solid transparent;
}
