.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1rem;
    width: 100%;

    padding: 2rem;
}

.header {
    display: flex;

    column-gap: 0.5rem;
}

.header > * {
    min-width: 12.5rem;
}

.control {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    row-gap: 0.3125rem;
}

.templateControl {
    min-width: 21.25rem;
}

.controlLabel {
    color: #90a0b7;
    font-size: 0.75rem;
}

.emailInput {
    padding: 0.625rem 1rem;

    font-size: 0.75rem;

    border: 1px solid #C2CFE0;
    border-radius: 0.25rem;
}
