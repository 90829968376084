.dropdown {
    min-width: max-content;
    padding: 0.875rem 1.25rem;

    background: var(--White, #FFF);

    border-radius: 0.75rem;

    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.10), 0 2px 4px -2px rgba(0, 0, 0, 0.10);
}
