.form > * + * {
    margin-top: 1.25rem;
}

.field {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.field input {
    box-sizing: border-box;
    max-height: 2.375rem;

    font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

    outline: none;
}

.field input:focus {
    color: rgb(98, 98, 98);

    background-color: #eee;
    border: 1px solid rgba(0, 0, 0, 0.2);
    outline: none;
}

.field input:disabled {
    cursor: default;
}

.row {
    display: flex;
    gap: 0.5rem;
    width: 100%;
}

.col {
    width: 100%;
}

.col5 {
    width: 20%;
}

.col4 {
    width: 25%;
}

.errorMessage {
    max-width: 100%;
    margin-top: 0.5rem;

    color: red;
    font-size: 0.7rem;
}

.metricsRow > * {
    flex: 1;
    width: 0;
}

.metricsRow > * + * {
    margin-top: 0 !important;
}

.defaultRenderActions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 1.25rem;
}

.defaultSubmitButton {
    flex: 1;
}
