.form {
    display: flex;
    flex-direction: column;
    gap: var(--6);
    height: 100%;
}

@media screen and (min-width: 700px) {
    .form {
        flex-direction: row;
    }

    .form > * {
        flex: 1;
    }
}

.songSection {
    display: flex;
    flex-direction: column;
    gap: var(--6);
}

.audiosSection {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 700px) {
    .audiosSection {
        padding: 0 var(--6, 1.5rem);
    }
}

.statusOptions {
    display: flex;
    flex-direction: column;
    gap: var(--2);
    align-items: stretch;
    max-width: 24.25rem;
}

.audioLinkWarning {
    composes: p-small-medium from 'ui-new/whitelabel/utils/utils.css';

    display: flex;
    gap: var(--2);
    align-items: center;
}

.audiosLabel {
    display: flex;
    gap: var(--2);
    align-items: center;
}

.infoCircle {
    width: 1.125rem;
    height: 1.125rem;

    color: var(--icon-base);
}
