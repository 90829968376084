.account {
    display: flex;
    flex-direction: row;
    align-items: center;

    column-gap: 0.5rem;
}

.avatar {
    width: 2.25rem;
    height: 2.25rem;
    object-fit: cover;
    object-position: center;

    border-radius: 50%;
}

.accountDetails {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.3125rem;
}

.username {
    color: var(--color-accent-blue);
    text-decoration: none;
}

.username:hover {
    text-decoration: underline;
}

.location {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    column-gap: 0.5rem;
}

.table tbody tr {
    cursor: pointer;
}
