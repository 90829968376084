.table {
    width: 100%;

    text-align: left;

    table-layout: auto;

    border: 1px solid #e7eef6;
    border-collapse: collapse;
}

.table td {
    padding: 0.45rem 1rem 0.45rem 0.375rem;

    color: #707683;
    font-size: 0.675rem;

    border: 1px solid #e7eef6
}

.table thead {
    background-color: #f5f6f8;
}

.table thead th {
    padding: 0.8125rem 0.625rem;

    color: #58698e;
    font-weight: 600;

    white-space: nowrap;
}

.table tfoot {
    display: none;
}

.accountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accountContainer > * + * {
    margin-left: 0.375rem;
}

.accountDetails {
    display: flex;
    flex-direction: column;
    min-width: 6rem;
}

.influencerThumbnail {
    width: 2rem;
    height: 2rem;
    object-fit: cover;

    border-radius: 50%;
}

.influencer {
    color: #4a87cd;
    text-decoration: none;
}

.statusContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.statusButton {
    background-color: transparent;
    border: none;
}

.status {
    color: #58698e;
    font-weight: 500;
    white-space: normal;
    text-align: left;
}

.linkIcon {
    width: 0.75rem;
    height: 0.75rem;
    margin-left: 1rem;

    color: var(--color-grey-4);
}

.input {
    min-height: 1.15em;
    max-height: 2.9em;
    padding: 0.3rem;
    overflow: scroll;

    color: inherit;
    text-overflow: ellipsis;
    overflow-wrap: normal;

    background-color: #fdffef;
    border: none;

    resize: none;
    scrollbar-width: none;
}

.inputWide {
    width: 8rem;
}

.inputWide::-webkit-scrollbar {
    display: none;
}

.costInput {
    width: 4rem;
}

.editorCell {
    background: #fdffef;
}

.invalid {
    outline: 1px auto rgba(247, 104, 91, 0.5);
}
