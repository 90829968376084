.table a {
    color: var(--color-text);
}

.table td {
    min-width: 6.5rem;

    color: #707683;
    font-size: 0.75rem;
}

.postedAfterDeadline {
    background-color: rgba(255, 184, 0, .25);
}

.campaign,
.audio {
    min-width: 12.5rem;
}

.table tr td:nth-child(3) {
    position: relative;

    min-width: 12rem;
}

.brief {
    position: absolute;
    top: 0.6875rem;
    right: 1.3125rem;
    bottom: 0.6875rem;
    left: 1.3125rem;

    width: unset;

    line-height: normal;

    white-space: normal;
    overflow-wrap: break-word;
}

.title {
    font-weight: 600;
    font-size: 0.825rem;
}

.date {
    white-space: nowrap;
}

.actions {
    display: flex;
    gap: 1rem;
    align-items: center;

    color: white;
}

.actions > button {
    min-width: 5rem;
    padding: 0.375rem;

    color: white;
    font-weight: 500;

    border: none;
    border-radius: 0.25rem;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.actions > button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.actions > button:not(.disabled):hover {
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
    opacity: 0.9
}

.approve {
    background-color: var(--color-success);
}

.reject {
    background-color: var(--color-danger);
}
