.modal {
    width: 70%;
}

@media only screen and (max-width: 868px) {
    .modal {
        width: 100%;
    }
}

.modalContent {
    box-sizing: border-box;
    height: 100vh;
    padding: 2.8125rem 1.875rem 1.5rem;
    overflow-y: auto;

    background-color: white;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: #58698e;

    background-color: transparent;
    border: none;
    cursor: pointer;
}

.closeIcon {
    width: 1rem;
    height: 1rem;
}

.influencerProfile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 0.875rem;
}

.influencerUserInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1.875rem;
    padding: 1.25rem 0.9375rem 1.25rem 1.875rem;

    background-color: #e4e7ec;
}

.actions {
    top: 1.25rem;
    right: 0.9375rem;

    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-left: auto;
}

.actions > * + * {
    margin-left: 0.3125rem;
}

.influencerUserEditButton {
    padding: 0.5rem;
}

.refreshButton,
.influencerUserEditButton {
    padding: 0.625rem 1.5625rem;

    background-color: #ffffff;
    border-color: #c2cfe0;
}

.refreshButton:hover,
.refreshButton:active,
.influencerUserEditButton:hover,
.influencerUserEditButton:active {
    background-color: #ffffff !important;
}

.refreshButtonIcon {
    margin-right: 0.3125rem;
}

.influencerUserEditIcon {
    width: 1.25rem;
    height: 1rem;
}

.influencerUserImage {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
}

.influencerUserDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-left: 0.875rem;
}

.influencerUserDetails > * {
    margin: 0;
}

.influencerUserName {
    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
}

.influencerUserAccount {
    color: #0090ff;
    font-weight: 500;
    font-size: 0.875rem;
    text-decoration: none;
}

.influencerUserSignature,
.influencerUserBio {
    color: #58698e;
    font-size: 0.75rem;
}

.tags {
    display: flex;
    flex-direction: column;
    gap: 0.425rem;
    margin-top: 0.875rem;
}

.influencerUserForm {
    display: flex;
    flex-direction: column;
    width: 75%;
}

.influencerUserForm > * {
    box-sizing: border-box;
}

.influencerFormSplitSection {
    display: flex;
    flex-direction: row;
    gap: 1.2rem;
}

.influencerUserCostColumn {
    display: flex;
    flex-direction: column;
}

.influencerUserFormSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.375rem;
}

.influencerUserFormLabel {
    margin: 0.65rem 0 0.2rem;

    font-weight: 500;
    font-size: 0.9rem;
}

.influencerUserCostField {
    display: flex;
    flex-direction: row;
    gap: 0.225rem;
    align-items: flex-start;
}

.influencerUserCostField > * + * {
    margin-left: 0.3125rem;
}

.influencerUserNotes {
    width: 100%;;
    height: 100%;
    min-height: 7.1rem;
    padding-top: 0.6rem;

    font-weight: 400;
    font-size: 0.925rem;
    line-height: 1.15;
}

.influencerUserNotes::placeholder {
    font-size: 0.925rem;
}

.influencerUserCurrencySelect,
.influencerUserCost,
.influencerUserNotes,
.influencerUserNotes:focus,
.influencerUserContactDetails,
.influencerUserPaypalEmail {
    background-color: #ffffff;
    border-color: #c2cfe0;
}

.influencerUserCurrencySelect {
    display: flex;
    flex: 1 2;
    align-items: center;

    background-color: #e4e7ec;
}

.influencerUserCost {
    flex: 1 2;
    max-width: 10rem;
}

.influencerUserCost,
.influencerUserContactDetails,
.influencerUserPaypalEmail {
    height: 2.4rem;
    padding: 0.5rem;

    font-size: 0.9rem;

    border-style: solid;
    border-width: 0.0625rem;
    border-radius: 0.3rem;
}

.statsContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.875rem;
    overflow-x: auto;
}

.statsContainer > * {
    flex: 1;
}

.statsContainer > * + * {
    margin-left: 1.25rem;
}

.infoIcon {
    width: 1.25rem;
    height: 1rem;
}

.warning {
    color: var(--color-danger);
}

.tabNav {
    max-width: 80%;
    margin-bottom: 1.875rem;
}

.tabNavItem {
    width: 10.625rem;
}

.tabNavItemIcon {
    margin-right: 0.3125rem;
}

.addToCampaignButton {
    height: 100%;
    padding: 0.625rem 1.5625rem !important;

    font-weight: 600 !important;
    font-size: 0.75rem !important;

    background-color: white !important;
    border: 1px solid #c2cfe0 !important;
    border-radius: 0.1875em !important;
}

.addToCampaignButton:hover {
    color: inherit !important;
}

.apexLogoContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #192a3e;
    border: 1px solid #c2cfe0;
    border-radius: 0.1875em;
}

.locationContainer {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    width: 100%;
}

.label {
    min-width: 6.875rem;

    font-weight: 500;
    font-size: 0.75rem;
}

.location {
    display: flex;
    flex-direction: row;
    gap: 0.3125rem;
    align-items: center;

    font-size: 0.75rem;
}

.tagsContainer {
    display: flex;
    flex-direction: row;
    gap: 0.625rem;
    align-items: center;
    width: 100%;
}

.tagsLabel {
    min-width: 6.875rem;

    font-weight: 500;
    font-size: 0.75rem;
}
