.brandOption {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0.3125rem 0;
    padding: 0.3125rem 0.625rem;

    cursor: pointer;
}

.brandOption:hover {
    background-color: var(--color-background-4);
}

.brandOption > * + * {
    margin-top: 0.3125rem;
}

.brandOptionSubtitle {
    margin: 0;

    color: var(--color-grey-4);
    font-size: 0.75rem;
}
