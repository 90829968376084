.thead {
    color: #90a0b7;
    font-weight: 500;
    text-transform: uppercase;

    background-color: #f5f6f8;
    border: 1px solid #f5f6f8;
}

.thead th {
    padding: 0.8125rem 1.3125rem;
}

.tbody tr:hover {
    background-color: inherit;
}

.tbody tr:nth-child(even),
.tbody tr:nth-child(even):hover {
    background-color: #fafafa;
}

.tbody td {
    padding: 0.6875rem 1.3125rem;

    border: 1px solid #eef0f2;
}

.paginationToolbar {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 1.4375rem;

    border-top: none;
}

@media screen and (min-width: 768px) {
    .paginationToolbar {
        align-items: center;
    }
}

.paginationContainer {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .paginationContainer {
        flex-direction: row;
    }
}

.paginationButtonsContainer {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.paginationLabel {
    color: #707683;
    font-size: 0.675rem;
}

@media screen and (min-width: 768px) {
    .paginationLabel {
        font-size: 0.875rem;
    }
}

.paginationIcon {
    box-sizing: content-box;
    width: 0.875rem;
    height: 0.875rem;
    padding: 0.5rem;

    color: #212b36;

    border: 1px solid #dfe3e8;
    border-radius: 4px;

    transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 768px) {
    .paginationIcon {
        width: 0.75rem;
        height: 0.75rem;
    }
}

@media screen and (min-width: 768px) {
    .paginationIcon {
        width: 0.75rem;
        height: 0.75rem;
    }
}

.paginationIcon:hover {
    color: #109cf1;

    border-color: #109cf1;
    cursor: pointer;

    fill: #109cf1;
}

.paginationIconDisabled,
.paginationIconDisabled:hover {
    color: #c4cdd5;

    background-color: #919eab;
    border-color: #919eab;
    cursor: not-allowed;
    opacity: 0.5;

    fill: #c4cdd5;
}

.pageSizeSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: flex-start;

    color: #707683;
    font-size: 0.875rem;
}

@media screen and (min-width: 768px) {
    .pageSizeSelectContainer {
        flex-direction: row;
        align-items: center;
    }
}
