.button {
    position: fixed;
    right: 1rem;
    bottom: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5rem;

    font-size: 0;

    border-radius: 50%;
}

.icon {
    width: 1rem;
    height: 1rem;;
}

.menu {
    composes: shadow-md font-semi-base from 'ui/utils.css';

    display: flex;
    flex-flow: column nowrap;
    padding: 1rem;

    color: white;

    list-style: none;
    background-color: var(--color-brand-secondary);

    border-radius: 0.5rem;
    row-gap: 0.5rem;
}

.flag {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    column-gap: 1rem;
}

.flag span::first-letter {
    text-transform: uppercase;
}
