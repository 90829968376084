.bar {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: white;

    background-color: var(--color-grey-2);
}

.bar > * + * {
    margin-left: 1.25rem;
}

.button {
    padding: 0.5rem 1.25rem;

    background: transparent;
    border: none;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.expandIcon {
    color: white;

    transform: rotate(90deg);

    transition: transform 0.2s ease-in-out;
}

.expandIcon.open {
    transform: rotate(180deg);
}

.title {
    margin-top: 0;
    margin-bottom: 0;

    font-weight: 500;
    font-size: 0.6875rem;
}

@media screen and (min-width: 768px) {
    .title {
        font-size: 0.8125rem;
    }
}
