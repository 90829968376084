@layer base {
    .container {
        overflow: auto;
    }

    .table {
        composes: p-small from 'ui-new/whitelabel/utils/utils.css';

        min-width: 100%;

        color: #242424;

        border-radius: var(--rounded);

        border-spacing: 0;
    }

    .table th {
        padding: var(--Spacing-Spacing-2) var(--2);

        color: var(--Text-Base, #242424);
        font-weight: var(--Weight-500, 500);
        font-size: var(--Size-12, 0.75rem);

        font-family: var(--Fonts-Body, Inter);
        font-style: normal;
        line-height: var(--Line-Height-16, 1rem); /* 133.333% */
        letter-spacing: var(--Letter-Spacing-0, 0);

        text-align: left;

        background-color: var(--Tables-header-grey);

        border-top: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
    }

    .table th:first-child {
        border-left: 1px solid #d1d1d1;
        border-top-left-radius: var(--rounded);
    }

    .table th:last-child {
        border-right: 1px solid #d1d1d1;
        border-top-right-radius: var(--rounded);
    }

    .table tbody td {
        padding: var(--Spacing-Spacing-2) var(--2);

        border-bottom: 1px solid #d1d1d1;
    }


    /* Table needs to be grid to be positioned absolute correctly */
    .table.virtual {
        display: grid;
    }

    .table.virtual thead,
    .table.virtual tbody {
        display: grid;
    }

    .table.virtual tr {
        display: flex;
        width: 100%;
    }

    .table.virtual tr > * {
        flex: 1;
    }

    .table.virtual td {
        display: flex;
        align-items: center;
    }

    .table tbody td:first-child {
        border-left: 1px solid #d1d1d1;
    }

    .table tbody td:last-child {
        border-right: 1px solid #d1d1d1;
    }

    .table tr:last-child td:first-child {
        border-bottom-left-radius: var(--rounded);
    }

    .table tr:last-child td:last-child {
        border-bottom-right-radius: var(--rounded);
    }
}

.sortingIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;

    vertical-align: middle;
}

.sortingIcon.sortingActive {
    color: rgb(0, 0, 0);
}
