.card {
    position: relative;

    min-width: 8rem;
    padding: 0.5rem 0.625rem;

    background-color: white;
    border-radius: 0.375rem;
    cursor: help;
}

.iconContainer {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;

    background-color: #C2CFE080;
    border-radius: 50%;
}

.icon {
    width: 0.6125rem;
    height: 0.6125rem;

    color: #90A0B7;
}

.title {
    margin: 0;

    color: #323c47;
    font-weight: 500;
    font-size: 0.9rem;
}

.subtitle {
    margin: 0;

    color: #90A0B7;
    font-size: 0.6125rem;
}

.positive {
    color: #48D98A;
}

.negative {
    color: var(--color-danger);
}

.warning {
    color: var(--color-warning);
}

.tooltip {
    padding: 0.5rem;

    background-color: white;
}


.statusDescriptions {
    max-width: 18.75rem;
    margin: 0;
}

.statusDescription dd,
.statusDescription dt {
    display: inline;

    font-size: 0.75rem;
}

.statusDescription dd:before {
    margin: 0 0.5rem;

    content: '=';
}

.statusDescription dd {
    margin: 0;

    color: #A1B0C4;
}
