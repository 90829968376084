.container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;

    white-space: nowrap;
}

.container p {
    margin: 0;
}

.image {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
}

.name {
    composes: font-sm from 'ui/utils.css';

    color: var(--color-grayscale-100);
}

.username {
    composes: font-xs from 'ui/utils.css';

    color: var(--color-grayscale-40);
}

.container a {
    text-decoration: none;
}

.container a:hover {
    text-decoration: underline;
}
