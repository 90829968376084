.table {
    overflow: hidden;

    border: 1px solid var(--color-neutral-200);
    border-radius: 0.75rem;
}

.table tr td {
    height: 2.875rem;
    padding: 0.625rem 0.75rem;
}

.table thead tr th {
    border-top: none;
}

.table td:nth-child(1), .table tr th:nth-child(1) {
    padding-left: 1.25rem;
}

.table td:last-child, .table tr th:last-child{
    padding-right: 1.25rem;
}

.platformIcon {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.75rem;
}

.planners {
    display: -webkit-box;
    width: 10rem;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
