.modal {
    width: 70%;
}

@media only screen and (max-width: 768px) {
    .modal {
        width: 100%;
    }
}

.container {
    position: relative;

    box-sizing: border-box;
    min-height: 100vh;
    padding: 2.8125rem 1.875rem 1.875rem;

    background-color: white;
}

.container > * + * {
    margin-top: 1.25rem;
}

.container > section + section {
    margin-top: 2.5rem;
}

.closeButton {
    position: absolute;
    top: 1rem;
    right: 1rem;

    background-color: transparent;
    border: none;
    cursor: pointer;

    fill: #90a0b7;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.9375rem 1.875rem;

    background-color: #e4e7ec;
    border-radius: 0.625rem;
}

.header > * + * {
    margin-left: 1rem;
}

.audioCover {
    width: 2.8125rem;
    height: 2.8125rem;

    border-radius: 50%;
}

.titleContainer {
    flex: 1;
}

.title {
    color: #192a3e;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
}

.title:hover {
    text-decoration: underline;
}

.alternateName {
    margin-left: 0.5rem;

    color: #4a87cd;
    font-size: 0.7rem;
    font-style: italic;

    background-color: transparent;
    border: none;
}

.alternateName:focus {
    background-color: white;
}

.badge {
    padding: 0.2rem 0.5rem;

    color: white;
    font-size: 0.6rem;

    border-radius: 0.3rem;
}

.badge.original {
    background-color: #ed7200;
}

.badge.official {
    background-color: #2a5484;
}

.statsContainer {
    display: flex;
    flex-direction: row;
}

.statsContainer > * {
    flex: 1;
}

.statsContainer > * + * {
    margin-left: 2rem;
}

.tabsHeader {
    width: 18.75rem;
    margin-bottom: 1.75rem;
}

.sectionHeader {
    margin-bottom: 1.25rem;
}

.topLocationsContainer {
    display: grid;
    grid: repeat(3, 1fr) / repeat(2, 1fr);
    grid-auto-flow: column;
    gap: 1.25rem 5rem;
}

.topLocation {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.flagIcon {
    width: 1.25rem;
    height: 1.25rem;
}

.locationLabel {
    display: flex;
    gap: 0.25rem;
    align-items: center;

    font-size: 0.825rem;
}

.countryName {
    margin-right: 0.5rem;

    font-weight: 500;
    font-size: 0.875rem;
}

.sectionTitle {
    margin: 0;

    color: #192a3e;
    font-weight: 500;
}

.sectionSubtitle {
    margin: 0;

    color: #707683;
    font-size: 0.6rem;
}

.tooltipContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.tooltipContent p {
    margin: 0;

    text-align: justify;
    text-wrap: none;
}

.tooltipDot {
    width: 0.5rem;
    min-width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.5rem;

    background-color: #7a61df;
    border-radius: 50%;
}

.tooltipValue {
    margin-left: 0.5rem;
}

.monitoringContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.monitoringContainer p {
    margin: 0;

    color: #90a0b7;
    font-size: 0.75rem;
    text-align: left;
}

.monitoringContainer p.active {
    color: var(--color-success);
}

.monitoringContainer p.inactive {
    color: var(--color-danger);
}


.statusCard {
    min-width: 8rem;

    background-color: white;
}

.statusCardTitle {
    margin: 0;

    color: #323c47;
    font-weight: 500;
    font-size: 0.9rem;
}

.statusCardSubtitle {
    margin: 0;

    color: #90A0B7;
    font-size: 0.6125rem;
}

.positive {
    color: #48D98A;
}

.negative {
    color: var(--color-danger);
}

.warning {
    color: var(--color-warning);
}

.statusDescriptions {
    max-width: 18.75rem;
    margin: 0;
}

.statusDescription dd,
.statusDescription dt {
    display: inline;

    font-size: 0.75rem;
}

.statusDescription dd:before {
    margin: 0 0.5rem;

    content: '=';
}

.statusDescription dd {
    margin: 0;

    color: #A1B0C4;
}
