.content {
    max-width: 80%;
}

.footer {
    align-items: center;
    justify-content: space-between;
}

.totals {
    composes: font-semi-sm from 'ui/utils.css';

    display: flex;
    flex-flow: row nowrap;

    color: var(--color-grayscale-100);

    column-gap: 0.625rem;
}

.totals p {
    margin: 0;
}

.buttons {
    display: flex;
    flex-flow: row nowrap;

    column-gap: 0.75rem;
}

.results {
    composes: font-xs from 'ui/utils.css';

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
